import React from 'react'
import illustration from '../../assets/images/Services/Illustration.png'
import airplane from '../../assets/images/Services/airplane.png'
import notepad from '../../assets/images/Services/notepad.png'
import dorm from '../../assets/images/Services/dorm.png'
import { Link } from 'react-router-dom'

const Services = () => {
    return (
        <div style={{ margin: "0", padding: "50px 0" }} className="services-section">
            <h2 style={{ letterSpacing: 0 }}>خدماتنا</h2>

            <div className="container pc-container">
                <div className="service-card">
                    <div className="illustration">
                        <div className="circle"></div>
                        <img src={notepad} alt="" />
                        <br />
                        <p>خدمات التسجيل</p>
                    </div>
                </div>

                <div className="service-card">
                    <div className="illustration">
                        <div className="circle"></div>
                        <img src={airplane} alt="" />
                        <br />
                        <p>استقبال من المطار</p>
                    </div>
                </div>

                <div className="service-card">
                    <div className="illustration">
                        <div className="circle"></div>
                        <img src={illustration} alt="" />
                        <br />
                        <p>خدمة الاستشارات <br /> التعليمية</p>
                    </div>
                </div>

                <div className="service-card">
                    <div className="illustration">
                        <div className="circle"></div>
                        <img src={dorm} alt="" />
                        <br />
                        <p>إقامة الطالب</p>
                    </div>
                </div>

            </div>

            {/* Mobile version */}

            <div className="container services-section-mobile-version">
                <div className="service-card">
                    <div className="illustration">
                        <div className="circle"></div>
                        <img src={notepad} alt="" />
                        <br />
                        <p>خدمات التسجيل</p>
                    </div>
                </div>

                <div className="service-card">
                    <div className="illustration">
                        <div className="circle"></div>
                        <img src={airplane} alt="" />
                        <br />
                        <p>استقبال من المطار</p>
                    </div>
                </div>
            </div>

            <div className="container services-section-mobile-version">
                <div className="service-card">
                    <div className="illustration">
                        <div className="circle"></div>
                        <img src={illustration} alt="" />
                        <br />
                        <p>خدمة الاستشارات <br /> التعليمية</p>
                    </div>
                </div>

                <div className="service-card">
                    <div className="illustration">
                        <div className="circle"></div>
                        <img src={dorm} alt="" />
                        <br />
                        <p>إقامة الطالب</p>
                    </div>
                </div>
            </div>

            <div style={{ alignSelf: "center", marginTop: '2rem' }}>
                <button className="services-learn-more">
                    <Link style={{ textDecoration: "none" }} to="/ar/services">اقرأ المزيد <i class="fas fa-greater-than"></i></Link>
                </button>
            </div>

        </div>
    )
}

export default Services
