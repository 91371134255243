import axios from 'axios';
const FILTER_DATA_REQUEST = 'FILTER_DATA_REQUEST';
const FILTER_DATA_SUCCESS = 'FILTER_DATA_SUCCESS';
const FILTER_DATA_FAIL = 'FILTER_DATA_FAIL';


export const getFilterDataAction = () => async (dispatch) => {
    try {
        dispatch({
            type: FILTER_DATA_REQUEST
        })

        const { data } = await axios.get('/api/dashboard/')
        
        dispatch({
            type: FILTER_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FILTER_DATA_FAIL,
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail : error.message
        })
    }
}

export const getFilterDataReducer = (state={}, action) => {
    switch(action.type) {
        case FILTER_DATA_REQUEST:
            return {
                loading: true
            }
        case FILTER_DATA_SUCCESS:
            return {
                loading: false,
                data: action.payload
            }
        case FILTER_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}