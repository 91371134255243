import React, { useState, useEffect } from 'react'
import logo from '../assets/images/logo.png'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { login, logout, register } from '../actions/userActions'

import { Form, Button, Modal, Row, Col } from 'react-bootstrap'

const Header = ({ match, step1, step2, step3, step4, step5, step6, step7, location, history }) => {
    let path = "/ar" + match.url;

    const [show, setShow] = useState(false);
    const [showRegister, setShowregister] = useState(false);

    // Register
    const [emailRegister, setEmailregister] = useState('')
    const [name, setName] = useState('')
    const [passwordRegister, setPasswordregister] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const userRegister = useSelector(state => state.userRegister)
    const { loading, userInfo: userInfoRegister, error: errorRegister } = userRegister

    // Login
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    // Modal Display Functions
    const handleClose = () => setShow(false);
    const handleRegisterClose = () => setShowregister(false);
    const handleShow = () => setShow(true);
    const handleRegisterShow = () => setShowregister(true);
    // ******************************************************

    const redirect = location?.search ? location.search.split('=')[1] : '/'

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo, message: loginSuccess, error } = userLogin

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logout())
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    const submitHandlerRegister = (e) => {
        if (password != confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(register(name, email, password))
        }
    }

    useEffect(() => {
        if (!userInfo) {
            // history.push(redirect)
            // handleRegisterClose()
        }
    }, [userInfo, userInfoRegister])

    return (
        <>
            <div className="row mx-0 my-0">

                <div className="container-fluid welcome-header">
                    {userInfo ? (
                        <>
                            <h5 className="col-md-6 text-center welcome-provada-banner">Welcome to Provada Consultancy</h5>
                            {step7 && (
                                <span className="col-md-3 d-flex justify-content-center">
                                    <Link style={{ textDecoration: "none", color: "#ffffff" }} className="mx-3" to="/profile"><i class="fas fa-user mx-1"></i>{userInfo.name.toUpperCase()}</Link>
                                    <button style={{ backgroundColor: "transparent", color: "#ffffff" }} onClick={logoutHandler}>
                                        <i class="fas fa-sign-out-alt"><span style={{ color: "#ffffff" }}>Sign Out</span></i>
                                    </button>
                                </span>
                            )}

                        </>
                    ) : (
                        <>
                            <h5 className="col-md-6 text-center">Welcome to Provada Consultancy</h5>
                            {step7 && (
                                <Button style={{ backgroundColor: "#ffffff", color: "#eb8c1e", borderRadius: "10px" }} variant="flush"
                                    onClick={handleShow}>
                                    Sign In
                                </Button>
                            )}

                        </>
                    )}
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sign In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitHandler}>
                        {loginSuccess ? (
                            <>
                                <p className="alert alert-success">You have been successfully signed in</p>
                                {setTimeout(() => { handleClose() }, 1000)}
                            </>
                        ) : error ? (
                            <p className="alert alert-danger">{error}</p>
                        ) : (
                            <></>
                        )}
                        <Form.Group controlId='email'>
                            <Form.Label>
                                Email Address
                            </Form.Label>
                            <Form.Control required type='email' placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='password'>
                            <Form.Label>
                                Password
                            </Form.Label>
                            <Form.Control required type='password' placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Button style={{ backgroundColor: "#eb8c1e", borderRadius: "10px" }} className='mt-3' type='submit' variant='primary'>Sign In</Button>

                    </Form>

                    <Row className='py-3'>
                        <Col>
                            New Customer? <Button variant="flush" onClick={() => { handleRegisterShow(); handleClose() }}>Register</Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal
                show={showRegister}
                onHide={handleRegisterClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitHandlerRegister}>
                        {message && <p className="alert alert-danger">{message}</p>}
                        {errorRegister && <p className="alert alert-danger">{errorRegister} {setTimeout(() => { handleRegisterClose() }, 100000)}</p>}
                        {userInfoRegister && (
                            <>
                                <p className="alert alert-success">You have been successfully registered</p>
                                {setTimeout(() => { handleRegisterClose() }, 1000)}
                            </>
                        )
                        }
                        <Form.Group controlId='name'>
                            <Form.Label>
                                Full Name
                            </Form.Label>
                            <Form.Control required type='name' placeholder='Enter Name' value={name} onChange={(e) => setName(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='email'>
                            <Form.Label>
                                Email Address
                            </Form.Label>
                            <Form.Control required type='email' placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='password'>
                            <Form.Label>
                                Password
                            </Form.Label>
                            <Form.Control required type='password' placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId='passwordConfirm'>
                            <Form.Label>
                                Confirm Password
                            </Form.Label>
                            <Form.Control required type='password' placeholder='Confirm Password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Button style={{ backgroundColor: "#eb8c1e", borderRadius: "10px" }} className='mt-3' type='submit' variant='primary'>Sign up</Button>

                        <Row className='py-3'>
                            <Col>
                                Already have an account? <Button onClick={() => { handleShow(); handleRegisterClose() }} variant="flush">Sign In</Button>
                            </Col>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleRegisterClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <a className="navbar-brand" href="#"><img src={logo} alt="" /></a>
                    <button className="btn nav-lang-btn-v2-changes btn-out-menu" style={{ padding: "0", margin: "0 5px", display: 'none', marginLeft: 'auto' }}><Link style={{ textDecoration: "none", color: "#f6921e", border: "1px solid #f6921e", borderRadius: "10px", padding: "5px" }} to={path}>عربي</Link></button>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav-ul-v2-changes" style={{ marginLeft: 'auto' }}>
                            <li className="nav-item"><Link style={step1 ? { color: "#eb8c1e" } : {}} className="nav-link" to="/">HOME</Link></li>
                            <li className="nav-item"><Link style={step2 ? { color: "#eb8c1e" } : {}} className="nav-link" to="/services">SERVICES</Link></li>
                            <li className="nav-item"><Link style={step3 ? { color: "#eb8c1e" } : {}} className="nav-link" to="/blog">Blog</Link></li>
                            <li className="nav-item"><Link style={step4 ? { color: "#eb8c1e" } : {}} className="nav-link" to="/universities">UNIVERSITIES</Link></li>
                            <li className="nav-item"><Link style={step5 ? { color: "#eb8c1e" } : {}} className="nav-link" to="/registration">REGISTRATION</Link></li>
                            <li className="nav-item"><Link style={step6 ? { color: "#eb8c1e" } : {}} className="nav-link" to="/contact">About</Link></li>
                            {/* <li className="nav-item"><Link style={{backgroundColor: "#eb8c1e", color: "#ffffff"}} className="nav-link store-btn" to="/store"><i class="fas fa-book mx-1"></i>BOOK STORE</Link></li> */}
                        </ul>
                        <button className="btn nav-lang-btn-v2-changes btn-in-menu" style={{ padding: "0", margin: "0 5px" }}><Link style={{ textDecoration: "none", color: "#f6921e", border: "1px solid #f6921e", borderRadius: "10px", padding: "5px" }} to={path}>عربي</Link></button>
                        {/* <button className="btn" style={{ padding: "0", margin: "0 5px" }}><Link style={{textDecoration: "none", color: "#f6921e", border: "1px solid #f6921e", borderRadius: "10px", padding: "5px"}} to="/store"><i class="fas fa-book mx-1"></i>STORE</Link></button> */}

                        {/* <span className="navbar-text">
                    Navbar text with an inline element
                </span> */}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Header