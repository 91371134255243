import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import {
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userListReducer,
    userDeleteReducer,
    userUpdateReducer
} from './reducers/userReducers'
import {
    orderDetailsReducer,
    orderPayReducer,
    orderListMyReducer,
    orderListReducer,
    orderDeliverReducer,
    orderCreateReducer,
} from './reducers/orderReducers'

import { getDegreesDataReducer } from './redux/DegreesRedux'
import { getLanguagesDataReducer } from './redux/LanguagesRedux'
import { getMajorsDataReducer } from './redux/MajorsRedux'
import { getUniversitiesDataReducer } from './redux/UniversitiesRedux'

import { MajorDetailsReducer } from './reducers/universityPageReducer'
import { getSuccessDataReducer, getSuccessSingleDataReducer } from './redux/successStories'
import { getContactDataReducer } from './redux/contact'

import { getFilterDataReducer } from './redux/FilterData'
import { postFormDataReducer } from './redux/FormRedux'
import { handleChangeReducer } from './redux/ModalRedux'

const reducer = combineReducers({

    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,

    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderListMy: orderListMyReducer,
    orderList: orderListReducer,
    orderDeliver: orderDeliverReducer,

    MajorDetails: MajorDetailsReducer,
    getSuccessData: getSuccessDataReducer,
    getContactData: getContactDataReducer,

    getLangFilter: getLanguagesDataReducer,
    getMajorsFilter: getMajorsDataReducer,
    getDegreesFilter: getDegreesDataReducer,
    getUniversitiesFilter: getUniversitiesDataReducer,

    filterDataFun: getFilterDataReducer,
    postFormData: postFormDataReducer,

    handleChange: handleChangeReducer,
    getSuccessSingleData: getSuccessSingleDataReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

const initialState = {
    userLogin: {
        userInfo: userInfoFromStorage
    }
}

const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store
