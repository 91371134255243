import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

const AboutUs = ({match}) => {
    return (
        <>
        <Header match={match}/>
            jhng
        <Footer />
        </>
    )
}

export default AboutUs
