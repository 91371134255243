import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../../components/Footer'
import logo from '../../../assets/images/logo.png'
import userImage from '../../../assets/images/user.jpg'
import { Button } from 'react-bootstrap'
import { listMyOrders } from '../../../actions/orderActions'

import { logout } from '../../../actions/userActions'
import { getUserDetails, updateUserProfile } from '../../../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'

import { useDispatch, useSelector } from 'react-redux'

const ArProfilePage = ({ history }) => {

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logout())
    }

    let [books, setBooks] = useState([])

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetails = useSelector(state => state.userDetails)
    const { loading, user, error } = userDetails

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile

    const myOrders = useSelector(state => state.orderListMy)
    var {loading: ordersLoading, error: ordersError, orders} = myOrders


    let getBooks = async () => {
        let response = await fetch('/api/dashboard/store/')
        let data = await response.json()
        setBooks(data['products'])
    }

    useEffect(() => {
        if (!userInfo) {
            history.push('/ar/login')
        } else {
            if (!user || !user.name || success || userInfo._id !== user._id) {
                dispatch({
                    type: USER_UPDATE_PROFILE_RESET
                })
                dispatch(getUserDetails('profile'))
                getBooks()
                dispatch(listMyOrders())
                
            } else {
                console.log("")
            }
        }
    }, [dispatch, history, userInfo, user, success, orders])

    return (
        <>
            <div style={{margin: "0"}} className="row">
                
                
                <div style={{padding: "0"}} className="col-md-10">
                    <div style={{backgroundColor: "#ffffff", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)", padding:"10px 0"}} className="container-fluid">
                        <header className="container profile-header">
                            
                            <p style={{marginBottom: "0"}}>

                                <Button variant="flush" style={{fontSize: "25px"}} onClick={logoutHandler}>
                                    <i style={{fontSize: "18px"}}  class="fas fa-sign-out-alt"><span style={{color: "#f4911d", fontSize: "18px"}}> تسجيل الخروج</span></i>
                                </Button>

                                <Link style={{textDecoration: "none", marginLeft: "20px", fontSize: "25px"}} to="/ar/account">
                                    <i style={{fontSize: "18px"}}  class="fas fa-cog"></i>
                                </Link>
                                
                            </p>
                            <img style={{maxHeight: "150px", maxWidth: "150px"}} src={logo} alt="" />
                        </header>
                    </div>
                    

                    <div style={{marginTop: "100px"}} className="container">
                        <div style={{flexDirection: "row-reverse"}} className="row">
                            <div className="profile-image col-md-6">
                                
                                <p>{user?.name?.toUpperCase()} <br />
                                طالب
                                </p>
                                <img style={{maxWidth: "25%", marginLeft: "50px"}} src={userImage} alt="" />
                            </div>
                        </div>
                        
                    </div>

                    <div className="container mt-5">
                        <h3 style={{borderBottom: "1px solid black", color: "#f4911d", fontWeight: "bold", letterSpacing: "0", textAlign: "right"}}>مكتبتي</h3>
                        
                        <div className="container mt-5">
                            <div style={{flexDirection: "row-reverse"}} className="row">
                                {orders ? (
                                    <>
                                        {orders.map(order => (
                                            <>
                                                
                                                {order.isPaid ? (
                                                    // <div style={{margin: "0 10px", width: "15%"}} className="col-md-3 mx-3" key={order.id}>
                                                    //     <div style={{height: "90%"}}>
                                                    //         <img style={{width: "170px", height: "250px"}} src={order.product.image} alt="" />
                                                    //         <p style={{fontSize: "24px", textAlign: "center", fontSize: "15px"}}>{order.product.name}</p>
                                                    //     </div>
                                                    //     {/* <p className="book-price w-50">افتح</p> */}
                                                    //     <p className="book-price w-50"><a target="_blank" href={`https://development.osgpro.com${order.product.pdf}`} id="download" style={{color: "#ffffff",textDecoration: "none", cursor: "pointer"}}>افتح</a></p>
                                                    // </div>
                                                    <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', width: "15%"}} key={order.product.id} className="col-md-3 my-5">
                                                    <div style={{height: "90%"}}>
                                                        <img className="container" style={{width: "170px", height: "170px"}} src={order.product.image} alt="" />
                                                        <p className="container" style={{fontSize: "15px", letterSpacing: "0", lineHeight: "1rem", textAlign: "center"}}>{order.product.name.substring(0, 35)}</p>
                                                    </div>
                                                    <div style={{marginBottom: "1px"}}>
                                                    <p ><a target="_blank" href={`https://development.osgpro.com${order.product.pdf}`} className="book-price"  id="download" style={{color: "#ffffff",textDecoration: "none", cursor: "pointer"}}>افتح</a></p>
                                                    </div>
                                                </div>
                                                ) : (
                                                    <></>
                                                )}
                                                
                                            </>
                                        ))}
                                    </>
                                ) : !orders ? (
                                    <p> <Link to='/ar/store'>المتجر</Link> لا يوجد لديك اي كتاب في المكتبة اذهب الى   </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                    </div>
                    
                </div>

                <div className="col-md-2 profile-aside container">
                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", marginTop: "10px", color: "#ffffff"}} to="/ar/store">
                        <i style={{fontSize: "50px"}} class="fas fa-store-alt mb-2"></i>
                        <p className="text-center">المكتبة</p>
                    </Link>

                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", marginTop: "10px"}} to="/ar/profile">
                        <i style={{fontSize: "50px"}} class="fas fa-book mb-2"></i>
                        <p className="text-center">مكتبتي</p>
                    </Link>
                </div>
            </div>
        
            <Footer />
        </>
    )
}

export default ArProfilePage
