import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

const TopUniversities = () => {

    useEffect(() => {
        getUniversities()
    }, [])

    let [universities, setUniversities] = useState([])

    let getUniversities = async () => {
        let response = await fetch('/api/dashboard/universities/')
        let data = await response.json()
        data = data.slice(0, 4)
        setUniversities(data)
    }

    return (
        <div className="container famous-unis">
            <h2 style={{letterSpacing: "0"}} className="text-center text-ar2">أشهر الجامعات</h2>
            
            <div className="row">

                {universities.map(university => (
                     <div><Link to={`/ar/universities/${university.id}`}><img  src={university.logo} alt={university.name} className="container" /></Link></div>
                ))}

            </div>
        </div>
    )
}

export default TopUniversities
