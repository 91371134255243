import React, { useState, useEffect } from 'react'
import Hero1 from '../assets/images/HomePageImages/Hero1.png'
import Hero2 from '../assets/images/HomePageImages/Hero2.jpg'
import Hero3 from '../assets/images/HomePageImages/Hero3.png'
import learning from '../assets/images/Learning.gif'

const HeroCarousel = () => {

    let [headers, setHeaders] = useState([])

    let [header1, setHeader1] = useState([])
    let [header2, setHeader2] = useState([])
    let [header3, setHeader3] = useState([])

    let getHeaders = async () => {
        let response = await fetch(`/api/dashboard/sliders/`)
        let data = await response.json()
        setHeaders(data)
    }

    useEffect(() => {
        getHeaders()
        // headers.map(function(header) {
        //     if (header.id == 1) {
        //         setHeader1(header)
        //     } else if (header.id == 2) {
        //         setHeader2(header)
        //     } else if (header.id == 3) {
        //         setHeader3(header)
        //     }
        // })
    }, [])

    return (

        <header>
            <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">

                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>

                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img style={{ filter: 'brightness(100%)', objectFit: "cover" }} className="hero-image" src={learning} alt="" width="100%" height="100%" />

                        <div class="container hero-text-container">
                            <div class="carousel-caption text-start">
                                <h2 className="headline">Study In Turkey</h2>
                                {/* <p style={{color: "#000000"}}>Accredited in Europe.</p> */}
                                {/* <p><a class="hero-learn-more" href="#">Learn More <i class="fas fa-greater-than"></i></a></p> */}
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <img className="hero-image" style={{ objectFit: "cover" }} src={Hero1} alt="" width="100%" height="100%" />

                        <div class="container hero-text-container">
                            <div class="carousel-caption text-start">
                                <h2 className="headline">Universities Accredited World Wide</h2>
                                {/* <p>Accredited in Europe.</p> */}
                                {/* <p><a class="hero-learn-more" href="#">Learn More <i class="fas fa-greater-than"></i></a></p> */}
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <img className="hero-image" style={{ objectFit: "cover" }} src={Hero2} alt="" width="100%" height="100%" />

                        <div class="container hero-text-container">
                            <div class="carousel-caption text-start">
                                <h2 className="headline">Suitable Tuition Fees </h2>
                                {/* <p>Accredited in Europe.</p> */}
                                {/* <p><a class="hero-learn-more" href="#">Learn More <i class="fas fa-greater-than"></i></a></p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </header>
    )
}

export default HeroCarousel
