import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import Loader from '../components/Loader'
// import Message from '../components/Message'
import { login } from '../../actions/userActions'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ArHeader from '../../components/ArabicComponents/ArHeader'
import ArFooter from '../../components/ArabicComponents/ArFooter'

function ArLogin({ history, location, match }) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    console.log(location)
    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/ar/store'

    const userLogin = useSelector(state => state.userLogin)
    const { loading, userInfo, error } = userLogin

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
    }, [history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    return (
        <>
        {/* <ArHeader match={match} history={history} /> */}
        <div className="container my-5">
            <h1 style={{letterSpacing: "0", textAlign: "right"}}>
                تسجيل الدخول
            </h1>

            <Form onSubmit={submitHandler}>
            {error && (<p className="alert alert-danger">{error}</p>)}
                <Form.Group style={{display: 'flex', flexDirection: "column"}} controlId='email'>
                    <Form.Label style={{textAlign: "right"}}>
                        البريد الالكتروني
                    </Form.Label>
                    <Form.Control className="ar-input" type='email' placeholder='ادخل البريد الالكتروني' value={email} onChange={(e) => setEmail(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Form.Group style={{display: 'flex', flexDirection: "column"}}  controlId='password'>
                    <Form.Label style={{textAlign: "right"}}>
                        كلمة المرور
                    </Form.Label>
                    <Form.Control className="ar-input" type='password' placeholder='ادخل كلمة المرور' value={password} onChange={(e) => setPassword(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Button style={{float: "right"}} className='mt-3' type='submit' variant='primary'>تسجيل الدخول</Button>

            </Form>

            <Row className='py-3'>
                <Col>
                    لايوجد لديك حساب؟ <Link style={{letterSpacing: "0"}} to={redirect ? `/ar/signup?redirect=${redirect}` : '/signup'}>سجل الان</Link>
                </Col>
            </Row>
        </div>
        {/* <ArFooter /> */}
        </>
    )
}

export default ArLogin
