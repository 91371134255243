import React, {useState, useEffect} from 'react'
import offer from '../assets/images/WhatWeOffer/offer.png'

const WhatWeOffer = () => {

    let [body, setBody] = useState([])

    useEffect(() => {
        getBody()
    }, [])

    let getBody = async () => {
        let response = await fetch('api/dashboard/weoffer')
        let data = await response.json()
        setBody(data[0])
    }
    return (
        <div className="container">
            <div className="row">
                
                <div className="col-md-6">
                    <img className="img-fluid" src={offer} alt="" />
                </div>
                 
                <div className="col-md-6 offer-column">
                    <h2>We Offer</h2>
                    <p>{body.body}</p>
                </div>
            </div>
        </div>
    )
}

export default WhatWeOffer
