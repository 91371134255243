import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import Loader from '../components/Loader'
// import Message from '../components/Message'
import { login } from '../actions/userActions'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Login({ history, location, match }) {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()

    const redirect = 
    // location.search ? location.search.split('=')[1] 
    // : 
    '/store'

    const userLogin = useSelector(state => state.userLogin)
    const { loading, userInfo, error } = userLogin

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
    }, [history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    return (
        <>
        <Header match={match} history={history} />
        <div className="container my-5">
            <h1>
                Sign In
            </h1>

            <Form onSubmit={submitHandler}>
                {error && (<p className="alert alert-danger">{error}</p>)}
                <Form.Group controlId='email'>
                    <Form.Label>
                        Email Address
                    </Form.Label>
                    <Form.Control type='email' placeholder='Enter Email' value={email} onChange={(e) => setEmail(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='password'>
                    <Form.Label>
                        Password
                    </Form.Label>
                    <Form.Control type='password' placeholder='Enter Password' value={password} onChange={(e) => setPassword(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Button className='mt-3' type='submit' variant='primary'>Sign In</Button>

            </Form>

            <Row className='py-3'>
                <Col>
                    New Customer? <Link to={redirect ? `/signup?redirect=${redirect}` : '/signup'}>Register</Link>
                </Col>
            </Row>
        </div>
        <Footer />
        </>
    )
}

export default Login
