import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import ArFooter from '../../../components/ArabicComponents/ArFooter'
import ArHeader from '../../../components/ArabicComponents/ArHeader'

import useGeoLocation from "react-ipgeolocation";

const ArBook = ({ match, history }) => {

    const location = useGeoLocation();

    let id = match.params.id
    let [sideBooks, setSidebooks] = useState([])


    let [book, setBook] = useState([])
    let [books, setBooks] = useState([])

    let [categories, setCategories] = useState([])

    let getBook = async () => {
        let response = await fetch(`/api/dashboard/store/${id}`)
        let data = await response.json()
        setBook(data)
    }

    let getBooks = async () => {
        let response = await fetch('/api/dashboard/store/')
        let data = await response.json()
        setBooks(data['products'])
        setSidebooks(data['products'].slice(0, 6))
    }

    let getCategories = async () => {
        let response = await fetch('/api/dashboard/store/categories')
        let data = await response.json()
        setCategories(data)
    }

    useEffect(() => {
        getCategories()
        getBook()
        getBooks()
        window.scrollTo(0, 0)
    }, [id])

    return (

        <>
        <ArHeader step7 match={match} />

    <div className="container mt-5">
        <div className="row">

            

            <div className="col-md-11">

                <div className="row">
                    

                    <div className="col-md-8">
                        <h4 style={{letterSpacing: "0", textAlign: "right", direction: "rtl"}}>{book.name_ar}</h4>
                        <hr />
                        <p style={{letterSpacing: "0", textAlign: "right", direction: "rtl"}}>{book.info_ar}</p>
                        <button className="my-5" style={{backgroundColor: "#eb8c1e", color: "#ffffff", padding: "10px", borderRadius: "5px", float: "right"}}>
                        <Link style={{textDecoration: "none", color: "#ffffff", letterSpacing: "0"}} to={`/ar/placeOrder/${book.id}`}>اشتري الان</Link>
                        </button>
                    </div>

                    <div className="col-md-4">
                        <img style={{width: "100%"}} src={book.image} alt="" />
                        {location.country == 'TR' ? (
                            <p className="w-50" style={{backgroundColor: "#eb8c1e", color: "#ffffff", textAlign: "center", borderRadius: "5px", margin: "10px 0", padding: "10px 0", float: "right"}}>TL {book.price_in_tl}</p>
                        ) : (
                            <p className="w-50" style={{backgroundColor: "#eb8c1e", color: "#ffffff", textAlign: "center", borderRadius: "5px", margin: "10px 0", padding: "10px 0", float: "right"}}>${book.price}</p>
                        )}                    </div>
                </div>
                
                
            </div>
            <div style={{flexDirection: "row-reverse"}} className="row">

                    {sideBooks.map(book => (
                        <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', width: "15%"}} key={book.id} className="col-md-3 my-5">
                        <div style={{height: "90%"}}>
                        <Link style={{textDecoration: "none"}} to={`/book/${book.id}`}>
                            <img className="container" style={{width: "170px", height: "170px"}} src={book.image} alt="" />
                            <p className="container" style={{fontSize: "15px", letterSpacing: "0", lineHeight: "1rem", textAlign: "center"}}>{book.name.substring(0, 35)}</p>
                        </Link>
                        </div>
                        <div style={{marginBottom: "1px"}}>
                        {location.country == 'TR' ? (
                                    <Link className="book-price container" style={{textDecoration: "none", color: "#ffffff"}} to={`/ar/placeOrder/${book.id}`}>TL {book.price_in_tl}</Link>
                                ) : (
                                    <Link className="book-price container" style={{textDecoration: "none", color: "#ffffff"}} to={`/ar/placeOrder/${book.id}`}>${book.price}</Link>
                            )}                        </div>
                    </div>
                    ))}
                </div>
            
        </div>
    </div>

        <ArFooter />
        </>
    )
}

export default ArBook
