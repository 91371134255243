import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import counter1 from '../assets/images/University/counter1.png'
import counter2 from '../assets/images/University/counter2.png'
import counter3 from '../assets/images/University/counter3.png'

// import altinbas from '../assets/images/Universities/Altinbas.png'
// import uskudar from '../assets/images/Universities/Uskudar.png'
// import istinye from '../assets/images/Universities/Istinye.png'
// import medipol from '../assets/images/Universities/Medipol.png'

import test from '../assets/images/University/test.png'

import { Accordion, Card } from "react-bootstrap";

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Loader from '../components/Loader'

const UniversityPage = ({ match }) => {

    let id = match.params.id

    let [university, setUniversity] = useState()
    let [majors, setMajors] = useState([])
    let [faculties, setFaculties] = useState([])
    let [degrees, setDegrees] = useState([])

    let [filter, setFilter] = useState([])

    let getUniversity = async () => {
        let response = await fetch(`/api/dashboard/universities/${id}`)
        let data = await response.json()
        await setUniversity(data)
        await console.log(data)
    }

    let getUniversityMajors = async () => {
        let response = await fetch(`/api/dashboard/majors/uni/${id}`)
        let data = await response.json()
        setMajors(data)
        setFilter(data)
    }

    let getFaculties = async () => {
        let response = await fetch(`/api/dashboard/faculties/`)
        let data = await response.json()
        setFaculties(data)
    }

    let getDegrees = async () => {
        let response = await fetch("/api/dashboard/degrees")
        let data = await response.json()
        setDegrees(data)
    }


    let filteredMajors = majors
    let filterDegrees = (id) => {
        filteredMajors = majors.filter(major => {
            return major.degree.id == id;
        });
        setFilter(filteredMajors)
    }

    const [visible, setVisible] = useState(5)

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4)
    }

    const showLessItems = () => {
        setVisible(5)
    }

    useEffect(() => {
        getUniversity()
        getUniversityMajors()
        getFaculties()
        getDegrees()
        window.scrollTo(0, 0)
    }, [])

    const seen = new Set()

    const filteredArr = majors.filter(el => {
        const duplicate = seen.has(el.degree.id);
        seen.add(el.degree.id);
        return !duplicate;
    });

    return (

        <>
            {!university ? (
                <Loader />
            ) : university ? (
                <>
                    <Header match={match} step4 />
                    <div className="container-fluid">
                        <div style={{ marginTop: "50px" }} className="container">
                            <h4 style={{ textAlign: "center" }}>Home <i style={{ color: "#f6992d" }} class="fas fa-greater-than"></i><Link style={{ textDecoration: "none" }} to="/universities">Universities</Link>
                                <i style={{ color: "#f6992d" }} class="fas fa-greater-than"></i> {university['university'].name}
                            </h4>
                        </div>
                    </div>

                    <div className="container university-container">
                        <div className="row">
                            <div className="col-md-6">
                                <h2>{university['university'].name}</h2>
                                <p>{university['university'].info}</p>
                            </div>

                            <div className="col-md-5">
                                <img className="img-fluid" src={university['university'].logo} alt="" />
                                {/* <p style={{marginTop: "5%"}}>{university.sub_info}</p> */}
                            </div>
                        </div>
                    </div>

                    <div className="container uni-numbers">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 style={{ color: "#f69422" }}>
                                    {university['university'].name} in numbers
                                </h2>

                                <p>
                                    {university['university'].info2}
                                </p>
                            </div>

                            <div className="col-md-5">
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false
                                    }}
                                >
                                    {university['images'].map(el => (
                                        <SwiperSlide key={el.id} className="swipe">
                                            <img height={300} width={300} className="slider-image" style={{ border: '1px solid #f6992d', borderRadius: '15px' }} src={el.image} alt="" />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>

                    </div>

                    <div className="registration-steps-band mb-5">
                        <h2 style={{ textAlign: "center" }}>Available Majors</h2>
                        <div className="container degree-filter-university">
                            {filteredArr.map(major => (
                                <p key={major.degree.id}>
                                    <button id={major.degree.id} onClick={(e) => filterDegrees(e.target.id)}>
                                        {major.degree.name}
                                    </button>
                                </p>
                            ))}
                        </div>

                    </div>

                    <div className="container">

                        {filter.length !== 0 ? (filter.slice(0, visible).map(major => (
                            <>

                                <Accordion className="accordion-background" key={major.id}>

                                    <Accordion.Item eventKey={major.name}>
                                        <Accordion.Header style={{ backgroundColor: "#f6921e", fontSize: "28px" }}><h4 style={{ color: "#000000" }}>{major.name}</h4></Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                <>
                                                    <div style={{ borderTop: "1px solid #f69422", margin: "20px 0" }} className="row" key={major.id}>
                                                        <div style={{ color: "#f69422" }} className="col-md-2">Language <br /><p style={{ color: "#000000", marginTop: "15px" }}>
                                                            {major.languages == 1 ? "English & Turkish" : major.languages == 2 ? "English" : major.languages == 3 ? "Turkish" : "Arabic"}
                                                        </p></div>
                                                        <div style={{ color: "#f69422" }} className="col-md-2">cost <br /><p style={{ color: "#000000", marginTop: "15px" }}><del>${major.pre_sale_fees}</del></p></div>
                                                        <div style={{ color: "#f69422" }} className="col-md-2">sale <br /><p style={{ color: "#000000", marginTop: "15px" }}>${major.post_sale_fees}</p></div>
                                                        <div style={{ color: "#f69422" }} className="col-md-2">Years <br /><p style={{ color: "#000000", marginTop: "15px" }}>{major.study_years}</p></div>
                                                        <div style={{ color: "#f69422", marginTop: "30px" }} className="col-md-2"><Link to={`/register/${major.degree.name}/${id}/${major.id}`} style={{ color: "#000000", textDecoration: "none", backgroundColor: "#f79b31", padding: "10px", color: "#ffffff" }}>Apply Now</Link></div>
                                                    </div>
                                                </>
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>


                            </>
                        ))) : (
                            <p style={{ textAlign: "center" }}>
                                No available majors based on the degree
                            </p>
                        )}
                        {visible < filter.length ? (
                            <div className="d-flex justify-content-center">
                                <button className="p-2 my-5" onClick={showMoreItems} style={{ color: 'white', textDecoration: 'none', border: 'none', outline: "none", padding: '0 1rem', backgroundColor: "#eb8c1e" }} target="_blank">LOAD MORE</button>
                            </div>
                        ) : filter.length < 5 ? (
                            <>
                            </>
                        ) : (
                            <div className="d-flex justify-content-center">
                                <button className="p-2 my-5" onClick={showLessItems} style={{ color: 'white', textDecoration: 'none', border: 'none', outline: "none", padding: '0 1rem', backgroundColor: "#eb8c1e" }} target="_blank">LOAD LESS</button>
                            </div>
                        )}
                    </div>

                    <div className="registration-steps-band">
                        <h2 style={{ textAlign: "center" }}>Video Gallery</h2>
                    </div>

                    <div style={{ display: 'flex', justifyContent: "center" }} className="container mt-5 mb-5">
                        <iframe width="560" height="315" src={university['university'].url1} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    <Footer />
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default UniversityPage
