import React, { useState, useEffect } from 'react'

import hero from '../assets/images/RegisterationApplication/header.gif'
import { Form, Button } from 'react-bootstrap'
import axios from 'axios'
import { postFormDataAction } from '../redux/FormRedux'
import { useDispatch, useSelector } from 'react-redux'

const RegisterApplicationDegree = ({ match }) => {

    const dispatch = useDispatch()

    let id = match.params.id
    let uniId = match.params.uni
    let majorId = match.params.major

    let [bachelor, setBachelor] = useState(false)
    let [master, setMaster] = useState(false)
    let [phd, setPhd] = useState(false)

    let [degrees, setDegrees] = useState([])
    let [uni, setUni] = useState([])
    let [major, setMajor] = useState([])

    let getDegrees = async () => {
        let response = await fetch(`/api/dashboard/filter/degrees/`)
        let data = await response.json()
        setDegrees(data)
    }

    let getUni = async () => {
        let response = await fetch(`/api/dashboard/universities/${uniId}/`)
        let data = await response.json()
        setUni(data)
    }

    let getMajor = async () => {
        let response = await fetch(`/api/dashboard/majors/${majorId}/`)
        let data = await response.json()
        setMajor(data)
    }

    let loadForm = (value) => {
        if (value == "Bachelor" || value == "Associate") {
            setBachelor(true)
            setMaster(false)
            setPhd(false)
        } else if (value == "PhD") {
            setMaster(false)
            setBachelor(false)
            setPhd(true)
        } else if (value == "Master's Degree") {
            setBachelor(false)
            setMaster(true)
            setPhd(false)
        }
    }

    let handleChange = (value) => {
        if (value == 1) {
            setBachelor(true)
            setMaster(false)
            setPhd(false)
        } else if (value == 2) {
            setMaster(false)
            setBachelor(false)
            setPhd(true)
        } else if (value == 3) {
            setBachelor(false)
            setMaster(true)
            setPhd(false)
        }
    }

    useEffect(() => {
        getDegrees()
        loadForm(id)
        getUni()
        getMajor()
    }, [bachelor, master, phd])

    let [name, setName] = useState('')
    let [motherName, setMothername] = useState('')
    let [fatherName, setFathername] = useState('')
    let [phone, setPhone] = useState('')
    let [birth, setBirth] = useState('')
    let [passport, setPassport] = useState('')
    let [highSchool, setHighschool] = useState('')
    let [email, setEmail] = useState('')
    let [homeAddress, setHomeaddress] = useState('')
    let [nufus, setNufus] = useState('')
    let [gender, setGender] = useState('')
    let [personalPhoto, setPersonalphoto] = useState('')
    let [passportCopy, setPassportcopy] = useState('')

    let [bacheloreDegree, setBacheloredegree] = useState('')
    let [bacheloreDegreeTranscript, setBacheloredegreetranscript] = useState('')
    let [bacheloreDegreeTranslated, setBacheloredegreetranslated] = useState('')
    let [bacheloreDegreeTranscriptTranslated, setBacheloredegreetranscripttranslated] = useState('')

    let [mastersDegree, setMastersdegree] = useState('')
    let [mastersDegreeTranscript, setMastersdegreetranscript] = useState('')
    let [mastersDegreeTranslated, setMastersdegreetranslated] = useState('')
    let [mastersDegreeTranscriptTranslated, setMastersdegreetranscripttranslated] = useState('')

    let [highSchoolCopy, setHighschoolcopy] = useState('')
    let [highSchoolTrans, setHighschooltrans] = useState('')
    let [highSchoolDiplomaTranslated, setHighschooldiplomatranslated] = useState('')
    let [highSchoolTransTranslated, setHighschooltranstranslated] = useState('')

    let [message, setMessage] = useState(false)
    let [success, setSuccess] = useState(false)

    const [formSuccess, setFormSuccess] = useState(false)

    const postFormData = useSelector(state => state.postFormData)
    const { loading, error, data } = postFormData

    let form = {
        'name': name,
        'motherName': motherName,
        'fatherName': fatherName,
        'phone': phone,
        'birth': birth,
        'passport': passport,
        'highSchool': highSchool,
        'email': email,
        'homeAddress': homeAddress,
        'nufus': nufus,
        'gender': gender,
        // 'personalPhoto': personalPhoto,
        // 'passportCopy': passportCopy,
        // 'highSchoolCopy': highSchoolCopy,
        // 'highSchoolTrans': highSchoolTrans,
        // 'highSchoolDiplomaTranslated': highSchoolDiplomaTranslated,
        // 'highSchoolTransTranslated': highSchoolTransTranslated,

        // 'bacheloreDegree': bacheloreDegree,
        // 'bacheloreDegreeTranscript': bacheloreDegreeTranscript,
        // 'bacheloreDegreeTranslated': bacheloreDegreeTranslated,
        // 'bacheloreDegreeTranscriptTranslated': bacheloreDegreeTranscriptTranslated,

        // 'mastersDegree': mastersDegree,
        // 'mastersDegreeTranscript': mastersDegreeTranscript,
        // 'mastersDegreeTranslated': mastersDegreeTranslated,
        // 'mastersDegreeTranscriptTranslated': mastersDegreeTranscriptTranslated
    }

    let createForm = async (e) => {
        e.preventDefault()
        if (name == "" || motherName == "" || fatherName == "" || phone == "" || birth == "" || passport == ""
            || highSchool == "" || email == ""
        ) {
            setMessage(true)
            window.scrollTo(0, 500)
        } else {
            setSuccess(true)
            window.scrollTo(0, 500)
        }
    }

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('personalPhoto', file)
        // console.log(form)
        // setUploading(true)

        axios.post(`/api/dashboard/upload/${data.id}/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    const uploadPassportHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('passportCopy', file)
        // console.log(form)
        // setUploading(true)

        axios.post(`/api/dashboard/upload2/${data.id}/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    const uploadHighSchoolDiplomaHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('highSchoolCopy', file)
        // console.log(form)
        // setUploading(true)

        axios.post(`/api/dashboard/upload3/${data.id}/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    let handleSubmit = () => {
        createForm()
    }

    const formSubmitHandler = () => {
        dispatch(postFormDataAction(form))
    }

    useEffect(() => {
        if (data) {
            setFormSuccess(true)
        }
    }, [data])

    return (
        <div className="container-fluid">

            <div className="container application-container-hero">
                <img style={{ alignSelf: "center" }} className="img-fluid w-25" src={hero} alt="" />
            </div>

            <div className="container application-container mt-5">
                <select
                    onChange={(e) => handleChange(e.target.value)}
                    style={{ padding: "20px", margin: "10px 0" }}
                    disabled
                >
                    <option value={id} selected disabled hidden>{id}</option>
                    {degrees.map(degree => (
                        <option value={degree.id} key={degree.id}>
                            {degree.name}
                        </option>
                    ))}
                </select>
                <p style={{ color: "#f6921e", fontWeight: "900" }}> {uni['university']?.name} - {major.name} </p>
            </div>

            {bachelor ? (
                <>
                    <Form onSubmit={createForm}>
                        <div className="container application-container mt-5">

                            {message && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    Application Form</h3><p className="alert alert-danger">Please fill in the fields below in English only, other formats will not be accepted </p>
                                </div>
                            )}

                            {success && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    Application Form</h3><p className="alert alert-success">Form Submitted Successfully </p>
                                </div>
                            )}


                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setName(e.target.value) }} value={name?.name} required placeholder="Full Name" type="text" />
                                    <Form.Control onChange={(e) => { setMothername(e.target.value) }} value={motherName?.motherName} required placeholder="Mother's Name" type="text" />
                                </Form.Group>
                                {/* <input onChange={(e) => {setName(e.target.value)}} value={name?.name} required placeholder="Full Name" type="text" />
                        <input onChange={(e) => {setMothername(e.target.value)}} value={motherName?.motherName} required placeholder="Mother's Name" type="text" /> */}
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setFathername(e.target.value)}} value={fatherName?.fatherName} required placeholder="Father's Name" type="text" />
                        <input onChange={(e) => {setPhone(e.target.value)}} value={phone?.phone} required placeholder="Phone Number" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setFathername(e.target.value) }} value={fatherName?.fatherName} required placeholder="Father's Name" type="text" />
                                    <Form.Control onChange={(e) => { setPhone(e.target.value) }} value={phone?.phone} required placeholder="Phone Number" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setBirth(e.target.value)}} value={birth?.birth} required placeholder="Birth Date(YYYY-MM-DD)" type="text" />
                        <input onChange={(e) => {setPassport(e.target.value)}} value={passport?.passport} required placeholder="Passport Number" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setBirth(e.target.value) }} value={birth?.birth} required placeholder="Birth Date(YYYY-MM-DD)" type="date" />
                                    <Form.Control onChange={(e) => { setPassport(e.target.value) }} value={passport?.passport} required placeholder="Passport Number" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setHighschool(e.target.value)}} value={highSchool?.highSchool} required placeholder="High School Name" type="text" />
                        <input onChange={(e) => {setEmail(e.target.value)}} value={email?.email} required placeholder="Email Address" type="email" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHighschool(e.target.value) }} value={highSchool?.highSchool} required placeholder="High School Name" type="text" />
                                    <Form.Control onChange={(e) => { setEmail(e.target.value) }} value={email?.email} required placeholder="Email Address" type="email" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setHomeaddress(e.target.value)}} value={homeAddress?.homeAddress} required placeholder="Home Address" type="text" />
                        <input onChange={(e) => {setNufus(e.target.value)}} value={nufus?.nufus} required placeholder="Nüfüs Kayit" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHomeaddress(e.target.value) }} value={homeAddress?.homeAddress} required placeholder="Home Address" type="text" />
                                </Form.Group>
                            </div>

                            <div id="gender" className="container">
                                <select required style={{ padding: "10px", margin: "10px 0" }} onChange={(e) => { setGender(e.target.value) }} value={gender?.gender} id="gender">
                                    <option hidden disabled selected>Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    {/* <label htmlFor="male">Male</label>
                                    <input id="male" type="radio" name="gender" />
                                    <label htmlFor="female">Female</label>
                                    <input id="female" type="radio" name="gender" /> */}
                                </select>
                            </div>
                            {!formSuccess && name !== "" && motherName !== "" && fatherName !== "" && phone !== "" && birth !== "" && passport !== ""
                                && highSchool !== "" && email !== "" && (
                                    <button onClick={formSubmitHandler} type="button" className="register-btn">Next</button>
                                )}
                        </div>

                        {formSuccess && (
                            <div className="container applicaiton2 application-container mt-5 mb-5 py-1">

                                <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    Documents</h3><p className="alert alert-danger">Please upload high quality valid documents with only two languages English or Turkey </p>
                                </div>
                                <div className="container w-75 register-application-mobile-inputs">
                                    <label className="container w-75">Personal Photo</label>

                                    <input
                                        onChange={uploadFileHandler}
                                        required type="file" accept='.png, .jpg, .jpeg' />
                                </div>

                                <div className="container w-75 register-application-mobile-inputs">
                                    <label className="container w-75">Passport Copy</label>
                                    <input onChange={uploadPassportHandler} value={passportCopy?.passportCopy} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                </div>

                                <div className="container w-75 register-application-mobile-inputs">
                                    <label className="container w-75">High School Diploma (original)</label>
                                    <input onChange={uploadHighSchoolDiplomaHandler} value={highSchoolCopy?.highSchoolCopy} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                </div>

                                <div className="container w-75 register-application-mobile-inputs">
                                    <label className="container w-75">High school Transcript (original)</label>
                                    <input onChange={(e) => { setHighschooltrans(e.target.value) }} value={highSchoolTrans?.highSchoolTrans} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                </div>

                                <div className="container w-75 register-application-mobile-inputs">
                                    <label className="container w-75">High school diploma (translated to turkish or english)</label>
                                    <input onChange={(e) => { setHighschooldiplomatranslated(e.target.value) }} value={highSchoolDiplomaTranslated?.highSchoolDiplomaTranslated} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                </div>

                                <div className="container w-75 register-application-mobile-inputs">
                                    <label className="container w-75">High school transcript (translated to turkish or english)</label>
                                    <input onChange={(e) => { setHighschooltranstranslated(e.target.value) }} value={highSchoolTransTranslated?.highSchoolTransTranslated} required type="file" required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                </div>
                                <button type="submit" className="register-btn">Register</button>
                            </div>
                        )}

                    </Form>
                </>
            ) : master ? (
                <>
                    <Form onSubmit={createForm}>
                        <div className="container application-container mt-5" style={{ backgorundColor: 'red' }}>

                            {message && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    Application Form</h3><p className="alert alert-danger">Please fill in the fields below in English only, other formats will not be accepted </p>
                                </div>
                            )}

                            {success && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    Application Form</h3><p className="alert alert-success">Form Submitted Successfully </p>
                                </div>
                            )}
                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setName(e.target.value) }} value={name?.name} required placeholder="Full Name" type="text" />
                                    <Form.Control onChange={(e) => { setMothername(e.target.value) }} value={motherName?.motherName} required placeholder="Mother's Name" type="text" />
                                </Form.Group>
                                {/* <input onChange={(e) => {setName(e.target.value)}} value={name?.name} required placeholder="Full Name" type="text" />
                        <input onChange={(e) => {setMothername(e.target.value)}} value={motherName?.motherName} required placeholder="Mother's Name" type="text" /> */}
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setFathername(e.target.value)}} value={fatherName?.fatherName} required placeholder="Father's Name" type="text" />
                        <input onChange={(e) => {setPhone(e.target.value)}} value={phone?.phone} required placeholder="Phone Number" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setFathername(e.target.value) }} value={fatherName?.fatherName} required placeholder="Father's Name" type="text" />
                                    <Form.Control onChange={(e) => { setPhone(e.target.value) }} value={phone?.phone} required placeholder="Phone Number" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setBirth(e.target.value)}} value={birth?.birth} required placeholder="Birth Date(YYYY-MM-DD)" type="text" />
                        <input onChange={(e) => {setPassport(e.target.value)}} value={passport?.passport} required placeholder="Passport Number" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setBirth(e.target.value) }} value={birth?.birth} required placeholder="Birth Date(YYYY-MM-DD)" type="date" />
                                    <Form.Control onChange={(e) => { setPassport(e.target.value) }} value={passport?.passport} required placeholder="Passport Number" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setHighschool(e.target.value)}} value={highSchool?.highSchool} required placeholder="High School Name" type="text" />
                        <input onChange={(e) => {setEmail(e.target.value)}} value={email?.email} required placeholder="Email Address" type="email" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHighschool(e.target.value) }} value={highSchool?.highSchool} required placeholder="High School Name" type="text" />
                                    <Form.Control onChange={(e) => { setEmail(e.target.value) }} value={email?.email} required placeholder="Email Address" type="email" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setHomeaddress(e.target.value)}} value={homeAddress?.homeAddress} required placeholder="Home Address" type="text" />
                        <input onChange={(e) => {setNufus(e.target.value)}} value={nufus?.nufus} required placeholder="Nüfüs Kayit" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHomeaddress(e.target.value) }} value={homeAddress?.homeAddress} required placeholder="Home Address" type="text" />
                                </Form.Group>
                            </div>

                            <div id="gender" className="container">
                                <select required style={{ padding: "10px", margin: "10px 0" }} onChange={(e) => { setGender(e.target.value) }} value={gender?.gender} id="gender">
                                    <option hidden disabled selected>Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                        </div>

                        <div className="container applicaiton2 application-container mt-5 mb-5 py-1" >

                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                Documents</h3><p className="alert alert-danger">Please upload high quality valid documents with only two languages English or Turkey </p>
                            </div>
                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Personal Photo</label>
                                <input required onChange={(e) => { setPersonalphoto(e.target.files[0]); console.log(e.target.files) }} value={personalPhoto?.personalPhoto} type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Passport Copy</label>
                                <input onChange={(e) => { setPassportcopy(e.target.value) }} required placeholder="Phone Number" type="file" />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Bachelor's Degree Diploma (original)</label>
                                <input onChange={(e) => { setBacheloredegree(e.target.value) }} required placeholder="Passport Number" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Bachelor's Degree Transcript (original)</label>
                                <input onChange={(e) => { setBacheloredegreetranscript(e.target.value) }} required placeholder="Email Address" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Bachelor's Degree diploma (translated to turkish or english)</label>
                                <input onChange={(e) => { setBacheloredegreetranslated(e.target.value) }} required placeholder="Nüfüs Kayit" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Bachelor's Degree transcript (translated to turkish or english)</label>
                                <input onChange={(e) => { setBacheloredegreetranscripttranslated(e.target.value) }} required placeholder="Nüfüs Kayit" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <button type="submit" className="register-btn">Register</button>
                        </div>
                    </Form>
                </>
            ) : phd ? (
                <>
                    <Form onSubmit={createForm}>
                        <div className="container application-container mt-5" >

                            {message && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    Application Form</h3><p className="alert alert-danger">Please fill in the fields below in English only, other formats will not be accepted </p>
                                </div>
                            )}

                            {success && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    Application Form</h3><p className="alert alert-success">Form Submitted Successfully </p>
                                </div>
                            )}
                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setName(e.target.value) }} value={name?.name} required placeholder="Full Name" type="text" />
                                    <Form.Control onChange={(e) => { setMothername(e.target.value) }} value={motherName?.motherName} required placeholder="Mother's Name" type="text" />
                                </Form.Group>
                                {/* <input onChange={(e) => {setName(e.target.value)}} value={name?.name} required placeholder="Full Name" type="text" />
                        <input onChange={(e) => {setMothername(e.target.value)}} value={motherName?.motherName} required placeholder="Mother's Name" type="text" /> */}
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setFathername(e.target.value)}} value={fatherName?.fatherName} required placeholder="Father's Name" type="text" />
                        <input onChange={(e) => {setPhone(e.target.value)}} value={phone?.phone} required placeholder="Phone Number" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setFathername(e.target.value) }} value={fatherName?.fatherName} required placeholder="Father's Name" type="text" />
                                    <Form.Control onChange={(e) => { setPhone(e.target.value) }} value={phone?.phone} required placeholder="Phone Number" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setBirth(e.target.value)}} value={birth?.birth} required placeholder="Birth Date(YYYY-MM-DD)" type="text" />
                        <input onChange={(e) => {setPassport(e.target.value)}} value={passport?.passport} required placeholder="Passport Number" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setBirth(e.target.value) }} value={birth?.birth} required placeholder="Birth Date(YYYY-MM-DD)" type="date" />
                                    <Form.Control onChange={(e) => { setPassport(e.target.value) }} value={passport?.passport} required placeholder="Passport Number" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setHighschool(e.target.value)}} value={highSchool?.highSchool} required placeholder="High School Name" type="text" />
                        <input onChange={(e) => {setEmail(e.target.value)}} value={email?.email} required placeholder="Email Address" type="email" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHighschool(e.target.value) }} value={highSchool?.highSchool} required placeholder="High School Name" type="text" />
                                    <Form.Control onChange={(e) => { setEmail(e.target.value) }} value={email?.email} required placeholder="Email Address" type="email" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                {/* <input onChange={(e) => {setHomeaddress(e.target.value)}} value={homeAddress?.homeAddress} required placeholder="Home Address" type="text" />
                        <input onChange={(e) => {setNufus(e.target.value)}} value={nufus?.nufus} required placeholder="Nüfüs Kayit" type="text" /> */}
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHomeaddress(e.target.value) }} value={homeAddress?.homeAddress} required placeholder="Home Address" type="text" />
                                </Form.Group>
                            </div>

                            <div id="gender" className="container">
                                <select required style={{ padding: "10px", margin: "10px 0" }} onChange={(e) => { setGender(e.target.value) }} value={gender?.gender} id="gender">
                                    <option hidden disabled selected>Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                        </div>

                        <div className="container applicaiton2 application-container mt-5 mb-5 py-1 ">

                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} className="container mt-5"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                Documents</h3><p className="alert alert-danger">Please upload high quality valid documents with only two languages English or Turkey </p>
                            </div>
                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Personal Photo</label>
                                <input onChange={(e) => setPersonalphoto(e.target.value)} required placeholder="Full Name" type="file" accept='.png, .jpg, .jpeg, .pdf' onChange={(e) => { setPersonalphoto(e.target.value) }} value={personalPhoto?.personalPhoto} />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Passport Copy</label>
                                <input onChange={(e) => setPassportcopy(e.target.value)} required placeholder="Phone Number" type="file" accept='.png, .jpg, .jpeg, .pdf' onChange={(e) => { setPassportcopy(e.target.value) }} value={passportCopy?.passportCopy} />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Masters Diploma (original)</label>
                                <input onChange={(e) => setMastersdegree(e.target.value)} value={mastersDegree?.mastersDegree} required placeholder="Passport Number" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Masters Transcript (original)</label>
                                <input onChange={(e) => setMastersdegreetranscript(e.target.value)} value={mastersDegreeTranscript?.mastersDegreeTranscript} required placeholder="Email Address" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Masters diploma (translated to turkish or english)</label>
                                <input onChange={(e) => setMastersdegreetranslated(e.target.value)} value={mastersDegreeTranslated?.mastersDegreeTranslated} required placeholder="Nüfüs Kayit" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <label className="container w-75">Masters transcript (translated to turkish or english)</label>
                                <input onChange={(e) => setMastersdegreetranscripttranslated(e.target.value)} value={mastersDegreeTranscriptTranslated?.mastersDegreeTranscriptTranslated} required placeholder="Nüfüs Kayit" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                            </div>

                            <button type="submit" className="register-btn">Register</button>
                        </div>
                    </Form>
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export default RegisterApplicationDegree
