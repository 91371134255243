import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import Loader from '../components/Loader'
// import Message from '../components/Message'
import { register } from '../../actions/userActions'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ArHeader from '../../components/ArabicComponents/ArHeader'
import ArFooter from '../../components/ArabicComponents/ArFooter'

function ArRegister({ location, history, match }) {

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userRegister = useSelector(state => state.userRegister)
    const { loading, userInfo, error } = userRegister

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
    }, [history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password != confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(register(name, email, password))
        }
    }

    return (
        <>
        <ArHeader match={match} />
        <div className="container my-5">
            <h1 style={{letterSpacing: "0", textAlign: "right"}}>تسجيل الحساب</h1>

            <Form onSubmit={submitHandler}>
                <Form.Group style={{display: 'flex', flexDirection: "column"}} controlId='name'>
                    <Form.Label style={{letterSpacing: "0", textAlign: "right"}}>
                        الاسم الكامل
                    </Form.Label>
                    <Form.Control className="ar-input" required type='name' placeholder='ادخل الاسم الكامل' value={name} onChange={(e) => setName(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Form.Group style={{display: 'flex', flexDirection: "column"}} controlId='email'>
                    <Form.Label style={{letterSpacing: "0", textAlign: "right"}}>
                        البريد الالكتروني
                    </Form.Label>
                    <Form.Control className="ar-input" required type='email' placeholder='ادخل البريد الالكتروني' value={email} onChange={(e) => setEmail(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Form.Group style={{display: 'flex', flexDirection: "column"}} controlId='password'>
                    <Form.Label style={{letterSpacing: "0", textAlign: "right"}}>
                        كلمة المرور
                    </Form.Label>
                    <Form.Control className="ar-input" required type='password' placeholder='ادخل كلمة المرور' value={password} onChange={(e) => setPassword(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Form.Group style={{display: 'flex', flexDirection: "column"}} controlId='passwordConfirm'>
                    <Form.Label style={{letterSpacing: "0", textAlign: "right"}}>
                        تأكيد كلمة المرور
                    </Form.Label>
                    <Form.Control className="ar-input" required type='password' placeholder='تأكيد كلمة المرور' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}>

                    </Form.Control>
                </Form.Group>

                <Button style={{float: "right"}} className='mt-3' type='submit' variant='primary'>تسجيل</Button>

                <Row className='py-3'>
                <Col>
                    يوجد لديك حساب؟ <Link style={{letterSpacing: 0}} to={redirect ? `/ar/login?redirect=${redirect}` : '/login'}>سجل من هنا</Link>
                </Col>
            </Row>

            </Form>
        </div>

        <ArFooter />
        </>
    )
}

export default ArRegister
