import React, { useState, useEffect } from 'react'
import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { login, logout, register } from '../../actions/userActions'

import { Form, Button, Modal, Row, Col } from 'react-bootstrap'

const ArHeader = ({ match, step1, step2, step3, step4, step5, step6, step7, location, history }) => {
    let path = match.url;
    path = path.replace('/ar', '')

    const [show, setShow] = useState(false);
    const [showRegister, setShowregister] = useState(false);

    // Register
    const [emailRegister, setEmailregister] = useState('')
    const [name, setName] = useState('')
    const [passwordRegister, setPasswordregister] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const userRegister = useSelector(state => state.userRegister)
    const { loading, userInfo: userInfoRegister, error: errorRegister } = userRegister

    // Login
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    // Modal Display Functions
    const handleClose = () => setShow(false);
    const handleRegisterClose = () => setShowregister(false);
    const handleShow = () => setShow(true);
    const handleRegisterShow = () => setShowregister(true);
    // ******************************************************

    const redirect = location?.search ? location.search.split('=')[1] : '/ar'

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo, message: loginSuccess, error } = userLogin

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logout())
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    const submitHandlerRegister = (e) => {
        if (password != confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(register(name, email, password))
        }
    }

    useEffect(() => {
        if (userInfo || userInfoRegister) {
            // history.push(redirect)
            // handleRegisterClose()
        }
    }, [userInfo, userInfoRegister])

    return (
        <>
            <div className="container-fluid welcome-header-ar welcome-header">
                {/* <h5 className="ar-text2" style={{letterSpacing: "0"}}>مرحبا بكم في مؤسسة بروفادا</h5> */}
                {userInfo ? (
                    <>
                        <h5 style={{ letterSpacing: "0" }} className="col-md-6 text-center welcome-provada-banner welcome-provada-banner-ar">مرحبا بكم في مؤسسة بروفادا</h5>
                        {step7 && (
                            <span className="col-md-3 d-flex justify-content-center">
                                <Link style={{ textDecoration: "none", color: "#ffffff" }} className="mx-3" to="/ar/profile"><i class="fas fa-user mx-1"></i>{userInfo.name.toUpperCase()}</Link>
                                <button style={{ backgroundColor: "transparent", color: "#ffffff" }} onClick={logoutHandler}>
                                    <i class="fas fa-sign-out-alt"><span style={{ color: "#ffffff" }}>تسجيل الخروج</span></i>
                                </button>
                            </span>
                        )}

                    </>
                ) : (
                    <>
                        <h5 style={{ letterSpacing: "0" }} className="col-md-6 text-center">مرحبا بكم في مؤسسة بروفادا</h5>
                        {step7 && (
                            <Button style={{ backgroundColor: "#ffffff", color: "#eb8c1e", borderRadius: "10px" }} variant="flush"
                                onClick={handleShow}>
                                تسجيل الدخول
                            </Button>
                        )}

                    </>
                )}
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                className="ar-modal"
            >
                <Modal.Header style={{ flexDirection: "row-reverse" }} closeButton>
                    <Modal.Title style={{ letterSpacing: "0" }}>تسجيل الدخول</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitHandler}>
                        {loginSuccess ? (
                            <>
                                <p style={{ letterSpacing: "0" }} className="alert alert-success">تم تسجيل الدخول بنجاح</p>
                                {setTimeout(() => { handleClose() }, 1000)}
                            </>
                        ) : error ? (
                            <p className="alert alert-danger">لم يتم العثور على حساب  بالبيانات المقدمة</p>
                        ) : (
                            <></>
                        )}
                        <Form.Group className="d-flex flex-column ar-input" controlId='email'>
                            <Form.Label style={{ letterSpacing: "0", textAlign: "right" }}>
                                البريد الالكتروني
                            </Form.Label>
                            <Form.Control required type='email' placeholder='ادخل البريد الالكتروني' value={email} onChange={(e) => setEmail(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="d-flex flex-column ar-input" controlId='password'>
                            <Form.Label style={{ letterSpacing: "0", textAlign: "right" }}>
                                كلمة المرور
                            </Form.Label>
                            <Form.Control required type='password' placeholder='ادخل كلمة المرور ' value={password} onChange={(e) => setPassword(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Button style={{ backgroundColor: "#eb8c1e", borderRadius: "10px", float: "right" }} className='mt-3' type='submit' variant='primary'>تسحيل الدخول</Button>

                    </Form>

                    <Row className='py-3'>
                        <Col style={{ letterSpacing: "0" }}>
                            <Button variant="flush" onClick={() => { handleRegisterShow(); handleClose() }}>سجل الان</Button>  لا يوجد لديك حساب؟
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        اغلاق
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal
                show={showRegister}
                onHide={handleRegisterClose}
                backdrop="static"
                keyboard={false}
                className="ar-modal"
            >
                <Modal.Header style={{ flexDirection: "row-reverse" }} closeButton>
                    <Modal.Title style={{ letterSpacing: "0" }}>تسجيل</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={submitHandlerRegister}>
                        {message && <p className="alert alert-danger">{message}</p>}
                        {errorRegister && <p className="alert alert-danger">{errorRegister}</p>}
                        {userInfoRegister && (
                            <>
                                <p className="alert alert-success">تم تسجيلك ب نجاح</p>
                                {setTimeout(() => { handleRegisterClose() }, 1000)}
                            </>
                        )
                        }
                        <Form.Group className="d-flex flex-column ar-input" controlId='name'>
                            <Form.Label style={{ letterSpacing: "0", textAlign: "right" }}>
                                الاسم الكامل
                            </Form.Label>
                            <Form.Control required type='name' placeholder='ادخل الاسم الكامل' value={name} onChange={(e) => setName(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="d-flex flex-column ar-input" controlId='email'>
                            <Form.Label style={{ letterSpacing: "0", textAlign: "right" }}>
                                البريد الالكتروني
                            </Form.Label>
                            <Form.Control required type='email' placeholder='ادخل البريد الالكتروني' value={email} onChange={(e) => setEmail(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="d-flex flex-column ar-input" controlId='password'>
                            <Form.Label style={{ letterSpacing: "0", textAlign: "right" }}>
                                كلمة المرور
                            </Form.Label>
                            <Form.Control required type='password' placeholder='ادخل كلمة المرور  ' value={password} onChange={(e) => setPassword(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="d-flex flex-column ar-input" controlId='passwordConfirm'>
                            <Form.Label style={{ letterSpacing: "0", textAlign: "right" }}>
                                تأكيد كلمة المرور
                            </Form.Label>
                            <Form.Control required type='password' placeholder='تأكيد كلمة المرور' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}>

                            </Form.Control>
                        </Form.Group>

                        <Button style={{ backgroundColor: "#eb8c1e", borderRadius: "10px", float: 'right' }} className='mt-3' type='submit' variant='primary'>تأكيد التسجيل</Button>

                        <Row className='py-3'>
                            <Col>
                                <Button onClick={() => { handleShow(); handleRegisterClose() }} variant="flush">سجل</Button> يوجد لديك حساب؟
                            </Col>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleRegisterClose}>
                        اغلاق
                    </Button>

                </Modal.Footer>
            </Modal>

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <Link className="navbar-brand" to='/ar'><img src={logo} alt="" /></Link>
                    <button className="btn btn-out-menu" style={{ padding: "0", margin: "0 5px", marginLeft: 'auto', display: 'none' }}><Link style={{ textDecoration: "none", color: "#f6921e", border: "1px solid #f6921e", borderRadius: "10px", padding: "5px" }} to={path}>En</Link></button>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div style={{ justifyContent: "end" }} className="collapse navbar-collapse collapse-nav-v2-ar" id="navbarText">
                        <ul style={{ marginLeft: "auto", marginRight: "100px" }} className="navbar-nav ml-auto mb-2 mb-lg-0 nav-ul-v2-changes-ar">
                            {/* <li className="nav-item"><Link style={{backgroundColor: "#eb8c1e", color: "#ffffff"}} className="nav-link store-btn" to="/ar/store">مكتبة <i class="fas fa-book mx-1"></i></Link></li> */}
                            <li className="nav-item nav-item-ar"><Link style={{ letterSpacing: "0", fontFamily: 'Tajawal !important' }, step6 ? { color: "#eb8c1e" } : { letterSpacing: "0" }} className="nav-link nav-linkar ar-text" to="/ar/contact">من نحن</Link></li>
                            <li className="nav-item nav-item-ar"><Link style={{ letterSpacing: "0", fontFamily: 'Tajawal !important' }, step5 ? { color: "#eb8c1e" } : { letterSpacing: "0" }} className="nav-link nav-linkar ar-text" to="/ar/registration">التسجيل</Link></li>
                            <li className="nav-item nav-item-ar"><Link style={{ letterSpacing: "0", fontFamily: 'Tajawal !important' }, step4 ? { color: "#eb8c1e" } : { letterSpacing: "0" }} className="nav-link nav-linkar ar-text" to="/ar/universities">الجامعات</Link></li>
                            <li className="nav-item nav-item-ar"><Link style={{ letterSpacing: "0", fontFamily: 'Tajawal !important' }, step3 ? { color: "#eb8c1e" } : { letterSpacing: "0" }} className="nav-link nav-linkar ar-text" to="/ar/blog">مقالات</Link></li>
                            <li className="nav-item nav-item-ar"><Link style={{ letterSpacing: "0", fontFamily: 'Tajawal !important' }, step2 ? { color: "#eb8c1e" } : { letterSpacing: "0" }} className="nav-link nav-linkar ar-text" to="/ar/services">الخدمات</Link></li>
                            <li className="nav-item nav-item-ar"><Link style={{ letterSpacing: "0", fontFamily: 'Tajawal !important' }, step1 ? { color: "#eb8c1e" } : { letterSpacing: "0" }} className="nav-link nav-linkar ar-text" to="/ar">الرئيسية</Link></li>
                        </ul>
                        <button className="btn btn-in-menu" style={{ padding: "0", margin: "0 5px" }}><Link style={{ textDecoration: "none", color: "#f6921e", border: "1px solid #f6921e", borderRadius: "10px", padding: "5px" }} to={path}>En</Link></button>

                        {/* <span className="navbar-text">
                    Navbar text with an inline element
                </span> */}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default ArHeader