import axios from 'axios';
const GET_DATA_REQUEST = 'GET_DATA_REQUEST';
const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
const GET_DATA_FAILURE = 'GET_DATA_FAILURE';

export const getContactDataAction = () => {
    return (dispatch) => {
        dispatch({ type: GET_DATA_REQUEST });
        axios.get('/api/dashboard/contact-data')
            .then(res => {
                dispatch({ type: GET_DATA_SUCCESS, payload: res.data });
            })
            .catch(err => {
                dispatch({ type: GET_DATA_FAILURE, payload: err });
            })
    }
}

export const getContactDataReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case GET_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}