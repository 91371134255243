import React, { useEffect, useState } from 'react'
import Counter from '../components/Counter'
import Footer from '../components/Footer'
import Header from '../components/Header'
import HeroCarousel from '../components/HeroCarousel'
import HowWeWork from '../components/HowWeWork'
import OwlCarousel from '../components/OwlCarousel'
import Services from '../components/Services'
import SuccessStories from '../components/SuccessStories'
import TopUniversities from '../components/TopUniversities'
import WhatWeOffer from '../components/WhatWeOffer'
import { Accordion, Button } from "react-bootstrap";
import AboutProvada from '../components/AboutProvada'
import Loader from '../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getContactDataAction } from '../redux/contact'

const HomePage = ({ match, history }) => {

    const dispatch = useDispatch()

    const getContactData = useSelector(state => state.getContactData)
    const { loading, data, error } = getContactData

    let [faqs, setFaqs] = useState([])

    let getFaqs = async () => {
        let response = await fetch('/api/dashboard/store/faqs')
        let data = await response.json()
        setFaqs(data)
    }

    useEffect(() => {
        getFaqs()
        window.scrollTo(0, 0)
        dispatch(getContactDataAction())
    }, [dispatch])

    return (
        <div>
            {loading ? (
                <Loader />
            ) : data ? (
                <>
                    <Header match={match} history={history} step1 />
                    <HeroCarousel />
                    {/* <AboutProvada /> */}
                    <div className='about-provada'>
                        <h2>About Provada</h2>
                        <div className="container">
                            <div className='vision'>
                                <h2>VISION</h2>
                                <img src={data.vision_image} alt="" />
                                <p>{data.vision_en?.length > 300 ? data.vision_en.slice(0, 300) : data.vision_en}</p>
                            </div>

                            <div className='mission'>
                                <h2>MISSION</h2>
                                <img src={data.mission_image} alt="" />
                                <p>{data.mission_en?.length > 300 ? data.mission_en.slice(0, 300) : data.mission_en}</p>
                            </div>

                            <div>
                                <h2>HISTORY</h2>
                                <img src={data.history_image} alt="" />
                                <p>{data.history_en?.length > 300 ? data.history_en.slice(0, 300) : data.history_en}</p>
                            </div>
                        </div>
                    </div>
                    <TopUniversities />
                    <Services />
                    <Counter />
                    <HowWeWork />
                    <WhatWeOffer />
                    {/* <SuccessStories /> */}
                    <OwlCarousel />

                    <div className="container faq-container mb-5 w-50">
                        <h3 className="mb-5" style={{ color: "#f6921e", textAlign: "center", textTransform: "initial" }}>FAQs</h3>
                        <Accordion className="accordion-background">
                            {faqs.map(faq => (
                                <Accordion.Item eventKey={faq.id}>
                                    <Accordion.Header style={{ backgroundColor: "#f6921e", fontSize: "28px" }}><h4 style={{ color: "#000000", textTransform: "initial" }}>{faq.question}</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <>
                                            <div className="row">
                                                <p>{faq.answer}</p>
                                            </div>
                                        </>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                    <Footer />
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export default HomePage
