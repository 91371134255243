import React, { useState, useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import bg from '../../../assets/images/bg.png'
import { createOrder } from '../../../actions/orderActions'
import { ORDER_CREATE_RESET } from '../../../constants/orderConstants'

import ArFooter from '../../../components/ArabicComponents/ArFooter'
import ArHeader from '../../../components/ArabicComponents/ArHeader'
import ArLogin from '../ArLogin'

import useGeoLocation from "react-ipgeolocation";

function ArPlaceOrderScreen({ history, match, location }) {

    let bookId = match.params.id

    const location2 = useGeoLocation();
    
    let [book, setBook] = useState([])
 
    const orderCreate = useSelector(state => state.orderCreate)
    const { order, error, success } = orderCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const dispatch = useDispatch()

    let code = 0

    let getBook = async () => {
        let response = await fetch(`/api/dashboard/store/${bookId}`)
        let data = await response.json()
        setBook(data)
    }

    const placeOrder = () => {
        dispatch(createOrder({
            product: book,
            paymentMethod: "PayPal",
            totalPrice: book.price,
    }, book.id))
    }

    useEffect(() => {
        // if (!userInfo) {
        //     history.push('/ar/login')
        // }
        if (success) {
            history.push(`/ar/order/${order._id}`)
            dispatch({ type: ORDER_CREATE_RESET })
        }
        getBook()
    }, [success, history, userInfo])

    return (
        <>
           
            {userInfo ? (
                <>
                <ArHeader step7 match={match} />
                <div className="container my-5">
                <Row>

                <Col md={4}>
                        <Card>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h2 style={{letterSpacing: "0", textAlign: "right"}}>ملخص الطلب</h2>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col style={{letterSpacing: "0", textAlign: "right"}}>{book.name}</Col>
                                        <Col style={{letterSpacing: "0", textAlign: "right"}}>اسم الكتاب</Col>
                                    </Row>
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <Row>
                                        <Col style={{letterSpacing: "0", textAlign: "right"}}>
                                        {location2.country == 'TR' ? (
                                                <Col>TL {book.price_in_tl}</Col>
                                            ) : (
                                                <Col>${book.price}</Col>
                                            )}</Col>
                                        <Col style={{letterSpacing: "0", textAlign: "right"}}>السعر</Col>   
                                    </Row>
                                </ListGroup.Item>

                                {error && <ListGroup.Item>{error}</ListGroup.Item>}

                                <ListGroup.Item>
                                    <Button
                                        type='button'
                                        className='btn-block'
                                        // disabled={cart.cartItems === 0}
                                        onClick={placeOrder}
                                        style={{backgroundColor: "#eb8c1e", float: "right"}}
                                    >
                                        اطلب الان
                                    </Button>
                                </ListGroup.Item>

                            </ListGroup>
                        </Card>
                    </Col>

                    <Col style={{display: "flex", justifyContent: "center", alignItems: 'center'}} md={8}>
                        <div className="row img col-4">
                            <img src={bg} alt="" className=""/>
                        </div>
                    </Col>

                </Row>
            </div>
            <ArFooter />
            </>
            ) : (
                <ArLogin match={match} history={history} location={location} />
            )}
            
            
        </>
    )
}

export default ArPlaceOrderScreen