import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/Footer'
import logo from '../../assets/images/logo.png'
import userImage from '../../assets/images/user.jpg'

import { Button } from 'react-bootstrap'

import { listMyOrders } from '../../actions/orderActions'

import { logout } from '../../actions/userActions'
import { getUserDetails, updateUserProfile } from '../../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../../constants/userConstants'

import { useDispatch, useSelector } from 'react-redux'

const ProfilePage = ({ history, location, match }) => {

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logout())
    }

    let [books, setBooks] = useState([])

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetails = useSelector(state => state.userDetails)
    const { loading, user, error } = userDetails

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile

    const myOrders = useSelector(state => state.orderListMy)
    var {loading: ordersLoading, error: ordersError, orders} = myOrders


    let getBooks = async () => {
        let response = await fetch('/api/dashboard/store/')
        let data = await response.json()
        setBooks(data['products'])
    }

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (!user || !user.name || success || userInfo._id !== user._id) {
                dispatch({
                    type: USER_UPDATE_PROFILE_RESET
                })
                dispatch(getUserDetails('profile'))
                getBooks()
                dispatch(listMyOrders())
                
            } else {
                console.log("")
            }
        }
    }, [dispatch, history, userInfo, user, success, orders])

    return (
        <>
            <div style={{margin: "0"}} className="row">
                <div className="col-md-2 profile-aside container">
                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", marginTop: "10px", color: "#ffffff"}} to="/store">
                        <i style={{fontSize: "50px"}} class="fas fa-store-alt mb-2"></i>
                        <p className="text-center">STORE</p>
                    </Link>

                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", marginTop: "10px"}} to="/profile">
                        <i style={{fontSize: "50px"}} class="fas fa-book mb-2"></i>
                        <p className="text-center">BOOKS</p>
                    </Link>
                </div>
                
                <div style={{padding: "0"}} className="col-md-10">
                    <div style={{backgroundColor: "#ffffff", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)", padding:"10px 0"}} className="container-fluid">
                        <header className="container profile-header">
                            <img style={{maxHeight: "150px", maxWidth: "150px"}} src={logo} alt="" />
                            <p style={{marginBottom: "0"}}>
                                <Button variant="flush" style={{fontSize: "25px"}} onClick={logoutHandler}>
                                    <i style={{fontSize: "18px"}} class="fas fa-sign-out-alt"><span style={{color: "#f4911d", fontSize: "18px"}}>Sign Out</span></i>
                                </Button>

                                <Link style={{textDecoration: "none", marginLeft: "20px", fontSize: "25px"}} to="/account">
                                    <i style={{fontSize: "18px"}}  class="fas fa-cog"></i>
                                </Link>
                                
                            </p>
                        </header>
                    </div>

                    <div style={{marginTop: "100px"}} className="container">
                        <div  className="row">
                            <div className="profile-image col-md-6">
                                <img style={{maxWidth: "25%"}} src={userImage} alt="" />
                                <p>{user?.name?.toUpperCase()} <br />
                                student
                                </p>
                            </div>
                        </div>
                        
                    </div>

                    <div className="container mt-5">
                        <h3 style={{borderBottom: "1px solid black", color: "#f4911d", fontWeight: "bold"}}>My Books</h3>
                        
                        <div className="container mt-5">
                            <div className="row">
                                {orders ? (
                                    <>
                                    {/* 
                                     {sideBooks.map(book => (
                        <div style={{margin: "0 10px"}} key={book.id} className="col-md-3">
                            <Link style={{textDecoration: "none"}} to={`/book/${book.id}`}>
                                <img style={{width: "170px", height: "250px"}} src={book.image} alt="" />
                                <p style={{fontSize: "24px"}}>{book.name}</p>
                            </Link>
                            <p className="book-price2 my-3">${book.price}</p>
                            <hr />
                        </div>
                    ))} */}
                                        {orders.map(order => (
                                            <>
                                                {order.isPaid ? (

                                                <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', width: "15%"}} key={order.product.id} className="col-md-3 my-5">
                                                <div style={{height: "90%"}}>
                                                    
                                                        <img className="container" style={{width: "170px", height: "170px"}} src={order.product.image} alt="" />
                                                        <p className="container" style={{fontSize: "15px", textAlign: "center"}}>{order.product.name.substring(0, 35)}</p>
                                                    
                                                </div>
                                                <div style={{marginBottom: "1px"}}>
                                                    <p className=""><a target="_blank" href={`https://development.osgpro.com${order.product.pdf}`} className="book-price" id="download" style={{color: "#ffffff",textDecoration: "none", cursor: "pointer"}}>Open</a></p>
                                                </div>
                                                </div>
                                                    // <div style={{margin: "0 10px", width: "15%"}} className="col-md-3 mx-3" key={order.id}>
                                                    //     <div style={{height: "90%"}}>
                                                    //         <img style={{width: "170px", height: "250px"}} src={order.product.image} alt="" />
                                                    //         <p style={{fontSize: "20px", textAlign: "center", fontSize: "15px"}}>{order.product.name}</p>
                                                    //     </div>
                                                    //     <p className="book-price w-50"><a target="_blank" href={`https://development.osgpro.com${order.product.pdf}`} id="download" style={{color: "#ffffff",textDecoration: "none", cursor: "pointer"}}>Open</a></p>
                                                    // </div>
                                                ) : (
                                                    <></>
                                                )}
                                                
                                            </>
                                        ))}
                                    </>
                                ) : !orders ? (
                                    <p>You have no orders, go to <Link to='/store'>store</Link> and buy </p>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>
        
            <Footer />
        </>
    )
}

export default ProfilePage
