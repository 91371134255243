import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'

import { Accordion, Button, Card, Row, Col} from "react-bootstrap";

import hero from '../../../assets/images/Store/hero.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import ArFooter from '../../../components/ArabicComponents/ArFooter';
import ArHeader from '../../../components/ArabicComponents/ArHeader';

import useGeoLocation from "react-ipgeolocation";

const ArBooks = ({ match, history }) => {

    const location = useGeoLocation();

    let [filter, setFilter] = useState([])
    let [typeSearch, setTypesearch] = useState("")
    let [sideBooks, setSidebooks] = useState([])

    let [books, setBooks] = useState([])
    let [categories, setCategories] = useState([])
    let [faqs, setFaqs] = useState([])

    let getBooks = async () => {
        let response = await fetch('/api/dashboard/store/')
        let data = await response.json()
        setBooks(data['products'])
        setFilter(data['products'])
        setSidebooks(data['products'].slice(0, 3))
    }

    // categories

    let getCategories = async () => {
        let response = await fetch('/api/dashboard/store/categories')
        let data = await response.json()
        setCategories(data)
    }

    let getFaqs = async () => {
        let response = await fetch('/api/dashboard/store/faqs')
        let data = await response.json()
        setFaqs(data)
    }

    let filteredBooks = books
    let filterBooks = (id) => {
        filteredBooks = books.filter(book => {
            return book.category == id;
        });
        setFilter(filteredBooks)
    }

    let typeSearchFilter = (value) => {
        filteredBooks = books.filter(book => {
            return book.name.toLowerCase().includes(value.toLowerCase())
        })
        setFilter(filteredBooks)
    }

    let refreshFilter = () => {
        setFilter(books) 
    }

    useEffect(() => {
        getBooks()
        getCategories()
        getFaqs()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ArHeader step7 match={match} />
            <header>
                <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">

                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <img src={hero} alt="" style={{width: "100vw", height: "50vh", objectFit: "cover"}} />
                        
                            <div style={{right: "1%"}} class="container hero-text-container">
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'end'}} class="carousel-caption text-start">
                                    <h2 style={{letterSpacing: "0" , textAlign: "right"}} className="headline ar-text2">خصم 50% على الكتب</h2>
                                    <p style={{textAlign: "right"}} className="ar-text">  لطلابنا المشتركين معنا</p>
                                    {/* <p class="text-end"><a style={{letterSpacing: "0"}} class="hero-learn-more" href="#">تعلم المزيد <i class="fas fa-greater-than"></i></a></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className="container mt-5">
                <div className="row">

                <div className="col-md-8">
                        <div style={{flexDirection: "row-reverse"}} className="row books-row">

                            {filter.length !== 0 ? (filter.map(book => (

                                <div style={{display: "flex", flexDirection: 'column', alignItems: 'center'}} key={book.id} className="col-md-3 my-5">
                                    <div style={{height: "90%"}}>
                                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", alignItems: "center"}} to={`/ar/book/${book.id}`}>
                                        <img className="container" style={{width: "170px", height: "170px"}} src={book.image} alt="" />
                                        <p className="container" style={{fontSize: "15px", letterSpacing: "0", lineHeight: "1rem", textAlign: "center", paddingTop: "3px"}}>{book.name.substring(0, 35)}</p>
                                    </Link>
                                    </div>
                                    <div style={{marginBottom: "1px"}}>
                                        <Link className="book-price container" style={{textDecoration: "none", color: "#ffffff", textAlign: "center"}} to={`/ar/placeOrder/${book.id}`}>{location.country == 'TR' ? (
                                                <>TL{book.price_in_tl}</>
                                            ) : (
                                                <>${book.price}</>
                                        )}</Link>
                                    </div>
                                </div>
                            ))) : (
                                <p style={{textAlign: "center"}}>لا توجد كتب متاحة بناءً على الفئة المحددة</p>
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 books-filter-input">
                        <div className="row books-filter-aside-ar mt-5 align-items-center">
                            <input onChange={(e) => {typeSearchFilter(e.target.value)}} placeholder="البحث..." className="books-filter-ar w-50" type="text" />
                            <p style={{fontSize: "14px", textAlign: "center"}}>
                                <Button onClick={refreshFilter} style={{width: "fit-content", alignSelf: "center"}} variant="flush" className="font-weight-bold"> جميع الفئات</Button>
                            </p>
                            {
                                categories.map((category, index) => (
                                    <p style={{fontSize: "14px", textAlign: "center"}} key={index + 1}>
                                        {/* filter Books */}
                                        <Button variant="flush" id={category.id} onClick={(e) => filterBooks(e.target.id)}>
                                            {category.name_ar}
                                        </Button>
                                    </p>
                                ))
                            }
                        </div>

                        <div className="row products-aside">
                            {/* <p className="products-title-aside" style={{ fontWeight: "bold", letterSpacing: "0px", textAlign: "right"}}>الكتب</p> */}
                            {sideBooks.map(book => (
                                <div style={{flex: "0"}} className="col-md">
                                    <div style={{alignItems: "center", flexDirection: "column-reverse"}} key={book.id} className="row mt-2">
                                        
                                        <div className="col-md-6">
                                            <p>{book.name}</p>
                                            {location.country == 'TR' ? (
                                                <p>TL{book.price_in_tl}</p>
                                            ) : (
                                                <p>${book.price}</p>
                                            )}
                                        </div>
                                        <Link className="col-md-6" style={{textDecoration: "none"}} to={`/ar/book/${book.id}`}>
                                            <img style={{width: "80px", height: "100px"}} className="book-image" src={book.image} alt="" />
                                        </Link>
                                        
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid banner-books my-5">

            <div style={{marginTop: "50px"}} className="container">
                    <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    style={{display: 'flex'}}
                    className="success-stories-page"
                    loop={true}
                    autoplay={{delay: 2500,
                    disableOnInteraction: false}}   
                    >
                    {books.map(book => (
                        <SwiperSlide style={{margin: "0 "}}>
                            <div className="container swiper-container-mobile" style={{backgroundColor: "#384168"}}>
                                <div className="row mx-5">
                                    <div  className="col-md-8 special-offer-carousel-ar">
                                        <h3 className="text-white special-offer-carousel-h3" style={{letterSpacing: "0"}}>خصم 50% بالمية </h3>
                                        <br />
                                        <p className="text-white" style={{letterSpacing: "0"}}>طلابنا المشتركين معنا</p>
                                        {location.country == 'TR' ? (
                                                <p className="text-white">TL{book.price_in_tl}</p>
                                            ) : (
                                                <p className="text-white">${book.price}</p>
                                        )}
                                        <button style={{backgroundColor: "#f6921e", padding: "5px", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px", color: "#ffffff"}}>

                                            <Link to={`/ar/book/${book.id}`} style={{textDecoration: "none", color: "#ffffff"}}>احصل على العرض</Link>
                                            </button>
                                    </div>
                                    <div className="col-md-4 special-offer-carousel-col-4">
                                        <img className="my-3 special-offer-carousel-image" src={book.image} alt="" />
                                    </div>
                                </div>
                                
                            </div>
                        </SwiperSlide>
                    ))}
                    </Swiper>
            </div>
            </div>

            <div className="container faq-container mb-5 w-50">
                <h3 className="mb-5" style={{color: "#f6921e", textAlign: "center", letterSpacing: "0"}}>الأسئلة الشائعة</h3>
                {faqs.map(faq => (
                    <Accordion className="arAccordion" key={faq.id}>

                    <Accordion.Item eventKey={faq.question}>
                    <Accordion.Header style={{backgroundColor: "#f6921e", fontSize: "28px"}}><h4 style={{color: "#000000", letterSpacing: "0", direction: "rtl", textAlign: 'right'}}>{faq.question_ar}</h4></Accordion.Header>
                        <Accordion.Body>
                            <>
                                <div className="row">
                                    <p style={{letterSpacing: "0", textAlign: "right", direction: "rtl"}}>{faq.answer_ar}</p>
                                </div>
                            </>
                        </Accordion.Body>
                    </Accordion.Item>
                    </Accordion>
                ))}
                
            </div>
            <ArFooter />
        </>
    )
}

export default ArBooks
