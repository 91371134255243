import React, {useEffect} from 'react'
import notepad from '../../assets/images/Services/notepad.png'
import illustration from '../../assets/images/Services/Illustration.png'
import session from '../../assets/images/Services/session.png'
import airplane from '../../assets/images/Services/airplane.png'
import dorm from '../../assets/images/Services/dorm.png'
import book from '../../assets/images/Services/book.png'
import message from '../../assets/images/Services/Message.png'
import bank from '../../assets/images/Services/bank.png'
import id from '../../assets/images/Services/id.png'
import ArHeader from '../../components/ArabicComponents/ArHeader'
import ArFooter from '../../components/ArabicComponents/ArFooter'

const ServicesPage = ({ match }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <ArHeader match={match} step2 />
        <div className="container-fluid">
            <div style={{marginTop: "50px"}} className="container">
                <h4 className="text-ar2" style={{textAlign: "center", letterSpacing: "0"}}>الرئيسية <i style={{color: "#f6992d", letterSpacing: "0"}} class="fas fa-less-than"></i> الخدمات</h4>

                <div className="row services-row" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className="col-md-3 w-20"><img src={notepad} alt="" />
                        <h4 className="text-ar2" style={{letterSpacing: "0", direction: "rtl"}}>القبول والتسجيلات</h4>
                        <p className="text-ar" style={{letterSpacing: "0", direction: "rtl"}}>خطاب قبول مضمون بمنحة تصل إلى 75٪</p>
                    </div>
                    <div className="col-md-3 w-20"><img style={{maxWidth: "63px"}} src={illustration} alt="" />
                        <h4 className="text-ar2" style={{letterSpacing: "0", direction: "rtl"}}>خدمات شهادة المعادلة</h4>
                        <p className="text-ar" style={{letterSpacing: "0", direction: "rtl"}}>نقوم بالأعمال الورقية للطالب في الجامعة</p>
                    </div>
                    <div className="col-md-3 w-20"><img src={session} alt="" />
                        <h4 className="text-ar2" style={{letterSpacing: "0", direction: "rtl"}}>جلسات الإستشارات التعليمية</h4>
                        <p className="text-ar" style={{letterSpacing: "0", direction: "rtl"}}>شرح كافة معلومات وتفاصيل مجال الدراسة المفضل ومواصفاته</p>
                    </div>
                    <div className="col-md-3 w-20"><img style={{ maxWidth: "60px"}} src={airplane} alt="" />
                        <h4 className="text-ar2" style={{letterSpacing: "0", direction: "rtl"}}>الاستقبال في المطار</h4>
                        <p className="text-ar" style={{letterSpacing: "0", direction: "rtl"}}>سنكون في انتظارك في المطار</p>
                    </div>

                </div>

                <div className="row services-row" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className="col-md-3 w-20"><img src={dorm} alt="" />
                        <h4 className="text-ar2" style={{letterSpacing: "0"}}>السكن</h4>
                        <p className="text-ar" style={{letterSpacing: "0"}}>نحن نقدم إقامة مريحة نظيفة</p>
                    </div>
                    <div style={{minHeight: "175px"}} className="col-md-3 w-20"><img src={book} style={{maxWidth: "65px", marginBottom: "8%"}} alt="" />
                        <h4 className="text-ar2" style={{marginTop: "5%", letterSpacing: "0", direction: "rtl"}}> منح خاصة</h4>
                    </div>
                    <div style={{minHeight: "175px"}} className="col-md-3 w-20"><img src={message} style={{maxWidth: "65px", marginBottom: "8%"}} alt="" />
                        <h4 className="text-ar2" style={{marginTop: "5%", letterSpacing: "0", direction: "rtl"}}>خدمة المتابعة</h4>
                    </div>
                    <div style={{minHeight: "175px"}} className="col-md-3 w-20"><img src={id} style={{marginBottom: "8%"}} alt="" />
                        <h4 className="text-ar2" style={{marginTop: "5%", letterSpacing: "0", direction: "rtl"}}>تصريح بطاقة الإقامة</h4>
                    </div>

                </div>

                <div className="row services-row" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div style={{minHeight: "175px"}} className="col-md-3 w-20"><img src={bank} style={{maxWidth: "150px", marginBottom: "8%"}} alt="" />
                        <h4 className="text-ar2" style={{marginTop: "5%", letterSpacing: "0", direction: "rtl"}}>حسابات بنكية</h4>
                    </div>

                </div>
            </div>
        </div>
        <ArFooter />
        </>
    )
}

export default ServicesPage
