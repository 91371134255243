import React, { useState, useEffect } from 'react'

import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import contact from '../../assets/images/Contact/contact.gif'
import ArFooter from '../../components/ArabicComponents/ArFooter'
import ArHeader from '../../components/ArabicComponents/ArHeader'
import Loader from '../../components/Loader'
import { getContactDataAction } from '../../redux/contact'

const ArContactPage = ({ history, match }) => {

    let [first_name, setFirstname] = useState('')
    let [last_name, setLastname] = useState('')
    let [email, setEmail] = useState('')
    let [body, setBody] = useState('')

    let [message, setMessage] = useState('')
    let [success, setSuccess] = useState('')

    const dispatch = useDispatch()

    const getContactData = useSelector(state => state.getContactData)
    const { loading, data, error } = getContactData

    let form = {
        'first_name': first_name,
        'last_name': last_name,
        'email': email,
        'body': body
    }

    let createForm = async () => {
        fetch(`/api/dashboard/contact/form/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(form)
        })
    }

    let handleSubmit = () => {
        if (first_name !== '' && first_name !== '' && email !== '' && body !== '') {
            createForm()
            setSuccess("تم إرسال الإستمارة بنجاح")
            setMessage("")
        } else {
            setMessage("يرجى ملء الاستمارة قبل التقديم")
            setSuccess("")
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getContactDataAction())
    }, [dispatch])
    return (
        <>
            {loading ? (
                <Loader />
            ) : data ? (
                <>
                    <ArHeader match={match} step6 />
                    <div className="container-fluid">
                        <div style={{ marginTop: "50px" }} className="container">
                            <h4 className="text-a2" style={{ textAlign: "center", letterSpacing: "0" }}>الرئيسية <i style={{ color: "#f6992d" }} class="fas fa-less-than"></i> من نحن</h4>
                        </div>
                    </div>

                    <div className="registration-steps-band">
                        <h2 className="text-ar2" style={{ textAlign: "center", letterSpacing: "0" }}>كن على تواصل معنا</h2>
                    </div>

                    <div className="container mb-5">
                        <div className="row mt-5">
                            <div className="col-md-6">
                                <img src={contact} alt="" className="img-fluid" />
                            </div>

                            <div className="col-md-6">
                                <div className="container">
                                    <Form onSubmit={handleSubmit}>
                                        {message && <p className="alert alert-danger" style={{ letterSpacing: "0", textAlign: "right" }}>{message}</p>}
                                        {success && <p className="alert alert-success" style={{ letterSpacing: "0", textAlign: "right" }}>{success}</p>}
                                        <div class="input-group mb-3">

                                            <input required
                                                onChange={(e) => { setLastname(e.target.value) }} value={last_name?.last_name}
                                                type="text" class="form-control ar" placeholder="اسم العائلة" aria-label="Last Name" style={{ marginRight: "1rem" }} />
                                            <input required
                                                onChange={(e) => { setFirstname(e.target.value) }} value={first_name?.first_name}
                                                type="text" class="form-control ar" placeholder="الاسم الاول" aria-label="First Name" />
                                        </div>

                                        <div class="input-group mb-3">
                                            <input required
                                                onChange={(e) => { setEmail(e.target.value) }} value={email?.email}
                                                type="email" placeholder="البريد الالكتروني" class="form-control ar" aria-label="With textarea" />
                                        </div>

                                        <div class="input-group">
                                            <textarea required
                                                onChange={(e) => { setBody(e.target.value) }} value={body?.body}
                                                placeholder="الرسالة" class="form-control ar" aria-label="With textarea">
                                            </textarea>
                                        </div>

                                        <button type='submit' style={{ float: "right" }} className="btn-contact mt-3" onClick={handleSubmit}>ارسال </button>
                                    </Form>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="container-fluid contact-card-fluid1" >
                        <div className="row contact-card-row1">
                            <div className="col-md-6 contact-card-col1">
                                <div className="row contact-card-row2">
                                    <div className="col-md-6 contact-card-col2">
                                        <div className="container-fluid contact-card-fluid2">
                                            <p style={{ color: "#000000", marginTop: "20px" }} className="footer-data"><i style={{ marginRight: "5px", color: "#ed9835" }} class="fas fa-phone-alt"></i><a style={{ textDecoration: "none", color: "#000000" }} target="_blank" href="tel:+902128554253"> +90 212 855 42 53</a></p>
                                            <p style={{ color: "#000000" }} className="footer-data"><i style={{ marginRight: "5px", color: "#ed9835" }} class="fas fa-envelope"></i><a style={{ textDecoration: "none", color: "#000000" }} target="_blank" href="mailto:info@provada.education"> info@provada.education</a></p>
                                            <p style={{ color: "#000000" }} className="footer-data"><i style={{ marginRight: "5px", color: "#ed9835" }} class="fas fa-home"><a style={{ textDecoration: "none", color: "#000000" }} target="_blank" href="https://goo.gl/maps/fbCPABmanRuE5eMh7"> Babacan Premium B blok / 387</a></i></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='vision-mission-history-AR' style={{ marginBottom: '2rem' }}>
                        <div className="container">
                            <h1 style={{ letterSpacing: "0" }}>عن بروفادا</h1>

                            <h3 style={{ letterSpacing: "0" }}>الرؤية</h3>
                            <div className="vision">
                                <p style={{ textAlign: 'right' }}>{data.vision_ar}</p>
                                <img src={data.vision_image} alt="" />
                            </div>

                            <h3 style={{ letterSpacing: "0" }}>المهمة</h3>
                            <div className="mission">
                                <p style={{ textAlign: 'right' }}>{data.mission_ar}</p>

                                <img src={data.mission_image} alt="" />
                            </div>

                            <h3 style={{ letterSpacing: "0" }}>تاريخ المنظمة</h3>
                            <div className="history">
                                <p style={{ textAlign: 'right' }}>{data.history_ar}</p>

                                <img src={data.history_image} alt="" />
                            </div>
                        </div>
                    </div>
                    <ArFooter />
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default ArContactPage
