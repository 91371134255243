import React, { useState, useEffect } from 'react'

import hero from '../../assets/images/RegisterationApplication/hero.png'
import { Form, Button } from 'react-bootstrap'

const ArRegisterApplicationDegree = ({ match }) => {

    let id = match.params.id
    let uniId = match.params.uni
    let majorId = match.params.major
    if (id == "Bachelor") {
        id = "البكالوريوس"
    } else if (id == "Master's Degree") {
        id = "ماجستير"
    } else if (id == "PhD") {
        id = "الدكتوراة"
    }

    let [bachelor, setBachelor] = useState(false)
    let [master, setMaster] = useState(false)
    let [phd, setPhd] = useState(false)

    let [degrees, setDegrees] = useState([])
    let [uni, setUni] = useState([])
    let [major, setMajor] = useState([])

    let getDegrees = async () => {
        let response = await fetch(`/api/dashboard/filter/degrees/`)
        let data = await response.json()
        setDegrees(data)
    }

    let getUni = async () => {
        let response = await fetch(`/api/dashboard/universities/${uniId}/`)
        let data = await response.json()
        setUni(data)
    }

    let getMajor = async () => {
        let response = await fetch(`/api/dashboard/majors/${majorId}/`)
        let data = await response.json()
        setMajor(data)
    }

    let loadForm = (value) => {
        if (value == "البكالوريوس" || value == "Associate") {
            setBachelor(true)
            setMaster(false)
            setPhd(false)
        } else if (value == "الدكتوراة") {
            setMaster(false)
            setBachelor(false)
            setPhd(true)
        } else if (value == "ماجستير") {
            setBachelor(false)
            setMaster(true)
            setPhd(false)
        }
    }

    let handleChange = (value) => {
        if (value == 1) {
            setBachelor(true)
            setMaster(false)
            setPhd(false)
        } else if (value == 2) {
            setMaster(false)
            setBachelor(false)
            setPhd(true)
        } else if (value == 3) {
            setBachelor(false)
            setMaster(true)
            setPhd(false)
        }
    }

    useEffect(() => {
        getDegrees()
        loadForm(id)
        getUni()
        getMajor()
    }, [bachelor, master, phd])

    let [name, setName] = useState('')
    let [motherName, setMothername] = useState('')
    let [fatherName, setFathername] = useState('')
    let [phone, setPhone] = useState('')
    let [birth, setBirth] = useState('')
    let [passport, setPassport] = useState('')
    let [highSchool, setHighschool] = useState('')
    let [email, setEmail] = useState('')
    let [homeAddress, setHomeaddress] = useState('')
    let [nufus, setNufus] = useState('')
    let [gender, setGender] = useState('')
    let [personalPhoto, setPersonalphoto] = useState('')
    let [passportCopy, setPassportcopy] = useState('')
    let [bacheloreDegree, setBacheloredegree] = useState('')
    let [bacheloreDegreeTranscript, setBacheloredegreetranscript] = useState('')
    let [bacheloreDegreeTranslated, setBacheloredegreetranslated] = useState('')
    let [bacheloreDegreeTranscriptTranslated, setBacheloredegreetranscripttranslated] = useState('')

    let [mastersDegree, setMastersdegree] = useState('')
    let [mastersDegreeTranscript, setMastersdegreetranscript] = useState('')
    let [mastersDegreeTranslated, setMastersdegreetranslated] = useState('')
    let [mastersDegreeTranscriptTranslated, setMastersdegreetranscripttranslated] = useState('')

    let [highSchoolCopy, setHighschoolcopy] = useState('')
    let [highSchoolTrans, setHighschooltrans] = useState('')
    let [highSchoolDiplomaTranslated, setHighschooldiplomatranslated] = useState('')
    let [highSchoolTransTranslated, setHighschooltranstranslated] = useState('')

    let [message, setMessage] = useState(false)
    let [success, setSuccess] = useState(false)

    let form = {
        'name': name,
        'motherName': motherName,
        'fatherName': fatherName,
        'phone': phone,
        'birth': birth,
        'passport': passport,
        'highSchool': highSchool,
        'email': email,
        'homeAddress': homeAddress,
        'nufus': nufus,
        'gender': gender,
        'personalPhoto': personalPhoto,
        'passportCopy': passportCopy,
        'highSchoolCopy': highSchoolCopy,
        'highSchoolTrans': highSchoolTrans,
        'highSchoolDiplomaTranslated': highSchoolDiplomaTranslated,
        'highSchoolTransTranslated': highSchoolTransTranslated,

        'bacheloreDegree': bacheloreDegree,
        'bacheloreDegreeTranscript': bacheloreDegreeTranscript,
        'bacheloreDegreeTranslated': bacheloreDegreeTranslated,
        'bacheloreDegreeTranscriptTranslated': bacheloreDegreeTranscriptTranslated,

        'mastersDegree': mastersDegree,
        'mastersDegreeTranscript': mastersDegreeTranscript,
        'mastersDegreeTranslated': mastersDegreeTranslated,
        'mastersDegreeTranscriptTranslated': mastersDegreeTranscriptTranslated
    }

    let createForm = async (e) => {
        e.preventDefault()
        if (name == "" || motherName == "" || fatherName == "" || phone == "" || birth == "" || passport == ""
            || highSchool == "" || email == ""
        ) {
            setMessage(true)
            window.scrollTo(0, 500)
        } else {
            fetch(`/api/dashboard/register/form/`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)

            }).then(setSuccess(true)).then(window.scrollTo(0, 500))
        }

    }

    let handleSubmit = () => {
        createForm()
        // history.push('/')
    }

    return (
        <div className="container-fluid">

            <div className="container application-container-hero">
                <img style={{ alignSelf: "center" }} className="img-fluid w-25" src={hero} alt="" />
            </div>

            <div className="container application-container mt-5">
                <select
                    onChange={(e) => handleChange(e.target.value)}
                    style={{ padding: "20px", margin: "10px 0" }}
                    disabled
                >
                    <option value={id} selected disabled hidden>{id}</option>
                    {degrees.map(degree => (
                        <option value={degree.id} key={degree.id}>
                            {degree.name_ar}
                        </option>
                    ))}
                </select>
                <p style={{ color: "#f6921e", fontWeight: "900", letterSpacing: "0" }}>{uni['university']?.name_ar} - {major.name_ar}</p>
            </div>

            {bachelor ? (
                <>
                    <Form onSubmit={createForm}>
                        <div className="container application-container mt-5">

                            {message && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5 text-ar"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    استمارة التقديم</h3><p className="alert alert-danger text-ar" style={{ letterSpacing: "0px" }}>يرجى ملء الحقول أدناه باللغة الإنجليزية فقط ، ولن يتم قبول الأشكال الأخرى </p>
                                </div>
                            )}
                            {success && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><p className="alert alert-success">تم إرسال النموذج بنجاح </p>
                                </div>
                            )}
                            <div className="container">
                                <Form.Group style={{ flexDirection: "row-reverse" }} className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setName(e.target.value) }} value={name?.name} required className='form-control2 ar' placeholder="الاسم بالكامل" type="text" />
                                    <Form.Control onChange={(e) => { setMothername(e.target.value) }} value={motherName?.motherName} required className='form-control2 ar' placeholder="اسم الام" type="text" />

                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setFathername(e.target.value) }} value={fatherName?.fatherName} required className='form-control2 ar' placeholder="اسم الاب" type="text" />
                                    <Form.Control onChange={(e) => { setPhone(e.target.value) }} value={phone?.phone} required className='form-control2 ar' placeholder="رقم الهاتف" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setBirth(e.target.value) }} required className='form-control2 ar' placeholder=" تاريخ الميلاد " type="date" />
                                    <Form.Control onChange={(e) => { setPassport(e.target.value) }} required className='form-control2 ar' placeholder="رقم جواز السفر" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHighschool(e.target.value) }} value={highSchool?.highSchool} required className='form-control2 ar' placeholder="اسم مدرسة الثانوية" type="text" />
                                    <Form.Control onChange={(e) => { setEmail(e.target.value) }} value={email?.email} required className='form-control2 ar' placeholder="البريد الالكتروني" type="email" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHomeaddress(e.target.value) }} value={homeAddress?.homeAddress} required className='form-control2 ar' placeholder="عنوان المنزل بالكامل" type="text" />
                                    <Form.Control onChange={(e) => { setNufus(e.target.value) }} value={nufus?.nufus} required className='form-control2 ar' placeholder="قيد النفوس" type="text" />
                                </Form.Group>
                            </div>

                            <div id="gender" className="container">
                                <select style={{ padding: "10px", margin: "10px 0" }} onChange={(e) => { setGender(e.target.value) }} value={gender?.gender} id="gender">
                                    <option hidden disabled selected>الجنس</option>
                                    <option value="Male">ذكر</option>
                                    <option value="Female">انثى</option>
                                    {/* <label htmlFor="male">Male</label>
                            <input id="male" type="radio" name="gender" />
                            <label htmlFor="female">Female</label>
                            <input id="female" type="radio" name="gender" /> */}
                                </select>
                            </div>
                        </div>

                        <div className="container applicaiton2 application-container mt-5 mb-5 py-1">

                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} className="container mt-5 text-ar"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '0', marginRight: '10px' }} className="text-center">
                                وثائق</h3><p className="alert alert-danger text-ar">يرجى تحميل مستندات صالحة عالية الجودة ب احدى الغتين الإنجليزية أو التركية </p>
                            </div>
                            <div className="container w-75 register-application-mobile-inputs">
                                <input onChange={(e) => { setPersonalphoto(e.target.value) }} value={personalPhoto?.personalPhoto} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>صورة شخصية</label>

                            </div>

                            <div className="container w-75 register-application-mobile-inputs">
                                <input onChange={(e) => { setPassportcopy(e.target.value) }} value={passportCopy?.passportCopy} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>نسخة جواز السفر</label>

                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setHighschoolcopy(e.target.value) }} value={highSchoolCopy?.highSchoolCopy} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>شهادة الثانوية العامة (الأصلية)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setHighschooltrans(e.target.value) }} value={highSchoolTrans?.highSchoolTrans} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>بيان درجات الثانوية العامة (الأصلية)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setHighschooldiplomatranslated(e.target.value) }} value={highSchoolDiplomaTranslated?.highSchoolDiplomaTranslated} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>شهادة الثانوية العامة (مترجمة إلى التركية أو الإنجليزية)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setHighschooltranstranslated(e.target.value) }} value={highSchoolTransTranslated?.highSchoolTransTranslated} required type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>كشف درجات الثانوية العامة (مترجم إلى التركية أو الإنجليزية)</label>
                            </div>
                            <button type="submit" className="register-btn">سجل</button>
                        </div>
                    </Form>
                </>
            ) : master ? (
                <>
                    <Form onSubmit={createForm}>
                        <div className="container application-container mt-5">

                            {message && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5 text-ar"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    استمارة التقديم</h3><p className="alert alert-danger text-ar">يرجى ملء الحقول أدناه باللغة الإنجليزية فقط ، ولن يتم قبول الأشكال الأخرى </p>
                                </div>
                            )}
                            {success && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><p className="alert alert-success">تم إرسال النموذج بنجاح </p>
                                </div>
                            )}
                            <div className="container">
                                <Form.Group style={{ flexDirection: "row-reverse" }} className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setName(e.target.value) }} value={name?.name} required className='form-control2 ar' placeholder="الاسم بالكامل" type="text" />
                                    <Form.Control onChange={(e) => { setMothername(e.target.value) }} value={motherName?.motherName} required className='form-control2 ar' placeholder="اسم الام" type="text" />

                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setFathername(e.target.value) }} required className='form-control2 ar' placeholder="اسم الاب" type="text" />
                                    <Form.Control onChange={(e) => { setPhone(e.target.value) }} required className='form-control2 ar' placeholder="رقم الهاتف" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setBirth(e.target.value) }} value={birth?.birth} required className='form-control2 ar' placeholder=" تاريخ الميلاد " type="date" />
                                    <Form.Control onChange={(e) => { setPassport(e.target.value) }} value={passport?.passport} required className='form-control2 ar' placeholder="رقم جواز السفر" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHighschool(e.target.value) }} value={highSchool?.highSchool} required className='form-control2 ar' placeholder="اسم مدرسة الثانوية" type="text" />
                                    <Form.Control onChange={(e) => { setEmail(e.target.value) }} value={email?.email} required className='form-control2 ar' placeholder="البريد الالكتروني" type="email" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHomeaddress(e.target.value) }} required className='form-control2 ar' placeholder="عنوان المنزل بالكامل" type="text" />
                                    <Form.Control onChange={(e) => { setNufus(e.target.value) }} required className='form-control2 ar' placeholder="قيد النفوس" type="text" />
                                </Form.Group>
                            </div>

                            <div id="gender" className="container">
                                <select style={{ padding: "10px", margin: "10px 0" }} onChange={(e) => { setGender(e.target.value) }} value={gender?.gender} id="gender">
                                    <option hidden disabled selected>الجنس</option>
                                    <option value="Male">ذكر</option>
                                    <option value="Female">انثى</option>
                                    {/* <label htmlFor="male">Male</label>
                            <input required id="male" type="radio" name="gender" />
                            <label htmlFor="female">Female</label>
                            <input required id="female" type="radio" name="gender" /> */}
                                </select>
                            </div>
                        </div>

                        <div className="container applicaiton2 application-container mt-5 mb-5 py-1">

                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} className="container mt-5 text-ar"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '0', marginRight: '10px' }} className="text-center">
                                مستندات</h3><p className="alert alert-danger text-ar">يرجى تحميل مستندات صالحة عالية الجودة ب احدى الغتين الإنجليزية أو التركية </p>
                            </div>
                            <div className="container w-75 register-application-mobile-inputs">

                                <input required onChange={(e) => { setPersonalphoto(e.target.files[0]); console.log(e.target.files) }} value={personalPhoto?.personalPhoto} type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>صورة شخصية</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setPassportcopy(e.target.value) }} required placeholder="Phone Number" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>صورة جواز السفر</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setBacheloredegree(e.target.value) }} required placeholder="Passport Number" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>دبلوم درجة البكالوريوس (أصلي)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setBacheloredegreetranscript(e.target.value) }} required placeholder="Email Address" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>كشف درجات البكالوريوس (أصلي)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setBacheloredegreetranslated(e.target.value) }} required placeholder="Nüfüs Kayit" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>دبلوم إجازة البكالوريوس (مترجم للغة التركية أو الإنجليزية)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => { setBacheloredegreetranscripttranslated(e.target.value) }} required placeholder="Nüfüs Kayit" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>كشف درجات البكالوريوس (مترجم إلى التركية أو الإنجليزية)</label>
                            </div>

                            <button type="submit" className="register-btn">سجل</button>
                        </div>
                    </Form>
                </>
            ) : phd ? (
                <>
                    <Form onSubmit={createForm}>
                        <div className="container application-container mt-5">

                            {message && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5 text-ar"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px' }} className="text-center">
                                    استمارة التقديم</h3><p className="alert alert-danger text-ar">يرجى ملء الحقول أدناه باللغة الإنجليزية فقط ، ولن يتم قبول الأشكال الأخرى </p>
                                </div>
                            )}
                            {success && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} className="container mt-5"><p className="alert alert-success">تم إرسال النموذج بنجاح </p>
                                </div>
                            )}
                            <div className="container">
                                <Form.Group style={{ flexDirection: "row-reverse" }} className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setName(e.target.value) }} value={name?.name} required className='form-control2 ar' placeholder="الاسم بالكامل" type="text" />
                                    <Form.Control onChange={(e) => { setMothername(e.target.value) }} value={motherName?.motherName} required className='form-control2 ar' placeholder="اسم الام" type="text" />

                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setFathername(e.target.value) }} required className='form-control2 ar' placeholder="اسم الاب" type="text" />
                                    <Form.Control onChange={(e) => { setPhone(e.target.value) }} required className='form-control2 ar' placeholder="رقم الهاتف" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setBirth(e.target.value) }} value={birth?.birth} required className='form-control2 ar' placeholder=" تاريخ الميلاد " type="date" />
                                    <Form.Control onChange={(e) => { setPassport(e.target.value) }} value={passport?.passport} required className='form-control2 ar' placeholder="رقم جواز السفر" type="text" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHighschool(e.target.value) }} value={highSchool?.highSchool} required className='form-control2 ar' placeholder="اسم مدرسة الثانوية" type="text" />
                                    <Form.Control onChange={(e) => { setEmail(e.target.value) }} value={email?.email} required className='form-control2 ar' placeholder="البريد الالكتروني" type="email" />
                                </Form.Group>
                            </div>

                            <div className="container">
                                <Form.Group className="mb-3 d-flex register-application-mobile-inputs">
                                    <Form.Control onChange={(e) => { setHomeaddress(e.target.value) }} required className='form-control2 ar' placeholder="عنوان المنزل بالكامل" type="text" />
                                    <Form.Control onChange={(e) => { setNufus(e.target.value) }} required className='form-control2 ar' placeholder="قيد النفوس" type="text" />
                                </Form.Group>
                            </div>

                            <div id="gender" className="container">
                                <select style={{ padding: "10px", margin: "10px 0" }} onChange={(e) => { setGender(e.target.value) }} value={gender?.gender} id="gender">
                                    <option hidden disabled selected>الجنس</option>
                                    <option value="Male">ذكر</option>
                                    <option value="Female">انثى</option>
                                    {/* <label htmlFor="male">Male</label>
                            <input required id="male" type="radio" name="gender" />
                            <label htmlFor="female">Female</label>
                            <input required id="female" type="radio" name="gender" /> */}
                                </select>
                            </div>
                        </div>

                        <div className="container applicaiton2 application-container mt-5 mb-5 py-1">

                            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} className="container mt-5 text-ar"><h3 style={{ alignSelf: 'center', color: '#f59628', letterSpacing: '0', marginRight: '10px' }} className="text-center">
                                المستندات</h3><p className="alert alert-danger text-ar">يرجى تحميل مستندات صالحة عالية الجودة بلغتين فقط الإنجليزية أو التركية </p>
                            </div>
                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => setPersonalphoto(e.target.value)} required placeholder="Full Name" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>صورة شخصية</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => setPassportcopy(e.target.value)} required placeholder="Phone Number" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>صورة جواز السفر</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => setMastersdegree(e.target.value)} value={mastersDegree?.mastersDegree} required placeholder="Passport Number" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>شهادة الماجستير (الأصل)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => setMastersdegreetranscript(e.target.value)} value={mastersDegreeTranscript?.mastersDegreeTranscript} required placeholder="Email Address" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>بيان درجات شهادة الماجستير (وثائق اصلية)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => setMastersdegreetranslated(e.target.value)} value={mastersDegreeTranslated?.mastersDegreeTranslated} required placeholder="Nüfüs Kayit" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>شهادة الماجستير (مترجمة إلى التركية أو الإنجليزية)</label>
                            </div>

                            <div className="container w-75 register-application-mobile-inputs">

                                <input onChange={(e) => setMastersdegreetranscripttranslated(e.target.value)} value={mastersDegreeTranscriptTranslated?.mastersDegreeTranscriptTranslated} required placeholder="Nüfüs Kayit" type="file" accept='.png, .jpg, .jpeg, .pdf' />
                                <label className="ar-label" style={{ textAlign: "right" }}>كشف درجات شهادة الماجستير (مترجم إلى التركية أو الإنجليزية)</label>
                            </div>

                            <button className="register-btn">سجل</button>
                        </div>
                    </Form>
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export default ArRegisterApplicationDegree
