import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Modal } from 'react-bootstrap'
import { getUserDetails, updateUserProfile } from '../../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../../constants/userConstants'
import { logout } from '../../actions/userActions'

import axios from 'axios'

import Footer from '../../components/Footer'
import logo from '../../assets/images/logo.png'
import userImage from '../../assets/images/user.jpg'

import { useDispatch, useSelector } from 'react-redux'

const AccountPage = ({ history }) => {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logoutHandler = () => {
        dispatch(logout())
    }

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')
    const [currentPassword, setCurrentpassword] = useState('')

    const [resetMessage, setResetmessage] = useState('')

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const { loading, user, error } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile

    // let user = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM0NjI5NTkwLCJqdGkiOiJiZTI4MTgxOTFmZTM0MWMzYTJiZTMxM2M2YzljOTJjMiIsInVzZXJfaWQiOjF9.IpOFYrRYaf3emlb7ZLpBBtabU-2wW81tlxYHRz8I8A4'

    let [order, setOrders] = useState([])

    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (!user || !user.name || success || userInfo._id !== user._id) {
                dispatch({
                    type: USER_UPDATE_PROFILE_RESET
                })
                dispatch(getUserDetails('profile'))
            } else {
                setName(user.name)
                setEmail(user.email)
            }
        }
    }, [dispatch, history, userInfo, user, success])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password != confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'email': email,
                'password': password,
            }))
            setMessage('')
        }
    }

    const passwordReset = (e) => {
        e.preventDefault()
        // console.log(userInfo.token)
        // const config = {
        //     headers: {
        //         'Content-type': 'multipart/form-data',
        //         'Authorization': `Bearer ${userInfo.token}`,
        //     }
        // }

        const formData = {
            'old_password': "iy8ld2nova66",
            'password': "iy8ld2nova6",
            'password2': "iy8ld2nova6"
        }

         fetch(`/api/dashboard/users/change_password/${user.id}/`, {
            method: 'PUT',
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`,
            },
            body: formData
         })
    }

    return (
        <>
            <div style={{margin: "0"}} className="row">
                <div style={{height: "100vh"}} className="col-md-2 profile-aside container">
                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", marginTop: "10px", color: "#ffffff"}} to="/store">
                        <i style={{fontSize: "50px"}} class="fas fa-store-alt mb-2"></i>
                        <p className="text-center">STORE</p>
                    </Link>

                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", marginTop: "10px"}} to="/profile">
                        <i style={{fontSize: "50px"}} class="fas fa-book mb-2"></i>
                        <p className="text-center">BOOKS</p>
                    </Link>
                </div>
                
                <div style={{padding: "0"}} className="col-md-10">
                    <div style={{backgroundColor: "#ffffff", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)", padding:"10px 0"}} className="container-fluid">
                        <header className="container profile-header">
                            <img style={{maxHeight: "150px", maxWidth: "150px"}} src={logo} alt="" />
                            <p style={{marginBottom: "0"}}>
                                <Button variant="flush" style={{fontSize: "18px"}} onClick={logoutHandler}>
                                    <i class="fas fa-sign-out-alt"><span style={{color: "#f4911d", fontSize: "18px"}}>Sign Out</span></i>
                                </Button>

                                <Link style={{textDecoration: "none", marginLeft: "20px", fontSize: "18px"}} to="/account">
                                    <i class="fas fa-cog"></i>
                                </Link>
                                
                            </p>
                        </header>
                    </div>

                    <div style={{marginTop: "100px"}} className="container">
                        <div  className="row">
                            <div className="profile-image col-md-6">
                                <img style={{maxWidth: "25%"}} src={userImage} alt="" />
                                <p>{user?.name?.toUpperCase()} <br />
                                student
                                </p>
                            </div>
                        </div>
                        
                    </div>

                    <div className="container mt-5">
                        <h3 style={{borderBottom: "1px solid black", color: "#f4911d", fontWeight: "bold"}}>Account Info</h3>
                        
                        <div className="container mt-5">
                            <div style={{border: "1px solid black"}} className="row">
                                <Form className="mt-5" onSubmit={submitHandler}>

                                    <Form.Group className="row" style={{display: "flex", justifyContent: "space-evenly", alignItems: "center"}} controlId='name'>
                                        <Form.Label className="col-md-4">Name:</Form.Label>
                                        <Form.Control
                                            style={{border: "1px solid black", borderRadius: "5px"}}
                                            className="w-50 col-md-10"
                                            required
                                            type='name'
                                            placeholder='Enter name'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="row" style={{display: "flex", justifyContent: "space-evenly", alignItems: "center"}} controlId='email'>
                                        <Form.Label className="col-md-4">Email Address:</Form.Label>
                                        <p className="w-50 col-md-10 mt-3 py-2" style={{border: "1px solid black", borderRadius: "5px"}}>{email}</p>
                                        {/* <Form.Control
                                        style={{border: "1px solid black", borderRadius: "5px"}}
                                        className="w-50 col-md-10 mt-3"
                                            required
                                            type='email'
                                            placeholder='Enter Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        >
                                        </Form.Control> */}
                                    </Form.Group>

                                    <div className="container">
                                        <div className="row mx-3 mb-3">
                                                
                                            <div className="col-md-6">
                                                {/* <Button variant="flush" 
                                                // className="w-50" 
                                                onClick={handleShow}>
                                                    Reset Password
                                                </Button> */}
                                            </div>

                                            <div className="col-md-6 d-flex justify-content-end">
                                                <Button className='mt-2' type='submit' style={{backgroundColor: "#f4911d"}}>
                                                    Update
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </Form>

                                

                                <Modal
                                    show={show}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                >
                                    <Modal.Header closeButton>
                                    <Modal.Title>Reset Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form onSubmit={passwordReset}>

                                        <Form.Group controlId='password'>
                                                <Form.Label>Current Password</Form.Label>
                                                <Form.Control
                                                    type='password'
                                                    placeholder='Enter Current Password'
                                                    value={currentPassword}
                                                    onChange={(e) => setCurrentpassword(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Form.Group>


                                            <Form.Group controlId='password'>
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    type='password'
                                                    placeholder='Enter Password'
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId='passwordConfirm'>
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control

                                                    type='password'
                                                    placeholder='Confirm Password'
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Form.Group>
                                            <Button className='mt-2' type='submit' style={{backgroundColor: "#f4911d", float: "right"}}>
                                                Update
                                            </Button>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>

                    </div>
                    
                </div>
            </div>
        
            <Footer />
        </>
    )
}

export default AccountPage
