import React, {useEffect} from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay} from 'swiper'

import 'swiper/swiper-bundle.css'

import altinbas from '../assets/images/Universities/Altinbas.png'
import uskudar from '../assets/images/Universities/uskudar2.png'
import istinye from '../assets/images/Universities/Istinye.png'
import medipol from '../assets/images/Universities/Medipol.png'
import { Link } from 'react-router-dom';

const OwlCarousel = () => { 
    let number = 4
    var w = window.innerWidth;
    
    if (w <= 1000) {
        number = 2
    }
    SwiperCore.use([Autoplay])

    useEffect(() => {

    }, [w, number])
    
    return (

        <div style={{borderTop: "1px solid #f2f2f2", marginTop: "50px"}}>
            <div className="container container-owl-carousel-just" style={{margin: "50px auto"}}>
                <h2 style={{textAlign: "center", marginBottom: "20px"}}>Universities</h2>
                <Swiper
                breakpoints={{
                    // when window width is >= 640px
                    280: {
                        width: 280,
                        slidesPerView: 1,
                    },
                    // when window width is >= 768px
                    768: {
                        width: 768,
                        slidesPerView: 2,
                    },
                    // when window width is >= 992px
                    1250: {
                        width: 1250,
                        slidesPerView: 4,
                    },
                }}
                className="owl-carousel-background"
                spaceBetween={50}
                slidesPerView={number}
                loop={true}
                autoplay={{delay: 2500,
                disableOnInteraction: false}}
                >
                    <SwiperSlide style={{border: "1px solid #f79c33", borderTopLeftRadius: "75px", borderTopRightRadius: "75px", borderBottomRightRadius: "75px", padding: "38px"}}><Link to="/universities/1"><img className="slider-image" src={altinbas} alt="" /></Link></SwiperSlide>
                    <SwiperSlide style={{border: "1px solid #f79c33", borderTopLeftRadius: "75px", borderTopRightRadius: "75px", borderBottomRightRadius: "75px", padding: "38px"}}><Link to="/universities/3"><img className="slider-image" src={uskudar} alt="" /></Link></SwiperSlide>
                    <SwiperSlide style={{border: "1px solid #f79c33", borderTopLeftRadius: "75px", borderTopRightRadius: "75px", borderBottomRightRadius: "75px", padding: "38px"}}><Link to="/universities/2"><img className="slider-image" src={istinye} alt="" /></Link></SwiperSlide>
                    <SwiperSlide style={{border: "1px solid #f79c33", borderTopLeftRadius: "75px", borderTopRightRadius: "75px", borderBottomRightRadius: "75px", padding: "38px"}}><Link to="/universities/4"><img className="slider-image" src={medipol} alt="" /></Link></SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default OwlCarousel
