import React, { useEffect } from "react"
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleHideAction } from "../redux/ModalRedux";
import { getSuccessSingleDataAction } from "../redux/successStories";

const ArBlogModal = ({ id }) => {

    const dispatch = useDispatch();

    const handleChange = useSelector(state => state.handleChange);
    const { show } = handleChange;

    const handleClose = () => {
        dispatch(handleHideAction())
    }

    const getSuccessSingleData = useSelector(state => state.getSuccessSingleData);
    const { loading, data, error } = getSuccessSingleData;

    useEffect(() => {
        dispatch(getSuccessSingleDataAction(id))
    }, [dispatch])

    return (
        <>
            {data && (
                <Modal
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="Ar-modal-header" style={{ flexDirection: "row-reverse" }} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter" style={{ letterSpacing: "0", textAlign: "right" }} dir="rtl">
                            {data.blog_title_ar}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ letterSpacing: "0", textAlign: "right" }} dir="rtl">
                            {data.blog_content_ar}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="blog-btn" onClick={() => { handleClose() }}>إغلاق</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default ArBlogModal