import React, {useEffect} from 'react'

import pdf2 from '../../assets/Pdf/ProvadaRegisterationStepsAr.pdf'

import icon7 from '../../assets/images/Untitled-1-09.svg'
import icon9 from '../../assets/images/Untitled-1-07.svg'
import icon1 from '../../assets/images/Untitled-1-01.svg'
import icon3 from '../../assets/images/Untitled-1-03.svg'
import icon8 from '../../assets/images/Untitled-1-08.svg'
import icon10 from '../../assets/images/Untitled-1-10.svg'
import icon2 from '../../assets/images/Untitled-1-02.svg'
import icon4 from '../../assets/images/Untitled-1-04.svg'
import icon11 from '../../assets/images/Untitled-1-11.png'
import icon12 from '../../assets/images/Untitled-1-12.svg'
import icon5 from '../../assets/images/Untitled-1-05.svg'
import icon6 from '../../assets/images/Untitled-1-06.svg'

import hero from '../../assets/images/RegistrationSteps/hero.gif'
import firstStep from '../../assets/images/RegistrationSteps/first-step.png'
import secondStep from '../../assets/images/RegistrationSteps/second-step.png'
import thirdStep from '../../assets/images/RegistrationSteps/third-step.png'
import fourthStep from '../../assets/images/RegistrationSteps/fourth-step.png'
import fifthtStep from '../../assets/images/RegistrationSteps/fifth-step.png'
import sixthStep from '../../assets/images/RegistrationSteps/sixth-step.png'
import firstColumn from '../../assets/images/RegistrationSteps/first-column-ar.png'
import secondColumn from '../../assets/images/RegistrationSteps/second-column-ar.png'
import thirdColumn from '../../assets/images/RegistrationSteps/third-column-ar.png'
import ArHeader from '../../components/ArabicComponents/ArHeader'
import ArFooter from '../../components/ArabicComponents/ArFooter'

const RegistrationsStepsPage = ({ match }) => {

    let getPdf = () => {
        // let response = await fetch('/images/provada_regestrution_form_pdf_1.pdf'
        // )
        //   .then((response) => response.blob())
        //   .then((blob) => {
        //     // Create blob link to download
        //     const url = window.URL.createObjectURL(
        //       new Blob([blob]),
        //     );
        //     console.log(url)
            const link = document.getElementById('download2');
            link.href = 'https://provada-test.herokuapp.com/images/provada_regestrution_form_pdf_1.pdf';
        
            // Append to html link element page
            document.body.appendChild(link);
        
            // Start download
            link.click();
        
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        //   }
        // )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <ArHeader match={match} step5 />
            <div className="container-fluid">
                <div style={{marginTop: "50px"}} className="container">
                    <h4 className="text-ar2" style={{textAlign: "center", letterSpacing: "0"}}>الرئيسية <i style={{color: "#f6992d"}} class="fas fa-less-than"></i> التسجيل</h4>
                </div>
            </div>
            <div className="registration-steps-band">
                    <h2 className="text-ar2" style={{textAlign: "center", letterSpacing: "0"}}>خطوات التسجيل</h2>
            </div>

            <div style={{marginTop: "50px"}} className="container-fluid">
                <div className="container" style={{display: 'flex', flexDirection: 'column'}}>
                    <h4 className="text-ar" style={{textAlign: "center", letterSpacing: "0"}}>اختر برنامجك المفضل والجامعة الخاصة بك من خلال النظام ثم قم بتحميل مستنداتك إلى النظام
                    </h4>

                    <img style={{alignSelf: 'center'}} className="img-fluid" width="40%" height="40%" src={hero} alt="" />
                </div>
            </div>

            <div style={{marginTop: "100px",  marginBottom: "100px"}} className="container-fluid world-map">
                <div className="container steps-container">
                    {/* <img className="img-fluid" src={firstStep} alt="" /> */}

                    <div className="container columns-container">
                        <img className="img-fluid" src={firstColumn} alt="" />
                        <img className="img-fluid" src={secondColumn} alt="" />
                        <img className="img-fluid" src={thirdColumn} alt="" />
                    </div>

                    {/* <img style={{marginTop: "50px"}} className="img-fluid" src={secondStep} alt="" /> */}
                    {/* <img className="img-fluid" src={thirdStep} alt="" />
                    <img className="img-fluid" src={fourthStep} alt="" />
                    <img className="img-fluid" src={fifthtStep} alt="" />
                    <img className="img-fluid" src={sixthStep} alt="" /> */}

            <div>
            <div className="container col-12 mt-5">
                <div className='w-100 container-d-ar'>
                    <div className='context-cont ar-margin'>
                        <h4 className="ar">اختر البرنامج الذي ترغب فيه والجامعة
                        من خلال النظام ثم قم بتحميل المستندات
                        الخاصة بك على النظام
                        </h4>
                    </div>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon9} alt="" />
                            <img src={icon7} alt="" />
                        </div>
                        <p className="ar">املأ الطلب</p>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='mt-4 container-d-ar'>
                    <div className='context-cont ar-margin'>
                        <h4 className="ar">سيقوم فريقنا بتسجيل طلبك إلى الجامعة المطلوبة
                        مباشرة للحصول على رسالة القبول الأولي و مفادها
                        أن الجامعة وافقت على قبول الطالب للدراسة في صفوفها
                        </h4>
                    </div>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon1} alt="" />
                            <img src={icon3} alt="" />
                        </div>
                        <p className="ar">رسالة القبول الأولي</p>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='mt-4 container-d-ar'>
                    <div className='context-cont ar-margin'>
                        <h4 className="ar">بعد حصول الطالب على رسالة القبول الأولي يتوجب عليه دفع
                        قيمة الديبوزت الى حساب الجامعة البنكي و أرسال صورة عن
                        ايصال الدفع لفريق بروفادا و ذلك لحجز مقعده علما أن قيمة
                        الديبوزت مقطوعة من الأقساط
                        </h4>
                    </div>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon8} alt="" />
                            <img src={icon10} alt="" />
                        </div>
                        <p className="ar">دفع قيمة الديبوزت</p>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='mt-4 container-d-ar'>
                    <div className='context-cont ar-margin'>
                        <h4 className="ar">يقوم فريق بروفادا بالتنسيق مع إدارة الجامعة بعد التأكد من وصول الدفعة
                        الى حساب الجامعة باستخراج القبول النهائي و مفاده أن الطالب أصبح يمتلك
                        مقعدا بإسمه و لا يحق لأي شخص الاستحواذ عليه حتى بدأ الفصل الدراس
                        </h4>
                    </div>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon2} alt="" />
                            <img src={icon4} alt="" />
                        </div>
                        <p className="ar">رسالة القبول النهائي</p>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='mt-4 container-d-ar'>
                    <div className='context-cont ar-margin'>
                        <h4 className="ar">باستخدام رسالتي القبول الأولي و النهائي بالإضافة إلى رسالة الدعوة من
                        الجامعة, يستطيع الطالب تقديم طلب الفيزا الدراسية و الحصول عليها
                        عبر السفارة التركية في بلده
                        </h4>
                    </div>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon11} alt="" />
                            <img src={icon12} alt="" />
                        </div>
                        <p className="ar">الفيزا</p>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='mt-4 container-d-ar'>
                    <div className='context-cont ar-margin'>
                        <h4 className="ar">على الطالب الحضور بشكل شخصي في الجامعة لتسليم الأوراق و الشهادات
                        باليد و تثبيت القيد و الحصول على بطاقة الطالب و رقم القيد. علما أنه يستطيع
                        طلب مرافق جامعي من مكتب بروفادا للمساعدة في إجراءات تثبيت
                        </h4>
                    </div>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon5} alt="" />
                            <img src={icon6} alt="" />
                        </div>
                        <p className="ar">تثبيت القيد و <br /> إنهاء التسجيل</p>
                    </div>
                </div>
            </div>
        </div>

                    {/* <a target="_blank" id="download2" onClick={getPdf} href="https://provada-test.herokuapp.com/images/provada_regestrution_form_pdf_1.pdf" style={{background: "#f6992d", color: "#ffffff", padding: "15px", borderRadius: "10px", textDecoration: "none"}}>حمل الملف</a> */}
                    <a href={pdf2} target="_blank" id="download2" style={{background: "#f6992d", color: "#ffffff", padding: "15px", borderRadius: "10px", textDecoration: "none"}}>حمل الملف</a>
                </div>
                
            </div>
            <ArFooter />
        </>
    )
}

export default RegistrationsStepsPage
