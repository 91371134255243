import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Modal } from 'react-bootstrap'
import { getUserDetails, updateUserProfile } from '../../../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../../../constants/userConstants'
import { logout } from '../../../actions/userActions'

import Footer from '../../../components/Footer'
import logo from '../../../assets/images/logo.png'
import userImage from '../../../assets/images/user.jpg'

import { useDispatch, useSelector } from 'react-redux'

const ArAccountPage = ({ history }) => {


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logoutHandler = () => {
        dispatch(logout())
    }

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const { loading, user, error } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile

    // let user = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjM0NjI5NTkwLCJqdGkiOiJiZTI4MTgxOTFmZTM0MWMzYTJiZTMxM2M2YzljOTJjMiIsInVzZXJfaWQiOjF9.IpOFYrRYaf3emlb7ZLpBBtabU-2wW81tlxYHRz8I8A4'

    let [order, setOrders] = useState([])

    useEffect(() => {
        if (!userInfo) {
            history.push('/ar/login')
        } else {
            if (!user || !user.name || success || userInfo._id !== user._id) {
                dispatch({
                    type: USER_UPDATE_PROFILE_RESET
                })
                dispatch(getUserDetails('profile'))
            } else {
                setName(user.name)
                setEmail(user.email)
            }
        }
    }, [dispatch, history, userInfo, user, success])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password != confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'email': email,
                'password': password,
            }))
            setMessage('')
        }
    }


    return (
        <>
            <div style={{margin: "0"}} className="row">

                
                <div style={{padding: "0"}} className="col-md-10">
                    <div style={{backgroundColor: "#ffffff", boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)", padding:"10px 0"}} className="container-fluid">
                        <header className="container profile-header">
                                
                                <p style={{marginBottom: "0"}}>

                                <Button variant="flush" style={{fontSize: "25px", fontSize: "18px"}} onClick={logoutHandler}>
                                        <i class="fas fa-sign-out-alt"><span style={{color: "#f4911d", fontSize: "18px"}}> تسجيل الخروج</span></i>
                                    </Button>

                                    <Link style={{textDecoration: "none", marginLeft: "20px", fontSize: "18px"}} to="/ar/account">
                                        <i class="fas fa-cog"></i>
                                    </Link>
                                    
                                </p>
                                <img style={{maxHeight: "150px", maxWidth: "150px"}} src={logo} alt="" />
                            </header>
                    </div>

                    <div style={{marginTop: "100px"}} className="container">
                        <div style={{flexDirection: "row-reverse"}} className="row">
                            <div className="profile-image col-md-6">
                                
                                <p>{user?.name?.toUpperCase()} <br />
                                طالب
                                </p>
                                <img style={{maxWidth: "25%", marginLeft: "20px"}} src={userImage} alt="" />
                            </div>
                        </div>
                        
                    </div>

                    <div className="container mt-5">
                        <h3 style={{borderBottom: "1px solid black", color: "#f4911d", fontWeight: "bold", letterSpacing: "0", textAlign: "right"}}>معلومات الحساب</h3>
                        
                        <div className="container mt-5">
                            <div style={{border: "1px solid black"}} className="row">
                                <Form className="mt-5" onSubmit={submitHandler}>

                                    <Form.Group className="row" style={{display: "flex", justifyContent: "space-evenly", alignItems: "center", flexDirection: "row-reverse"}} controlId='name'>
                                        <Form.Label className="col-md-4 text-end">الاسم</Form.Label>
                                        <Form.Control
                                            style={{border: "1px solid black", borderRadius: "5px"}}
                                            className="w-50 col-md-10 ar-input"
                                            required
                                            type='name'
                                            placeholder='Enter name'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>

                                    <Form.Group className="row" style={{display: "flex", justifyContent: "space-evenly", alignItems: "center", flexDirection: "row-reverse"}} controlId='email'>
                                        <Form.Label className="col-md-4 text-end">البريد الالكتروني</Form.Label>
                                        <p style={{border: "1px solid black", borderRadius: "5px"}} className="w-50 col-md-10 mt-3 ar-input py-2">{email}</p>
                                        {/* <Form.Control
                                        style={{border: "1px solid black", borderRadius: "5px"}}
                                        className="w-50 col-md-10 mt-3 ar-input"
                                            required
                                            type='email'
                                            placeholder='Enter Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        >
                                        </Form.Control> */}
                                    </Form.Group>

                                    <div className="container">
                                        <div style={{flexDirection: "row-reverse"}} className="row mx-3 mb-3">
                                                
                                            <div className="col-md-6 d-flex justify-content-end">
                                                {/* <Button style={{textAlign: "end"}} variant="flush" 
                                                 
                                                onClick={handleShow}>
                                                    إعادة ضبط كلمة المرور
                                                </Button> */}
                                            </div>

                                            <div className="col-md-6">
                                                <Button className='mt-2' type='submit' style={{backgroundColor: "#f4911d"}}>
                                                    تحديث
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </Form>

                                

                                <Modal
                                    show={show}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                    className="ar-modal"
                                >
                                    <Modal.Header style={{flexDirection: "row-reverse"}} closeButton>
                                    <Modal.Title style={{letterSpacing: 0}}>اعادة ضبط كلمة المرور</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form onSubmit={submitHandler}>
                                            <Form.Group className="d-flex flex-column ar-input" controlId='password'>
                                                <Form.Label style={{letterSpacing: "0", textAlign: "right"}}>كلمة المرور</Form.Label>
                                                <Form.Control
                                                    type='password'
                                                    placeholder='كلمة المرور'
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group className="d-flex flex-column ar-input" controlId='passwordConfirm'>
                                                <Form.Label style={{letterSpacing: "0", textAlign: "right"}}>تأكيد كلمة المرور</Form.Label>
                                                <Form.Control

                                                    type='password'
                                                    placeholder='تأكيد كلمة المرور'
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                >
                                                </Form.Control>
                                            </Form.Group>
                                            <Button className='mt-2' type='submit' style={{backgroundColor: "#f4911d", float: "right"}}>
                                                تحديث
                                            </Button>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        اغلاق
                                    </Button>
                                    
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>

                    </div>

                    
                    
                </div>

                <div className="col-md-2 profile-aside container">
                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", marginTop: "10px", color: "#ffffff"}} to="/ar/store">
                        <i style={{fontSize: "50px"}} class="fas fa-store-alt mb-2"></i>
                        <p className="text-center">المكتبة</p>
                    </Link>

                    <Link style={{textDecoration: "none", display: "flex", flexDirection: "column", marginTop: "10px"}} to="/ar/profile">
                        <i style={{fontSize: "50px"}} class="fas fa-book mb-2"></i>
                        <p className="text-center">مكتبتي</p>
                    </Link>
                </div>
            </div>
        
            <Footer />
        </>
    )
}

export default ArAccountPage
