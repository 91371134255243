import React from 'react'
import { Link } from 'react-router-dom'
import cap from '../assets/images/cap.png'
import logo from '../assets/images/footerLogo.png'

const Footer = () => {

    var currentYear = new Date().getFullYear();

    return (
        <>
            <a href="https://api.whatsapp.com/send?phone=905394479920" className="wp_btn"><i className="fab fa-whatsapp"></i></a>
            <div className="footer" style={{ backgroundColor: "#2d3339" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="w-50" style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "#f6921e", alignItems: "center", padding: "10px 5px 5px" }}>
                                <h4 style={{ color: "#ffffff", marginBottom: "0" }}>About Us </h4>
                                <i style={{ fontSize: "26px" }} class="fas fa-graduation-cap"></i>
                            </div>

                            <img className="footer-logo" style={{ margin: "30px 0" }} src={logo} alt="" />

                            <p className="footer-data"><i style={{ marginRight: "5px" }} class="fas fa-phone-alt"></i><a style={{ textDecoration: "none" }} target="_blank" href="tel:+902128554253"> +90 212 855 42 53</a></p>
                            <p className="footer-data"><i style={{ marginRight: "5px" }} class="fas fa-envelope"></i><a style={{ textDecoration: "none" }} target="_blank" href="mailto:info@provada.education">info@provada.education</a></p>
                            <p className="footer-data"><i style={{ marginRight: "5px" }} class="fas fa-home"><a style={{ textDecoration: "none" }} target="_blank" href="https://goo.gl/maps/fbCPABmanRuE5eMh7"> Babacan Premium B blok / 387</a></i></p>

                            <div className="footer-links">
                                <a style={{ marginLeft: "0" }} target="_blank" href="https://twitter.com/ProvadaE"><i class="fab fa-twitter" style={{ textAlign: 'center', lineHeight: '18px' }}></i></a>
                                <a target="_blank" href="https://www.facebook.com/provada.edu/"><i class="fab fa-facebook-f" style={{ textAlign: 'center', lineHeight: '18px' }}></i></a>
                                <a target="_blank" href="https://www.instagram.com/provada_education"><i class="fab fa-instagram" style={{ textAlign: 'center', lineHeight: '18px' }}></i></a>
                                <a target="_blank" href="https://www.youtube.com/channel/UCVQUvTaMEBNcIaGP4lh5e9A/featured" ><i class="fab fa-youtube" style={{ textAlign: 'center', lineHeight: '18px' }}></i></a>
                            </div>
                        </div>

                        <div className="col-md-4">
                            {/* <div className="" style={{display: "flex", justifyContent: "space-between", backgroundColor: "#7a7a7a", alignItems: "center", padding: "10px 5px 5px", width: "50%"}}>
                                <h4 style={{color: "#ffffff", marginBottom: "0", textAlign: "start"}}>Site Map</h4>
                                <i style={{fontSize: "26px"}} class="far fa-clock"></i>
                            </div> */}

                            <div style={{ marginTop: "70px" }}>
                                <p><Link className="footer-nav-item" style={{ textDecoration: "none" }} to="/">HOME</Link></p>
                                <p><Link className="footer-nav-item" style={{ textDecoration: "none" }} to="/services">SERVICES</Link></p>
                                <p><Link className="footer-nav-item" style={{ textDecoration: "none" }} to="/universities">UNIVERSITIES</Link></p>
                                <p><Link className="footer-nav-item" style={{ textDecoration: "none" }} to="/success-stories">SUCCESS STORIES</Link></p>
                                <p><Link className="footer-nav-item" style={{ textDecoration: "none" }} to="/registration">REGISTRATION</Link></p>
                                <p><Link className="footer-nav-item" style={{ textDecoration: "none" }} to="/contact">CONTACT</Link></p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12043.187355435331!2d28.681547!3d41.0078197!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3c09aa1320cb2047!2sBabacan%20Premium!5e0!3m2!1sen!2str!4v1643892813230!5m2!1sen!2str" style={{ border: "0" }} allowFullScreen="" loading="lazy"></iframe>
                            {/* <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.4476451589726!2d28.62352011571896!3d41.01546142693103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55f2afaf824ef%3A0xd8d61027ca651344!2sProvada%20Professional%20Providers!5e0!3m2!1sen!2str!4v1632993028064!5m2!1sen!2str" style={{border: "0"}} allowFullScreen="" loading="lazy"></iframe> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid second-footer-container" style={{ backgroundColor: "#23272d" }}>
                <div className="container second-footer">
                    <p><a style={{ textDecoration: "none", color: "#eb8c1e" }} target="_blank" href="https://osgpro.com/">OSG Pro</a> &#169; {currentYear}. All Rights Reserved.</p>
                    {/* <div className="footer-nav">
                        <p><Link className="footer-nav-item" style={{textDecoration: "none"}} to="/">HOME</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none"}} to="/services">SERVICES</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none"}} to="/universities">UNIVERSITIES</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none"}} to="/success-stories">SUCCESS STORIES</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none"}} to="/registration">REGISTRATION</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none"}} to="/contact">CONTACT</Link></p>
                    </div> */}
                </div>
            </div>

        </>
    )
}

export default Footer
