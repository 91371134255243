import React from "react";
import { Link } from 'react-router-dom'
import smallFile from '../assets/images/UniversitySearch/big.png'
import { Card, Button, CardGroup } from 'react-bootstrap'

class Filter extends React.Component {

    state = {
        universities: [],
        languages: [],
        majors: [],
        cities: [],
        degrees: [],
    
        filterLangugageId: "",
        filterMajorId: "",
        filterCityId: "",
        filterDegreeId: "",
        filterUniversityId: "",
        filterPriceId: "",
        clear: false,
    };

    getData = async (url, key) => {
        let response = await fetch(url);
        let data = await response.json();
        this.setState({ [key]: data });
        console.log({ [key]: data })
    };

    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    componentDidMount() {
        this.getData("/api/dashboard/universities/", "universities");
        this.getData("/api/dashboard/languages/", "languages");
        this.getData("/api/dashboard/majors/", "majors");
        this.getData("/api/dashboard/degrees/", "degrees");
    }

    render() {
    
        const { 
            universities, filterUniversityId,
            languages, filterLangugageId,
            majors, filterMajorId,
            degrees, filterDegreeId,
            filterPriceId
        } = this.state;

        let filter = filterUniversityId || filterLangugageId || filterMajorId || filterDegreeId

        let clear = false

        let filteredUniversities = universities;

        let filteredMajors = majors;

        if (filterLangugageId) {
            filteredMajors = filteredMajors.filter(major => {
                window.scrollTo(0, 0)
                return major.languages == filterLangugageId;
            });
        }
        if (filterMajorId) {
            filteredMajors = filteredMajors.filter(major => {
                window.scrollTo(0, 0)
                return major.name == filterMajorId;
            });
        }

        if (filterUniversityId) {
            filteredMajors = filteredMajors.filter(major => {
                window.scrollTo(0, 0)
                // return major.university.includes(Number(filterUniversityId))
                return major.university.id == filterUniversityId;
            })
        }

        if (filterPriceId) {
            filteredMajors = filteredMajors.filter(major => {
            })
        }

        if (filterDegreeId) {
            filteredMajors = filteredMajors.filter(major => {
                window.scrollTo(0, 0)
                return major.degree.id == filterDegreeId;
            });
        }

        if(clear) {
            filteredMajors = filteredMajors.filter(major => {
                return major
            })
        }

        const seen = new Set()

        const filteredArr = majors.filter(el => {
            const duplicate = seen.has(el.name);
            seen.add(el.name);
            return !duplicate;
        });

        return (
            <>
            <div className="container uni-container">
            <div className="filter">

                <div className="row filter-row">
                    <h3 style={{color: "#f69321", textAlign: "center"}}>Filter</h3>
                    <img src={smallFile} alt="" style={{marginBottom: "20px"}} />
                    <select
                        onChange={this.handleChange}
                        value={filterLangugageId}
                        id="filterLangugageId"
                        className="filter_select language-filter"
                    >
                        
                        <option selected value="">Select Language</option>
                        {languages.map(language => (
                            <option value={language.id} key={language.id}>
                                {language.name}
                            </option>
                        ))}
                    </select>

                    {/* <img src={smallFile} alt="" style={{marginBottom: "20px"}} /> */}
                    <select
                        onChange={this.handleChange}
                        value={filterUniversityId}
                        id="filterUniversityId"
                        className="filter_select university-filter"
                    >
                        <option selected value="">Select University</option>
                        {universities.map(university => (
                            <option value={university.id} key={university.id}>
                                {university.name}
                            </option>
                        ))}
                    </select>

                        {/* <img src={smallFile} alt="" style={{marginBottom: "20px"}} /> */}
                        <select
                            onChange={this.handleChange}
                            value={filterMajorId}
                            id="filterMajorId"
                            className="filter_select major-filter"
                            >
                            <option selected value="">Select Major</option>
                            {filteredArr.map(major => (
                                <option value={major.name} key={major.id}>
                                    {major.name}
                                </option>
                            ))}
                        </select>

                        {/* <img src={smallFile} alt="" style={{marginBottom: "20px"}} /> */}
                        <select
                            onChange={this.handleChange}
                            value={filterDegreeId}
                            id="filterDegreeId"
                            className="filter_select degree-filter"
                        >
                            <option selected value="">Select Degree</option>
                            {degrees.map(degree => (
                                <option value={degree.id} key={degree.id}>
                                    {degree.name}
                                </option>
                            ))}
                        </select>
                </div>
            </div>

            {!filter && (
                <div className="container mb-5">
                    <div className="container">
                        <div style={{justifyContent: "center"}} className="row uni-row">
                            {filteredUniversities.map(university => (
                                <div key={university.id} className="col-md-3">
                                    <div className="container d-flex justify-content-center align-items-center"><img className="filter-logo-img" src={university.logo} alt="" /></div>
                                    {/* <p style={{textAlign: "center", fontSize: "18px"}}>{university.name}</p> */}
                                    <p className="w-75">{
                                    university.sub_info.substring(0, 50)}...</p>
                                    <Link style={{textDecoration: "none", color: "#ffffff"}} to={`universities/${university.id}`}><button>Learn More</button></Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
                { filter &&
                <div style={{marginTop: "35px"}} className="container uni-container">
                        <div className="container">
                            <div className="row uni-row">
                                {filteredMajors.length <= 0 && (
                                    <h5>We could not find any results that matches the search</h5>
                                )}
                                {filteredMajors.map(major => (
                                    <div key={major.id} className="row result-row">
                                        <h4 key={major.name}>{major.name} - {major.degree.name}</h4>
                                            <div style={{borderTop: "1px solid #f69422", margin: "20px 0"}} className="row" key={major.id}>
                                                <img src={major?.university?.logo} style={{maxWidth: "150px", maxHeight: "100px", objectFit: "contain"}} alt="" />
                                                <div style={{color: "#f69422"}} className="col-md-2">Language <br /><p style={{color: "#000000", marginTop: "15px"}}>
                                                    {major.languages == 1 ? "English & Turkish" : major.languages == 2 ? "English" : major.languages == 3 ? "Turkish" : "Arabic"}
                                                    </p></div>
                                                <div style={{color: "#f69422"}} className="col-md-2">cost <br /><p style={{color: "#000000", marginTop: "15px"}}><del>${major.pre_sale_fees}</del></p></div>
                                                <div style={{color: "#f69422"}} className="col-md-2">sale <br /><p style={{color: "#000000", marginTop: "15px"}}>${major.post_sale_fees}</p></div>
                                                <div style={{color: "#f69422"}} className="col-md-2">Years <br /><p style={{color: "#000000", marginTop: "15px"}}>{major.study_years}</p></div>
                                                <div style={{color: "#f69422", marginTop: "30px"}} className="col-md-2"><Link to={`/register/${major.degree.name}/${major.university.id}/${major.id}`} style={{color: "#000000", textDecoration: "none", backgroundColor: "#f79b31", padding: "10px", color: "#ffffff"}}>Apply</Link></div>  
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                }
            </div>
            </>
        );
    }
}

export default Filter; 
