import React, { useState, useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import bg from '../../assets/images/bg.png'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'

import { getOrderDetails, payOrder, deliverOrder } from '../../actions/orderActions'
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from '../../constants/orderConstants'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Login from '../Login'

import useGeoLocation from "react-ipgeolocation";

function OrderScreen({ match, history, location }) {

    const orderId = match.params.id
    const dispatch = useDispatch()

    const location2 = useGeoLocation();

    const [discountCode, setDiscountcode] = useState(false)
    const [sdkReady, setSdkReady] = useState(false)

    const orderDetails = useSelector(state => state.orderDetails)
    const { order, error, loading } = orderDetails

    const orderPay = useSelector(state => state.orderPay)
    const { loading: loadingPay, success: successPay } = orderPay

    const orderDeliver = useSelector(state => state.orderDeliver)
    const { loading: loadingDeliver, success: successDeliver } = orderDeliver

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [codes, setCodes] = useState([])

    const [totalPrice, setTotalprice] = useState(0)
    // if (order) {
    //     setTotalprice(order.totalPrice)
    // }

    // if(!loading && !error) {
    //     order.itemsPrice = order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
    // }



    // ARZo4IaCX0n2D6znqaqkE6HK97OrJJJrwHddYBaIVpwpRGAKdAIOvmzBYzDpxNCOVkrYIHnVY4CgiIDG
    const addPayPalScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://www.paypal.com/sdk/js?client-id=ARZo4IaCX0n2D6znqaqkE6HK97OrJJJrwHddYBaIVpwpRGAKdAIOvmzBYzDpxNCOVkrYIHnVY4CgiIDG'
        script.async = true
        script.onload = () => {
            setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    let getCodes = async () => {
        let response = await fetch ('/api/dashboard/codes')
        let data = await response.json()
        setCodes(data)
        console.log(data)
    }
    
    // if (order) {
    //  setTotalprice(order.totalPrice)
    // }
    // else {
    //  setTotalprice()
    // }
    
    const checkCode = async (value) => {
        console.log("passing")
        if (codes.length !== 0) {
            codes.map(code => {
                return (value == code.code) ? (
                    setTotalprice(order.totalPrice - code.price),
                    console.log(code.price),
                    console.log(totalPrice),
                    console.log(order.totalPrice),
                    setDiscountcode(true)
                    
                ) : (
                    // setTotalprice(order.totalPrice)
                    ""
                    // dispatch(getOrderDetails(orderId))
                )
            })
        }
    }

    useEffect(() => {
        // if (!userInfo) {
        //     history.push('/login')
        // }
        if(!order || successPay || order._id !== Number(orderId) || successDeliver) {
            dispatch({ type: ORDER_PAY_RESET })
            dispatch({ type: ORDER_DELIVER_RESET })
            dispatch(getOrderDetails(orderId))
            getCodes()
            
        } else if (!order.isPaid) {
            // setTotalprice(order.totalPrice)
            if (!window.paypal) {
                addPayPalScript()
            } else {
                setSdkReady(true)
            }
        }
    }, [dispatch, order, orderId, successPay, successDeliver, discountCode, userInfo])

    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(orderId, paymentResult))
    }

    const deliverHandler = () => {
        dispatch(deliverOrder(order))
    }

    return loading ? (
        <></>
    ) : error ? (
        <>{error}</>
    ) : (
        <>
            
            {userInfo ? (
                <>
                <Header step7 match={match} />
                <div  className="container my-5">

                <Row>
                    <Col className="d-flex justify-content-center align-items-center" md={8}>
                        <div className="row img col-4">
                            <img src={bg} alt="" className=""/>
                        </div>
                    </Col>
    
                    <Col md={4}>
                        <Card>
                            <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h2>Order Summary</h2>
                            </ListGroup.Item>
    
                            <ListGroup.Item>
                                <Row>
                                    <Col>Book Name:</Col>
                                    <Col>{order.product.name}</Col>
                                </Row>
                            </ListGroup.Item>
    
                            <ListGroup.Item>
                                <Row>
                                    <Col>Promo Code:</Col>
                                    <Col><input onChange={(e) => checkCode(e.target.value)} className="promo-code" type="number" placeholder="Enter Code" /></Col>
                                </Row>
                            </ListGroup.Item>
    
                            <ListGroup.Item>
                                <Row>
                                    <Col>Total:</Col>
                                    {location2.country == 'TR' ? (
                                                <Col>TL {order.product.price_in_tl}</Col>
                                            ) : (
                                                <Col>${discountCode ? (
                                                    totalPrice
                                                ) : (
                                                    order.totalPrice
                                                )}</Col>
                                    )}
                                </Row>
                            </ListGroup.Item>
    
                            {!order.isPaid && (
                                <ListGroup.Item>
                                    {loadingPay && <>
                                    
                                    </>}
    
                                    {!sdkReady ? (
                                       <></>
                                    ) : (
                                        <PayPalButton 
                                            amount={discountCode ? (
                                                totalPrice
                                            ) : (
                                                order.totalPrice
                                            )}
                                            onSuccess={successPaymentHandler}
                                        />
                                    )}
                                </ListGroup.Item>
                            )}
                            </ListGroup>
                        </Card>
                        {order.isPaid && (
                                <p className="alert alert-success">Order paid at {order.paidAt.slice(0, 10)}</p>
                        )}
                    </Col>
                </Row>
                </div>
                 <Footer />
                 </>
            ) : (
                <Login match={match} history={history} location={location} />
            )}
            {/* <Login match={match} history={history} location={location} /> */}
           
        </>
    )
}

export default OrderScreen
