import { 

    MAJOR_DETAILS_REQUEST,
    MAJOR_DETAILS_SUCCESS,
    MAJOR_DETAILS_FAIL,
} from '../constants/universityPageConstants'

export const MajorDetailsReducer = (state={loading: true}, action) => {
    switch(action.type) {

        case MAJOR_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }
        
        case MAJOR_DETAILS_SUCCESS:
            return {
                loading: false,
                order: action.payload
            }

        case MAJOR_DETAILS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}