import React, { useState, useEffect } from 'react'

import hero from '../assets/images/RegisterationApplication/hero.png'

const RegisterApplication = ({ history }) => {

    let [bachelor, setBachelor] = useState(false)
    let [master, setMaster] = useState(false)
    let [phd, setPhd] = useState(false)

    let [degrees, setDegrees] = useState([])

    let getDegrees = async () => {
        let response = await fetch(`/api/dashboard/filter/degrees/`)
        let data = await response.json()
        setDegrees(data)
    }

    let handleChange = (value) => {
            if(value == 1) {
                setBachelor(true)
                setMaster(false)
                setPhd(false)
            } else if(value == 2) {
                setMaster(false)
                setBachelor(false)
                setPhd(true)
            } else if(value == 3) {
                setBachelor(false)
                setMaster(true)
                setPhd(false)
            }
    }

    useEffect(() => {
        getDegrees()
    }, [bachelor, master, phd])

    let [name, setName] = useState('')
    let [motherName, setMothername] = useState('')
    let [fatherName, setFathername] = useState('')
    let [phone, setPhone] = useState('')
    let [birth, setBirth] = useState('')
    let [passport, setPassport] = useState('')
    let [highSchool, setHighschool] = useState('')
    let [email, setEmail] = useState('')
    let [homeAddress, setHomeaddress] = useState('')
    let [nufus, setNufus] = useState('')
    let [gender, setGender] = useState('')
    let [personalPhoto, setPersonalphoto] = useState('')
    let [message, setMessage] = useState(false)

    let form = {
        'name': name,
        'motherName': motherName,
        'fatherName': fatherName,
        'phone': phone,
        'birth': birth,
        'passport': passport,
        'highSchool': highSchool,
        'email': email,
        'homeAddress': homeAddress,
        'nufus': nufus,
        'gender': gender,
        'personalPhoto': personalPhoto,
    }

    let createForm = async () => {
        if (name == "" || motherName == "" || fatherName == "" || phone == "" || birth == "" || passport == ""
        || highSchool == "" || email == "" || gender == ""
        ) {
            setMessage(true)
            window.scrollTo(0, 500)
        } else {
            fetch(`/api/dashboard/register/form/`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
                
            })
        }
        
    }

    let handleSubmit = () => {
        createForm()
        // history.push('/')
    }

    return (
        <div className="container-fluid">

            <div className="container application-container-hero">
                <img style={{alignSelf: "center"}} className="img-fluid w-25" src={hero} alt="" />
            </div>

            <div className="container application-container mt-5">
            <select
                    onChange={(e) => handleChange(e.target.value)}
                    style={{padding: "20px", margin: "10px 0"}}
                >
                    <option selected disabled hidden>Select Degree Type</option>
                    {degrees.map(degree => (
                        <option value={degree.id} key={degree.id}>
                            {degree.name}
                        </option>
                    ))}
                </select>
            </div>

            {bachelor ? (
                <>
                    <div className="container application-container mt-5">

                    {message && (
                        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}} className="container mt-5"><h3 style={{alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px'}} className="text-center">
                            Application Form</h3><p className="alert alert-danger">Please fill in the fields below in English only, other formats will not be accepted </p>
                        </div>
                    )}
                    
                    <div className="container">
                        <input required placeholder="Full Name" type="text" />
                        <input required placeholder="Mother's Name" type="text" />
                    </div>

                    <div className="container">
                        <input required placeholder="Father's Name" type="text" />
                        <input required placeholder="Phone Number" type="text" />
                    </div>

                    <div className="container">
                        <input required placeholder="Birth Date (dd/mm/yyyy)" type="text" />
                        <input required placeholder="Passport Number" type="text" />
                    </div>

                    <div className="container">
                        <input required placeholder="High School Name" type="text" />
                        <input required placeholder="Email Address" type="email" />
                    </div>

                    <div className="container">
                        <input required placeholder="Home Address" type="text" />
                    </div>

                    <div id="gender" className="container">
                        <select required style={{padding: "10px", margin: "10px 0"}} onChange={(e) => {setGender(e.target.value)}} value={gender?.gender} id="gender">
                            <option hidden disabled selected>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            {/* <label htmlFor="male">Male</label>
                            <input id="male" type="radio" name="gender" />
                            <label htmlFor="female">Female</label>
                            <input id="female" type="radio" name="gender" /> */}
                        </select>
                    </div>
                </div>

                <div className="container applicaiton2 application-container mt-5 mb-5 py-1">

                    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}} className="container mt-5"><h3 style={{alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px'}} className="text-center">
                        Documents</h3><p className="alert alert-danger">Please upload high quality valid documents with only two languages English or Turkey </p>
                    </div>
                    <div className="container w-75">
                        <label className="container w-75">Personal Photo</label>
                        <input required type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Passport Copy</label>
                        <input required type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">High School Diploma (original)</label>
                        <input required type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">High school Transcript (original)</label>
                        <input required type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">High school diploma (translated to turkish or english)</label>
                        <input required type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">High school transcript (translated to turkish or english)</label>
                        <input required type="file" />
                    </div>
                    <button onClick={handleSubmit} className="register-btn">Register</button>
                </div>
            </>
            ) : master ? (
                <>
                <div className="container application-container mt-5">

                {message && (
                        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}} className="container mt-5"><h3 style={{alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px'}} className="text-center">
                            Application Form</h3><p className="alert alert-danger">Please fill in the fields below in English only, other formats will not be accepted </p>
                        </div>
                    )}
                    <div className="container">
                        <input required onChange={(e) => {setName(e.target.value)}} value={name?.name} placeholder="Full Name" type="text" />
                        <input required onChange={(e) => {setMothername(e.target.value)}} value={motherName?.motherName} placeholder="Mother's Name" type="text" />
                    </div>

                    <div className="container">
                        <input required onChange={(e) => {setFathername(e.target.value)}} value={fatherName?.fatherName} placeholder="Father's Name" type="text" />
                        <input required onChange={(e) => {setPhone(e.target.value)}} value={phone?.phone} placeholder="Phone Number" type="text" />
                    </div>

                    <div className="container">
                        <input required onChange={(e) => {setBirth(e.target.value)}} value={birth?.birth} placeholder="Birth Date (dd/mm/yyyy)" type="text" />
                        <input required onChange={(e) => {setPassport(e.target.value)}} value={passport?.passport} placeholder="Passport Number" type="text" />
                    </div>

                    <div className="container">
                        <input required onChange={(e) => {setHighschool(e.target.value)}} value={highSchool?.highSchool} placeholder="High School Name" type="text" />
                        <input required onChange={(e) => {setEmail(e.target.value)}} value={email?.email} placeholder="Email Address" type="email" />
                    </div>

                    <div className="container">
                        <input required onChange={(e) => {setHomeaddress(e.target.value)}} value={homeAddress?.homeAddress} placeholder="Home Address" type="text" />
                    </div>

                    <div className="container">
                        <select required style={{padding: "10px", margin: "10px 0"}} onChange={(e) => {setGender(e.target.value)}} value={gender?.gender} id="gender">
                            <option hidden disabled selected>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            {/* <label htmlFor="male">Male</label>
                            <input id="male" type="radio" name="gender" />
                            <label htmlFor="female">Female</label>
                            <input id="female" type="radio" name="gender" /> */}
                        </select>
                    </div>
                </div>

                <div className="container applicaiton2 application-container mt-5 mb-5 py-1">

                    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}} className="container mt-5"><h3 style={{alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px'}} className="text-center">
                        Documents</h3><p className="alert alert-danger">Please upload high quality valid documents with only two languages English or Turkey </p>
                    </div>
                    <div className="container w-75">
                        <label className="container w-75">Personal Photo</label>
                        <input required onChange={(e) => {setPersonalphoto(e.target.files[0]); console.log(e.target.files)}} value={personalPhoto?.personalPhoto} type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Passport Copy</label>
                        <input required placeholder="Phone Number" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Bachelor's Degree Diploma (original)</label>
                        <input required placeholder="Passport Number" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Bachelor's Degree Transcript (original)</label>
                        <input required placeholder="Email Address" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Bachelor's Degree diploma (translated to turkish or english)</label>
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Bachelor's Degree transcript (translated to turkish or english)</label>
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Master's Degree Diploma (original)</label>
                        <input required placeholder="Passport Number" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Master's Degree Transcript (original)</label>
                        <input required placeholder="Email Address" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Master's Degree diploma (translated to turkish or english)</label>
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Master's Degree transcript (translated to turkish or english)</label>
                    </div>

                    <button onClick={handleSubmit} className="register-btn">Register</button>
                </div>
            </>
            ) : phd ? (
                <>
                    <div className="container application-container mt-5">

                    {message && (
                        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}} className="container mt-5"><h3 style={{alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px'}} className="text-center">
                            Application Form</h3><p className="alert alert-danger">Please fill in the fields below in English only, other formats will not be accepted </p>
                        </div>
                    )}
                    <div className="container">
                        <input required placeholder="Full Name" type="text" />
                        <input required placeholder="Mother's Name" type="text" />
                    </div>

                    <div className="container">
                        <input required placeholder="Father's Name" type="text" />
                        <input required placeholder="Phone Number" type="text" />
                    </div>

                    <div className="container">
                        <input required placeholder="Birth Date (dd/mm/yyyy)" type="text" />
                        <input required placeholder="Passport Number" type="text" />
                    </div>

                    <div className="container">
                        <input required placeholder="High School Name" type="text" />
                        <input required placeholder="Email Address" type="email" />
                    </div>

                    <div className="container">
                        <input required placeholder="Home Address" type="text" />
                    </div>

                    <div id="gender" className="container">
                        <select required style={{padding: "10px", margin: "10px 0"}} onChange={(e) => {setGender(e.target.value)}} value={gender?.gender} id="gender">
                            <option hidden disabled selected>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            {/* <label htmlFor="male">Male</label>
                            <input id="male" type="radio" name="gender" />
                            <label htmlFor="female">Female</label>
                            <input id="female" type="radio" name="gender" /> */}
                        </select>
                    </div>
                    </div>

                    <div className="container applicaiton2 application-container mt-5 mb-5 py-1">

                    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}} className="container mt-5"><h3 style={{alignSelf: 'center', color: '#f59628', letterSpacing: '1px', marginRight: '10px'}} className="text-center">
                        Documents</h3><p className="alert alert-danger">Please upload high quality valid documents with only two languages English or Turkey </p>
                    </div>
                    <div className="container w-75">
                        <label className="container w-75">Personal Photo</label>
                        <input required placeholder="Full Name" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Passport Copy</label>
                        <input required placeholder="Phone Number" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">High School Diploma (original)</label>
                        <input required placeholder="Passport Number" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">High school Transcript (original)</label>
                        <input required placeholder="Email Address" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">High school diploma (translated to turkish or english)</label>
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">High school transcript (translated to turkish or english)</label>
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Bachelor's Degree Diploma (original)</label>
                        <input required placeholder="Passport Number" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Bachelor's Degree Transcript (original)</label>
                        <input required placeholder="Email Address" type="file" />
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Bachelor's Degree diploma (translated to turkish or english)</label>
                    </div>

                    <div className="container w-75">
                        <label className="container w-75">Bachelor's Degree transcript (translated to turkish or english)</label>
                    </div>
                    <button className="register-btn">Register</button>
                    </div>
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export default RegisterApplication