import React, { useState, useEffect } from 'react'
import ArHeroCarousel from '../../components/ArabicComponents/ArHeroCarousel'
import ArOwlCarousel from '../../components/ArabicComponents/ArOwlCarousel'
import ArSuccessStories from '../../components/ArabicComponents/ArSuccessStories'
import ArWhatWeOffer from '../../components/ArabicComponents/ArWhatWeOffer'
import ArTopUniversities from '../../components/ArabicComponents/ArTopUniversities'
import ArServices from '../../components/ArabicComponents/ArServices'
import ArCounter from '../../components/ArabicComponents/ArCounter'
import ArHowWeWork from '../../components/ArabicComponents/ArHowWeWork'
import ArHeader from '../../components/ArabicComponents/ArHeader'
import ArFooter from '../../components/ArabicComponents/ArFooter'

import { Accordion, Button } from "react-bootstrap";
import ArAboutProvada from '../../components/ArabicComponents/ArAboutProvada'
import Loader from '../../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { getContactDataAction } from '../../redux/contact'

const ArHomePage = ({ match }) => {

    const dispatch = useDispatch()

    const getContactData = useSelector(state => state.getContactData)
    const { loading, data, error } = getContactData

    let [headers, setHeaders] = useState([])

    let [header1, setHeader1] = useState([])
    let [header2, setHeader2] = useState([])
    let [header3, setHeader3] = useState([])

    let getHeaders = async () => {
        let response = await fetch(`/api/dashboard/sliders/`)
        let data = await response.json()
        setHeaders(data)
    }

    let [faqs, setFaqs] = useState([])

    let getFaqs = async () => {
        let response = await fetch('/api/dashboard/store/faqs')
        let data = await response.json()
        setFaqs(data)
    }

    useEffect(() => {
        getFaqs()
        window.scrollTo(0, 0)
        dispatch(getContactDataAction())
    }, [dispatch])

    return (
        <div>
            {loading ? (
                <Loader />
            ) : data ? (
                <>
                    <ArHeader match={match} step1 />
                    <ArHeroCarousel />
                    <div className='about-provada'>
                        <h2 style={{ letterSpacing: 0 }}>عن بروفادا</h2>
                        <div className="container" style={{ flexDirection: 'row-reverse' }}>
                            <div className='vision'>
                                <h2 style={{ letterSpacing: 0 }}>الرؤية</h2>
                                <img src={data.vision_image} alt="" />
                                <p style={{ textAlign: 'right', borderRadius: '0 0 0 100px', letterSpacing: 0, whiteSpace: "normal" }}>{data.vision_ar?.length > 300 ? data.vision_ar.slice(0, 300) : data.vision_ar}</p>
                            </div>

                            <div className='mission'>
                                <h2 style={{ letterSpacing: 0 }}>المهمة</h2>
                                <img src={data.mission_image} alt="" />
                                <p style={{ textAlign: 'right', borderRadius: '0 0 0 100px', letterSpacing: 0, whiteSpace: "normal" }}>{data.mission_ar?.length > 300 ? data.mission_ar.slice(0, 300) : data.mission_ar}</p>
                            </div>

                            <div>
                                <h2 style={{ letterSpacing: 0 }}>التاريخ</h2>
                                <img src={data.history_image} alt="" />
                                <p style={{ textAlign: 'right', borderRadius: '0 0 0 100px', letterSpacing: 0, whiteSpace: "normal" }}>{data.history_ar?.length > 300 ? data.history_ar.slice(0, 300) : data.history_ar}</p>
                            </div>
                        </div>
                    </div>
                    <ArTopUniversities />
                    <ArServices />
                    <ArCounter />
                    <ArHowWeWork />
                    <ArWhatWeOffer />
                    {/* <ArSuccessStories /> */}
                    <ArOwlCarousel />

                    <div className="container faq-container mb-5 w-50">
                        <h3 className="mb-5" style={{ color: "#f6921e", textAlign: "center", letterSpacing: "0" }}>الأسئلة الشائعة</h3>
                        <Accordion className="arAccordion" style={{ backgroundColor: "#f6921e" }}>
                            {faqs.map(faq => (
                                <Accordion.Item eventKey={faq.question}>
                                    <Accordion.Header style={{ backgroundColor: "#f6921e", fontSize: "28px" }}><h4 style={{ color: "#000000", letterSpacing: "0", textAlign: 'right', direction: "rtl" }}>{faq.question_ar}</h4></Accordion.Header>
                                    <Accordion.Body>
                                        <>
                                            <div className="row">
                                                <p style={{ letterSpacing: "0", textAlign: "right", direction: "rtl" }}>{faq.answer_ar}</p>
                                            </div>
                                        </>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>

                    </div>

                    <ArFooter />
                </>
            ) : (
                <></>
            )}
        </div>
    )
}

export default ArHomePage