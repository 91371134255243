import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import ArFilter from '../../components/ArabicComponents/ArFilter'
import ArFooter from '../../components/ArabicComponents/ArFooter'
import ArHeader from '../../components/ArabicComponents/ArHeader'

const ArUniversitiesPage = ({ match }) => {

    let [universities, setUniversities] = useState([])

    useEffect(() => {
        getUniversities()
        window.scrollTo(0, 0)
    }, [])

    let getUniversities = async () => {
        let response = await fetch('/api/dashboard/universities/')
        let data = await response.json()
        setUniversities(data)
    }

    return (
        <>
            <ArFilter match={match} />
        </>
    )
}

export default ArUniversitiesPage