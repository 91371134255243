import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

import RegisterApplication from '../components/RegisterApplication'

const RegisterationPage = ({ match }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Header match={match} />
            <div style={{marginTop: "50px"}} className="container">
                <h4 style={{textAlign: "center"}}>Home <i style={{color: "#f6992d"}} class="fas fa-greater-than"></i> Register Application</h4>
            </div>

            <div className="registration-steps-band">
                <h2 style={{textAlign: "center"}}>Registration Application</h2>
            </div>
            <RegisterApplication />
            <Footer />
        </div>
    )
}

export default RegisterationPage
