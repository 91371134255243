import React, {useEffect} from 'react'
import ArFooter from '../../components/ArabicComponents/ArFooter'
import ArHeader from '../../components/ArabicComponents/ArHeader'

import ArRegisterApplication from '../../components/ArabicComponents/ArRegisterApplication'

const ArRegisterationPage = ({ match }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <ArHeader match={match} />
            <div style={{marginTop: "50px"}} className="container">
                <h4 className="text-ar2" style={{textAlign: "center", letterSpacing: "0"}}>الرئيسية <i style={{color: "#f6992d"}} class="fas fa-less-than"></i> استمارة التسجيل</h4>
            </div>

            <div className="registration-steps-band">
                    <h2 className="text-ar2" style={{textAlign: "center", letterSpacing: "0"}}>استمارة التسجيل</h2>
            </div>
            <ArRegisterApplication />
            <ArFooter />
        </div>
    )
}

export default ArRegisterationPage
