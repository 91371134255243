import React, {useEffect, useState} from 'react'
import pdf2 from '../assets/Pdf/ProvadaRegisterationSteps.pdf'

import hero from '../assets/images/RegistrationSteps/hero.gif'
import firstStep from '../assets/images/RegistrationSteps/first-step.png'
import secondStep from '../assets/images/RegistrationSteps/second-step.png'
import thirdStep from '../assets/images/RegistrationSteps/third-step.png'
import fourthStep from '../assets/images/RegistrationSteps/fourth-step.png'
import fifthtStep from '../assets/images/RegistrationSteps/fifth-step.png'
import sixthStep from '../assets/images/RegistrationSteps/sixth-step.png'
import firstColumn from '../assets/images/RegistrationSteps/first-column.png'
import secondColumn from '../assets/images/RegistrationSteps/second-column.png'
import thirdColumn from '../assets/images/RegistrationSteps/third-column.png'

import icon7 from '../assets/images/Untitled-1-09.svg'
import icon9 from '../assets/images/Untitled-1-07.svg'
import icon1 from '../assets/images/Untitled-1-01.svg'
import icon3 from '../assets/images/Untitled-1-03.svg'
import icon8 from '../assets/images/Untitled-1-08.svg'
import icon10 from '../assets/images/Untitled-1-10.svg'
import icon2 from '../assets/images/Untitled-1-02.svg'
import icon4 from '../assets/images/Untitled-1-04.svg'
import icon11 from '../assets/images/Untitled-1-11.png'
import icon12 from '../assets/images/Untitled-1-12.svg'
import icon5 from '../assets/images/Untitled-1-05.svg'
import icon6 from '../assets/images/Untitled-1-06.svg'

import num1 from '../assets/images/RegistrationSteps/V2/num1.png'
import firstIcon from '../assets/images/RegistrationSteps/V2/firstIcon.png'

import Header from '../components/Header'
import Footer from '../components/Footer'

const RegistrationsStepsPage = ({ match }) => {

    let [pdf, setPdf] = useState([])

    let getPdf = () => {
        // let response = await fetch('/images/provada_regestrution_form_pdf_1.pdf'
        // )
        //   .then((response) => response.blob())
        //   .then((blob) => {
        //     // Create blob link to download
        //     const url = window.URL.createObjectURL(
        //       new Blob([blob]),
        //     );
        //     console.log(url)
            const link = document.getElementById('download');
            link.href = 'https://provada-test.herokuapp.com/images/provada_regestrution_form_pdf_1.pdf';
        
            // Append to html link element page
            document.body.appendChild(link);
        
            // Start download
            link.click();
        
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        //   }
        // )
    }

    // let download = async () => {
    //     let url = pdf.file
    //     let doc = document.getElementById('download')
    //     doc.setAttribute("href", url)
    // }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Header match={match} step5 />
            <div className="container-fluid">
                <div style={{marginTop: "50px"}} className="container">
                    <h4 style={{textAlign: "center"}}>Home <i style={{color: "#f6992d"}} class="fas fa-greater-than"></i> Registration</h4>
                </div>
            </div>
            <div className="registration-steps-band">
                    <h2 style={{textAlign: "center"}}>Registration Steps</h2>
            </div>

            <div style={{marginTop: "50px"}} className="container-fluid">
                <div className="container" style={{display: 'flex', flexDirection: 'column'}}>
                    <h4 style={{textAlign: "center"}}>CHOOSE YOUR DESIRE PROGRAM AND UNIVERSITY THROUGH THE SYSTEM AND
                        THEN UPLOAD YOUR DOCUMENTS TO THE SYSTEM
                    </h4>

                    <img style={{alignSelf: 'center'}} width="40%" height="40%" className="img-fluid" src={hero} alt="" />
                </div>
            </div>

            <div style={{marginTop: "100px", marginBottom: "100px"}} className="container-fluid world-map">
                <div className="container steps-container">
                    {/* <img className="img-fluid" src={firstStep} alt="" /> */}

                    <div className="container columns-container">
                        <img className="img-fluid" src={firstColumn} alt="" />
                        <img className="img-fluid" src={secondColumn} alt="" />
                        <img className="img-fluid" src={thirdColumn} alt="" />
                    </div>

                    <div>
            <div className="container col-12 mt-5">
                <div className='container-d w-100'>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon9} alt="" />
                            <img src={icon7} alt="" />
                        </div>
                        <p>Fill the Application</p>
                    </div>
                    <div className='context-cont'>
                        <h4><span>Fill</span> the application through our 
                        website and will team will register 
                        you to the desired universities
                        </h4>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='container-d mt-4'>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon1} alt="" />
                            <img src={icon3} alt="" />
                        </div>
                        <p>Offer Letter</p>
                    </div>
                    <div className='context-cont'>
                        <h4><span>Once the university</span> approves 
                        your documents you will receive 
                        an offer letter explaining the major 
                        you are accepted to, the annual fees, 
                        and the discount you get.
                        </h4>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='container-d mt-4'>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon8} alt="" />
                            <img src={icon10} alt="" />
                        </div>
                        <p>Deposit Payment</p>
                    </div>
                    <div className='context-cont'>
                        <h4><span>In order</span> to reserve your seat at the 
                        university, you need to pay a deposit 
                        payment (which is mentioned in the 
                        offer letter) directly to the university’s 
                        bank account and send us a photo of the 
                        bank receipt.
                        </h4>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='container-d mt-4'>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon2} alt="" />
                            <img src={icon4} alt="" />
                        </div>
                        <p>Final Acceptance <br /> Letter</p>
                    </div>
                    <div className='context-cont'>
                        <h4><span>Once</span> the deposit payment is in the 
                        university’s bank account, you will 
                        receive the final acceptance letter 
                        which means you officially have a 
                        seat reserved to your name
                        </h4>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='container-d mt-4'>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon11} alt="" />
                            <img src={icon12} alt="" />
                        </div>
                        <p>Visa Application</p>
                    </div>
                    <div className='context-cont'>
                        <h4><span>Based on</span> the offer and final acceptance 
                        letter along with the bank receipt, you 
                        can apply for a student visa through the 
                        nearest Turkish embassy. Make sure to 
                        notify us with your appointment date so 
                        we can help you by requesting an invitation 
                        letter from the university.
                        </h4>
                    </div>
                </div>
    {/* ///////////////////////////////////////////////////////////// */}
                <div className='container-d mt-4'>
                    <div className='icon-cont'>
                        <div>
                            <img src={icon5} alt="" />
                            <img src={icon6} alt="" />
                        </div>
                        <p>Finalize you <br /> Registration</p>
                    </div>
                    <div className='context-cont'>
                        <h4><span>Handle your</span> documents (both original and 
                        Turkish translated) to the international 
                        office at the university in order to finalize 
                        your registration and get your student card 
                        and number. Don’t forget, you can always 
                        request one of our team members to help 
                        you throughout the final registration process. 
                        </h4>
                    </div>
                </div>
            </div>
        </div>

                    {/* <img style={{marginTop: "50px"}} className="img-fluid" src={secondStep} alt="" />
                    <img className="img-fluid" src={thirdStep} alt="" />
                    <img className="img-fluid" src={fourthStep} alt="" />
                    <img className="img-fluid" src={fifthtStep} alt="" />
                    <img className="img-fluid" src={sixthStep} alt="" /> */}

                    {/* <a target="_blank" id="download" onClick={getPdf} style={{background: "#f6992d", color: "#ffffff", padding: "15px", borderRadius: "10px", textDecoration: "none", cursor: "pointer"}}>DOWNLOAD PDF</a> */}
                    <a href={pdf2} target="_blank" id="download" style={{background: "#f6992d", color: "#ffffff", padding: "15px", borderRadius: "10px", textDecoration: "none", cursor: "pointer"}}>DOWNLOAD PDF</a>
                </div>

                {/* <div className="container">

                    <div className="row">
                            <div className="col-md-4">
                                <div className="row">
                                    <div className="col-md-5 d-flex justify-content-end align-items-center">
                                        <img src={firstIcon} alt="" />
                                    </div>
                                    <div className="col-md-5">
                                        <img src={num1} alt="" />
                                    </div>
                                </div>
                                <p style={{color: "#f6992d"}} className="text-center">Fill the Application</p>
                            </div>

                            <div className="col-md-8">
                                <span style={{color: "#f6992d"}}>Fill</span> the application through our <br />
                                website and will team will register <br />
                                you to the desired universities 
                            </div>
                        </div>
                    </div> */}
                
            </div>
            <Footer />
        </>
    )
}

export default RegistrationsStepsPage
