import React from 'react'
import email from '../../assets/images/HowWeWork/email-01.png'
import receipt2 from '../../assets/images/HowWeWork/receipt2.png'
import receipt from '../../assets/images/HowWeWork/receipt-01.png'
import clipboard from '../../assets/images/HowWeWork/clipboard-01.png'

const HowWeWork = () => {
    return (

      <>
      <div className="col-11 m-auto mt-4">
        <div className="container how-we-work" style={{marginTop: "50px"}}>
          <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <h2 className="ar-text2" style={{textAlign: "center", letterSpacing: "0"}}>كيف نعمل
            </h2>
            <div className="rectangle-115"></div>
          </div>
          <div className="row" style={{marginTop: "50px", letterSpacing: "0", textAlign: "center"}}><p> <h4 className="ar-text" style={{color: "#f27c66", letterSpacing: "0"}}>الخطوة الأولى : الملف الشخصي</h4> يتم إنشاء ملف تعريف على الموقع الرسمي للمؤسسة ويتم ملؤه بالمعلومات الشخصية ورغبات الدراسة ، ليتم إرساله إلى البريد الإلكتروني للمؤسسة الرسمية</p></div>
          <div className="row" style={{borderBottom: "2px solid #f9cb8f", paddingBlock: "4%"}}>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-8">
                  <div className="ar-text" style={{textAlign: 'right'}}>
                    <p className="steps-title ar-text" style={{letterSpacing: "0", textAlign: 'right'}}>خطاب القبول النهائي</p> <br />
                    في غضون 24 ساعة نبدأ العمل على ملف الطالب ونحصل على خطاب العرض الرسمي - أي خطاب بعد تقديم نسخة من إيصال البنك وخلال مدة أقصاها 24 ساعة
                  </div>

                <h3 className="ar-text2" style={{textAlign: "center", marginTop: "50px", color: "#f27c66", letterSpacing: "0"}}>الخطوة الثالثة</h3>
                </div>
                <div className="col-md-4" style={{display: "flex"}}>
                  <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content"}}>
                    <img src={receipt} alt="" />
                    <div className="rectanglear"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="row">
                <div className="col-md-8">
                  <div className="ar-text">
                    <p className="steps-title ar-text" style={{letterSpacing: "0", textAlign: 'right'}}>
                      القبول المبدئي
                      </p> <br />
                      في غضون 24 ساعة نبدأ العمل على ملف الطالب والحصول على خطاب العرض الرسمي
                    </div>
                <h3 className="ar-text step2ar-1400" style={{textAlign: "center", marginTop: "50px", color: "#f27c66", letterSpacing: "0", textAlign: 'right'}}>الخطوة الثانية</h3>
                </div>
                <div className="col-md-4" style={{display: "flex"}}>
                  <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content"}}>
                    <img src={email} alt="" />
                    <div className="rectanglear"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{paddingBlock: "4%"}}>
            <div className="col-md-6">
              <div className="row">
              <div className="col-md-4" style={{display: "flex", alignItems: "end"}}>
                  <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content"}}>
                    <div className="rectangle2ar"></div>
                    <img src={receipt2} alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <p className="ar-text" style={{textAlign: 'right'}}>
                    <p className="steps-title ar-text" style={{letterSpacing: "0", textAlign: 'right'}}>مع خطاب القبول النهائي</p> <br />
                    يمكن للطالب التقدم للحصول على تأشيرة دراسة من خلال السفارة التركية لبلاده
                </p>
                <h3 style={{textAlign: "center", marginTop: "50px", color: "#f27c66", letterSpacing: "0"}}>الخطوة الخامسة</h3>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="row">
            <div className="col-md-4" style={{display: "flex", alignItems: "end"}}>
                  <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content"}}>
                    <div className="rectangle3ar"></div>
                    <img src={clipboard} alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <p className="ar-text" style={{textAlign: 'right'}}>
                    <p className="steps-title ar-text" style={{letterSpacing: "0", textAlign: 'right'}}>
                      دفع الإيداع
                      </p> <br />
                      في حال رغبة الطالب بقبول العرض المرسل إليه ومتابعة إجراءات التسجيل يتوجب عليه دفع وديعة إلى الجامعة.
                </p>
                <h3 className="ar-text2" style={{textAlign: "center", marginTop: "50px", color: "#f27c66", letterSpacing: "0"}}>الخطوة الرابعة</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="how-we-work2">
          <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
              <h2 className="ar-text2" style={{textAlign: "center", letterSpacing: "0"}}>كيف نعمل
              </h2>
              <div className="rectangle-115"></div>
          </div>
          <div className="row" style={{marginTop: "50px"}}><p className="ar-text" style={{textAlign: "center", letterSpacing: "0"}}> <h4 style={{color: "#f27c66", letterSpacing: "0"}}>الخطوة الأولى : الملف الشخصي</h4> يتم إنشاء ملف تعريف على الموقع الرسمي للمؤسسة ويتم تعبئته بالمعلومات الشخصية ورغبات الدراسة ، ليتم إرسالها إلى البريد الإلكتروني للمؤسسة الرسمية. </p></div>
          <div className="how-we-work2-container">
            
            <div className="rectangle2-mob"></div>
            <div className="rectangle3-mob"></div>
            <h4 className="ar-text2" style={{marginTop: "100px", marginLeft: "55px", color: "#f27c66", letterSpacing: "0"}}>الخطوة الثانية</h4>
            <div style={{backgroundColor: "#f27c66", borderRadius: "50%", padding: "5px", height: "fit-content", marginTop: "304px", marginLeft: "-111px"}}>
              <img src={receipt} alt="" />
            </div>
            
          </div>
          <div className="ar-text" style={{marginTop: "-200px", marginLeft: "130px", letterSpacing: "0"}}>القبول المبدئي
                  
          في غضون 24 ساعة ، نبدأ العمل في ملف الطالب والحصول على خطاب العرض الرسمي
          </div>
          
        </div>

        <div className="how-we-work3">
          <div className="how-we-work2-container">
            
            <div style={{backgroundColor: "#f9cb8f"}} className="rectangle2-mob2"></div>
            <div style={{backgroundColor: "#f9cb8f"}} className="rectangle3-mob2"></div>
            <h4 className="ar-text2" style={{marginTop: "115px", marginLeft: "27px", color: "#f9cb8f", letterSpacing: "0"}}>الخطوة الثالثة</h4>
            <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content", marginTop: "304px", marginRight:"100px"}}>
              <img src={receipt2} alt="" />
            </div>
            
          </div>
          <div className="w-50 h4-step3ar ar-text" style={{marginTop: "-200px", marginRight: "165px !important", display: 'flex', letterSpacing: "0", marginRight: 'auto'}}>دفعة الإيداع: في حالة رغبة الطالب بقبول العرض المرسل إليه ومتابعة إجراءات التسجيل
          </div>
          
        </div>

        <div className="how-we-work3">
          <div className="how-we-work2-container">
            
            <div style={{backgroundColor: "#f9cb8f"}} className="rectangle2-mob2"></div>
            <div style={{backgroundColor: "#f9cb8f"}} className="rectangle3-mob2"></div>
            <h4 className="ar-text2" style={{marginTop: "100px", marginLeft: "21px", color: "#f9cb8f", letterSpacing: "0"}}>الخطوة الرابعة</h4>
            <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content", marginTop: "304px", marginRight:"100px"}}>
              <img src={receipt} alt="" />
            </div>
            
          </div>
          <div className="w-50 h4-step3ar ar-text" style={{marginTop: "-200px", marginRight: "253px", display: 'flex', letterSpacing: "0", marginRight: 'auto'}}>دفعة الإيداع: في حالة رغبة الطالب بقبول العرض المرسل إليه ومتابعة إجراءات التسجيل
          </div>
          
        </div>

        <div style={{marginBottom: "200px"}} className="how-we-work2">
          <div className="how-we-work2-container">
            
            <div className="rectangle2-mob"></div>
            <div className="rectangle3-mob"></div>
            <h4 className="ar-text2" style={{marginTop: "100px", marginLeft: "9", color: "#f27c66", letterSpacing: "0"}}>الخطوة الخامسة</h4>
            <div style={{backgroundColor: "#f27c66", borderRadius: "50%", padding: "5px", height: "fit-content", marginTop: "304px", marginLeft: "-82px"}}>
              <img src={receipt} alt="" />
            </div>
            
          </div>
          <div className="ar-text" style={{marginTop: "-200px", marginLeft: "130px", letterSpacing: "0"}}>مع خطاب القبول النهائي
          يمكن للطالب التقدم للحصول على تأشيرة دراسة من خلال السفارة التركية لبلاده
          </div>
          </div>
        </div>
      </>

    )
}

export default HowWeWork
