import React, { useState, useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import bg from '../../../assets/images/bg.png'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'

import { getOrderDetails, payOrder, deliverOrder } from '../../../actions/orderActions'
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from '../../../constants/orderConstants'

import ArFooter from '../../../components/ArabicComponents/ArFooter'
import ArHeader from '../../../components/ArabicComponents/ArHeader'
import ArLogin from '../ArLogin'

import useGeoLocation from "react-ipgeolocation";

function ArOrderScreen({ match, history, location }) {

    const orderId = match.params.id
    const dispatch = useDispatch()

    const location2 = useGeoLocation();

    const [discountCode, setDiscountcode] = useState(false)
    const [sdkReady, setSdkReady] = useState(false)

    const orderDetails = useSelector(state => state.orderDetails)
    const { order, error, loading } = orderDetails

    const orderPay = useSelector(state => state.orderPay)
    const { loading: loadingPay, success: successPay } = orderPay

    const orderDeliver = useSelector(state => state.orderDeliver)
    const { loading: loadingDeliver, success: successDeliver } = orderDeliver

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [codes, setCodes] = useState([])

    const [totalPrice, setTotalprice] = useState(0)

    // if(!loading && !error) {
    //     order.itemsPrice = order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
    // }

    // ARZo4IaCX0n2D6znqaqkE6HK97OrJJJrwHddYBaIVpwpRGAKdAIOvmzBYzDpxNCOVkrYIHnVY4CgiIDG
    const addPayPalScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://www.paypal.com/sdk/js?client-id=ARZo4IaCX0n2D6znqaqkE6HK97OrJJJrwHddYBaIVpwpRGAKdAIOvmzBYzDpxNCOVkrYIHnVY4CgiIDG'
        script.async = true
        script.onload = () => {
            setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    let getCodes = async () => {
        let response = await fetch ('/api/dashboard/codes')
        let data = await response.json()
        setCodes(data)
        console.log(data)
    }

    const checkCode = async (value) => {
        console.log("passing")
        if (codes.length !== 0) {
            codes.map(code => {
                return (value == code.code) ? (
                    setTotalprice(order.totalPrice - code.price),
                    console.log(code.price),
                    console.log(totalPrice),
                    console.log(order.totalPrice),
                    setDiscountcode(true)
                    
                ) : (
                    // setTotalprice(order.totalPrice)
                    ""
                    // dispatch(getOrderDetails(orderId))
                )
            })
        }
    }

    useEffect(() => {
        // if (!userInfo) {
        //     history.push('/ar/login')
        // }
        if(!order || successPay || order._id !== Number(orderId) || successDeliver) {
            dispatch({ type: ORDER_PAY_RESET })
            dispatch({ type: ORDER_DELIVER_RESET })
            dispatch(getOrderDetails(orderId))
            getCodes()
            
        } else if (!order.isPaid) {
            // setTotalprice(order.totalPrice)
            if (!window.paypal) {
                addPayPalScript()
            } else {
                setSdkReady(true)
            }
        }
    }, [dispatch, order, orderId, successPay, successDeliver, discountCode, userInfo])

    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(orderId, paymentResult))
    }

    const deliverHandler = () => {
        dispatch(deliverOrder(order))
    }

    return loading ? (
        <></>
    ) : error ? (
        <>{error}</>
    ) : (
        <>
            
            {userInfo ? (
                <>
                <ArHeader step7 match={match} />

                <div  className="container my-5">

<Row>
    <Col md={4}>
        <Card>
            <ListGroup variant='flush'>
            <ListGroup.Item>
                <h2 style={{letterSpacing: "0", textAlign: "right"}}>ملخص الطلب</h2>
            </ListGroup.Item>

            <ListGroup.Item>
                <Row>
                    <Col style={{letterSpacing: "0", textAlign: "right"}}>{order.product.name}</Col>
                    <Col style={{letterSpacing: "0", textAlign: "right"}}>اسم الكتاب</Col>
                </Row>
            </ListGroup.Item>

            <ListGroup.Item>
                <Row>
                    <Col style={{textAlign: "right"}}><input onChange={(e) => checkCode(e.target.value)} className="promo-code ar-input" type="number" placeholder="ادخل الكود " /></Col>
                    <Col style={{letterSpacing: "0", textAlign: "right"}}>كود الخصم</Col>
                </Row>
            </ListGroup.Item>

            <ListGroup.Item>
                <Row>
                    <Col style={{textAlign: "right"}}>{location2.country == 'TR' ? (
                                <Col>TL {order.product.price_in_tl}</Col>
                            ) : (
                                <Col>${discountCode ? (
                                    totalPrice
                                ) : (
                                    order.totalPrice
                                )}</Col>
                    )}</Col>
                    <Col style={{letterSpacing: "0", textAlign: "right"}}>المجموع</Col>
                </Row>
            </ListGroup.Item>

            {!order.isPaid && (
                <ListGroup.Item>
                    {loadingPay && <>
                    
                    </>}

                    {!sdkReady ? (
                       <></>
                    ) : (
                        <PayPalButton 
                            amount={discountCode ? (
                                totalPrice
                            ) : (
                                order.totalPrice
                            )}
                            onSuccess={successPaymentHandler}
                        />
                    )}
                </ListGroup.Item>
            )}
            </ListGroup>
        </Card>
        {order.isPaid && (
                <p className="alert alert-success text-end">{order.paidAt.slice(0, 10)} تم دفع الطلب في </p>
        )}
    </Col>
    
    <Col style={{display: "flex", justifyContent: "center", alignItems: 'center'}} md={8}>
        <div  className="row img col-4">
            <img src={bg} alt="" className=""/>
        </div>
    </Col>

    
</Row>
</div>
<ArFooter />
</>
            ) : (
                <ArLogin match={match} history={history} location={location} />
            )}
            
            
        </>
    )
}

export default ArOrderScreen
