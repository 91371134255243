import React, {useState} from 'react'
import { Form, Button } from 'react-bootstrap'

const TestForm = () => {

    let [firstName, setFirstname] = useState('')
    let [lastName, setLastname] = useState('')
    let [email, setEmail] = useState('')
    let [body, setBody] = useState('')

    let form = {
        'firstName': firstName,
        'lastName': lastName,
        'email': email,
        'body': body,
    }

    function getCookie(name) {
        var cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            var cookies = document.cookie.split(';');
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookie.trim(cookies[i]);
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    var csrftoken = getCookie('csrftoken');

    let submitHandle =  async () => {
        fetch(`/api/dashboard/contact/form/test/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrftoken
            },
            body: JSON.stringify(form)
            
        })
    }
    return (
        <div>
    <Form onSubmit={submitHandle}>
    <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Email address</Form.Label>
    <Form.Control onChange={(e) => setEmail(e.target.value)} value={email?.email} type="email" placeholder="Enter email" />

  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>last name</Form.Label>
    <Form.Control onChange={(e) => setLastname(e.target.value)} value={lastName?.lastName} type="text" placeholder="Enter last" />

  </Form.Group>
  <Form.Group className="mb-3" controlId="formBasicEmail">
    <Form.Label>Email address</Form.Label>
    <Form.Control onChange={(e) => setBody(e.target.value)} value={body?.body} type="text" placeholder="Enter body" />

  </Form.Group>

  <Form.Group className="mb-3" controlId="formBasicPassword">
    <Form.Label>Name</Form.Label>
    <Form.Control onChange={(e) => setFirstname(e.target.value)} value={firstName?.firstName} type="test" placeholder="Name" />
  </Form.Group>
  <Button variant="primary" type="submit">
    Submit
  </Button>
</Form>
        </div>
    )
}

export default TestForm
