const MODAL_SHOW = 'MODAL_SHOW'
const MODAL_HIDE = 'MODAL_HIDE'

export const handleShowAction = () => async (dispatch) => {
    dispatch({
        type: MODAL_SHOW
    })
}

export const handleHideAction = () => async (dispatch) => {
    dispatch({
        type: MODAL_HIDE
    })
}

export const handleChangeReducer = (state={}, action) => {
    switch(action.type) {
        case MODAL_SHOW:
            return {
                show: true,
            }
        case MODAL_HIDE:
            return {
                show: false,
            }
        default:
            return state
    }
}