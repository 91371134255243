import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import rectangle from '../assets/images/Rectangle.png'

const TopUniversities = () => {

    // let [universities, setUniversities] = useState([])

    useEffect(() => {
        getUniversities()
    }, [])
    
    // let getUniversities = async () => {
    //     let response = await fetch('/api/dashboard/top/')
    //     let data = await response.json()
    //     setUniversities(data)
    // }

    let [universities, setUniversities] = useState([])

    let getUniversities = async () => {
        let response = await fetch('/api/dashboard/universities/')
        let data = await response.json()
        data = data.slice(0, 4)
        setUniversities(data)
    }

    return (
        <div style={{backgroundImage: `url(${rectangle})`, paddingTop: "1px"}} className="container-fluid">
            <div className="container famous-unis">
                <h2 className="text-center">Most Famous Universities</h2>
                
                <div className="row">

                    {universities.map(university => (
                        <div ><Link to={`/universities/${university.id}`}><img className="container" src={university.logo} alt={university.name} /></Link></div>
                    ))}
                    
                </div>
            </div>
        </div>
    )
}

export default TopUniversities
