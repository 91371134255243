import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import smallFile from "../assets/images/UniversitySearch/big.png";
import { useDispatch, useSelector } from "react-redux";

import { getLanguagesDataAction } from "../redux/LanguagesRedux";
import { getDegreesDataAction } from "../redux/DegreesRedux";
import { getMajorsDataAction } from "../redux/MajorsRedux";
import { getUniversitiesDataAction } from "../redux/UniversitiesRedux";

import { getFilterDataAction } from "../redux/FilterData";

import Footer from '../components/Footer'
import Header from '../components/Header'
import Loader from '../components/Loader'

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { postFormDataAction } from "../redux/FormRedux";

const FilterFun = ({ match, history }) => {
  const dispatch = useDispatch();

  const [filterLangugageId, setFilterLangugageId] = useState("");
  const [filterMajorId, setFilterMajorId] = useState("");
  const [filterDegreeId, setFilterDegreeId] = useState("");
  const [filterUniversityId, setFilterUniversityId] = useState("");
  const [filterPriceId, setFilterPriceId] = useState("");

  var inputSearchId

  const getLangFilter = useSelector((state) => state.getLangFilter);
  let { data: languageData } = getLangFilter;

  const getMajorsFilter = useSelector((state) => state.getMajorsFilter);
  let { data: majorData, loading } = getMajorsFilter;

  const getDegreesFilter = useSelector((state) => state.getDegreesFilter);
  let { data: DegreesData } = getDegreesFilter;

  const getUniversitiesFilter = useSelector(
    (state) => state.getUniversitiesFilter
  );
  let { data: UniversitiesData } = getUniversitiesFilter;

  useEffect(() => {
    dispatch(getLanguagesDataAction());
    dispatch(getDegreesDataAction());
    dispatch(getMajorsDataAction());
    dispatch(getUniversitiesDataAction());

  }, [dispatch]);


  let filter = filterUniversityId || filterLangugageId || filterMajorId || filterDegreeId || inputSearchId

  let clear = false

  let filteredUniversities = UniversitiesData;

  let filteredMajors = majorData;

  if (filterMajorId) {
    let major_name
    filteredMajors = filteredMajors.filter(major => {
      window.scrollTo(0, 0)
      if (major.id == filterMajorId) {
        major_name = major.name
      }
      return major.name == major_name;
    });
  }

  if (filterUniversityId) {
    filteredMajors = filteredMajors.filter(major => {
      window.scrollTo(0, 0)
      return major.university.id == Number(filterUniversityId);
    })
  }

  if (filterPriceId) {
    filteredMajors = filteredMajors.filter(major => {
    })
  }

  if (filterDegreeId) {
    filteredMajors = filteredMajors.filter(major => {
      window.scrollTo(0, 0)
      return major.degree.id == filterDegreeId;
    });
  }

  if (filterLangugageId) {
    filteredMajors = filteredMajors.filter(major => {
      window.scrollTo(0, 0)
      return major.languages == filterLangugageId;
    });
  }

  if (clear) {
    filteredMajors = filteredMajors.filter(major => {
      return major
    })
  }

  const seen = new Set()

  const filteredArr = majorData?.filter(el => {
    const duplicate = seen.has(el.name);
    seen.add(el.name);
    return !duplicate;
  });

  const fakeLoader = true

  const handleChange = (event) => {
    if (event.target.name == "filterLangugageId") {
      setFilterLangugageId(event.target.value);
    }
    if (event.target.name == "filterMajorId") {
      setFilterMajorId(event.target.value);
    }
    if (event.target.name == "filterDegreeId") {
      setFilterDegreeId(event.target.value);
    }
    if (event.target.name == "filterUniversityId") {
      setFilterUniversityId(event.target.value);
    }
  };

  let [filterSearch, setFilterSearch] = useState([])

  let searchFilter = (val) => {
    filteredMajors = majorData.filter(major => {
      return major.name.toLowerCase().includes(val.toLowerCase())
    })
    setFilterSearch(filteredMajors)
  }

  const [input, setInput] = useState('');
  // const [formSuccess, setFormSuccess] = useState(false);

  // const createHandler = (majorName, majorUniversity, majorId) => {
  //   let form = {
  //     'name': "name",
  //     'motherName': "",
  //     'fatherName': "",
  //     'phone': "",
  //     'birth': "1990-01-01",
  //     'passport': "",
  //     'highSchool': "",
  //     'email': "",
  //     'homeAddress': "",
  //     'nufus': "",
  //     'gender': "",
  //     'personalPhoto': "",
  //     'passportCopy': "",
  //     'highSchoolCopy': "",
  //     'highSchoolTrans': "",
  //     'highSchoolDiplomaTranslated': "",
  //     'highSchoolTransTranslated': "",

  //     'bacheloreDegree': "",
  //     'bacheloreDegreeTranscript': "",
  //     'bacheloreDegreeTranslated': "",
  //     'bacheloreDegreeTranscriptTranslated': "",

  //     'mastersDegree': "",
  //     'mastersDegreeTranscript': "",
  //     'mastersDegreeTranslated': "",
  //     'mastersDegreeTranscriptTranslated': ""
  //   }
  //   dispatch(postFormDataAction(form))
  //   setFormSuccess(true)
  //   if (formSuccess) {
  //     history.push(`/register/${majorName}/${majorUniversity}/${majorId}/${formData.id}`)
  //   }
  //   // history.push(`/register/${majorName}/${majorUniversity}/${majorId}`)

  // }

  return (
    <>
      {loading ? (<Loader />) : majorData ? (
        <>
          <Header match={match} step4 />

          <div className="container-fluid">
            <div style={{ marginTop: "50px" }} className="container">
              <h4 style={{ textAlign: "center" }}>Home <i style={{ color: "#f6992d" }} class="fas fa-greater-than"></i> Universities</h4>
            </div>
          </div>

          <div className="container uni-container">
            <div className="filter">

              <input
                type="search"
                placeholder="Search Major"
                id="search-input"
                value={input}
                onInput={e => setInput(e.target.value)}
                onChange={(e) => { searchFilter(e.target.value) }}
                name={inputSearchId}
              />

              <div className="row filter-row">
                <h3 style={{ color: "#f69321", textAlign: "center" }}>Filter</h3>
                <img src={smallFile} alt="" style={{ marginBottom: "20px" }} />
                <FormControl variant="standard" className="language-filter" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label" style={{ left: '27px' }}>Select Language</InputLabel>
                  <Select
                    onChange={handleChange}
                    value={filterLangugageId}
                    name="filterLangugageId"
                    className="filter_select language-filter"
                  >
                    <MenuItem selected value="">
                      Select Language
                    </MenuItem>
                    {languageData &&
                      languageData.map((lang) => (
                        <MenuItem value={lang.id} key={lang.id}>
                          {lang.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl variant="standard" className="university-filter" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label" style={{ left: '27px' }}>Select University</InputLabel>
                  <Select
                    onChange={handleChange}
                    value={filterUniversityId}
                    name="filterUniversityId"
                    className="filter_select university-filter"
                  >
                    <MenuItem selected value="">
                      Select University
                    </MenuItem>
                    {UniversitiesData &&
                      UniversitiesData.map((university) => (
                        <MenuItem value={university.id} key={university.id}>
                          {university.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl variant="standard" className="major-filter" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label" style={{ left: '27px' }}>Select Major</InputLabel>
                  <Select
                    onChange={handleChange}
                    value={filterMajorId}
                    name="filterMajorId"
                    className="filter_select major-filter"
                  >
                    <MenuItem selected value="">
                      Select Major
                    </MenuItem>
                    {filteredArr &&
                      filteredArr.map((major) => (
                        <MenuItem value={major.id} key={major.id}>
                          {major.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl variant="standard" className="degree-filter" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-standard-label" style={{ left: '27px' }}>Select Degree</InputLabel>
                  <Select
                    onChange={handleChange}
                    value={filterDegreeId}
                    name="filterDegreeId"
                    className="filter_select degree-filter"
                  >
                    <MenuItem selected value="">
                      Select Degree
                    </MenuItem>
                    {DegreesData &&
                      DegreesData.map((degree) => (
                        <MenuItem value={degree.id} key={degree.id}>
                          {degree.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            {!filter && input.length <= 0 && (
              <div className="container mb-5">
                <div className="container">
                  <div style={{ justifyContent: "center" }} className="row uni-row">
                    {UniversitiesData && UniversitiesData.map(university => (
                      <div key={university.id} className="col-md-3">
                        <div className="container d-flex justify-content-center align-items-center"><img className="filter-logo-img" src={university.logo} alt="" /></div>
                        <p className="w-75"> {
                          university.sub_info.substring(0, 50)}...</p>
                        <Link style={{ textDecoration: "none", color: "#ffffff" }} to={`universities/${university.id}`}><button>Learn More</button></Link>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {filter && input.length <= 0 &&
              <div style={{ marginTop: "35px" }} className="container uni-container">
                <div className="container">
                  <div className="row uni-row">
                    <>
                      {filteredMajors.length <= 0 && (
                        <h5>We could not find any results that matches the search</h5>
                      )}
                      {filteredMajors.map(major => (
                        <div key={major.id} className="row result-row">
                          <h4 key={major.name}>{major.name} - {major.degree.name}</h4>
                          <div style={{ borderTop: "1px solid #f69422", margin: "20px 0" }} className="row wrappp" key={major.id}>
                            <img src={major?.university?.logo} style={{ maxWidth: "150px", maxHeight: "100px", objectFit: "contain" }} alt="" />
                            <div style={{ color: "#f69422" }} className="col-md-2">Language <br /><p style={{ color: "#000000", marginTop: "15px" }}>
                              {major.languages == 1 ? "English & Turkish" : major.languages == 2 ? "English" : major.languages == 3 ? "Turkish" : "Arabic"}
                            </p></div>
                            <div style={{ color: "#f69422" }} className="col-md-2">cost <br /><p style={{ color: "#000000", marginTop: "15px" }}><del>${major.pre_sale_fees}</del></p></div>
                            <div style={{ color: "#f69422" }} className="col-md-2">sale <br /><p style={{ color: "#000000", marginTop: "15px" }}>${major.post_sale_fees}</p></div>
                            <div style={{ color: "#f69422" }} className="col-md-2">Years <br /><p style={{ color: "#000000", marginTop: "15px" }}>{major.study_years}</p></div>
                            <div style={{ color: "#f69422", marginTop: "30px" }} className="col-md-2"><Link to={`/register/${major.degree.name}/${major.university.id}/${major.id}`} style={{ color: "#000000", textDecoration: "none", backgroundColor: "#f79b31", padding: "10px", color: "#ffffff" }}>Apply</Link></div>
                          </div>
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </div>
            }



            {filterSearch.length > 0 && input.length > 0 ? (
              <div style={{ marginTop: "35px" }} className="container uni-container">
                <div className="container">
                  <div className="row uni-row">
                    <>
                      {filterSearch.length === 0 ? (
                        <></>
                      ) : (filterSearch.map(el => (
                        <div key={el.id} className="row result-row">
                          <h4 key={el.name}>{el.name} - {el.degree.name}</h4>
                          <div style={{ borderTop: "1px solid #f69422", margin: "20px 0" }} className="row wrappp" key={el.id}>
                            <img src={el?.university?.logo} style={{ maxWidth: "150px", maxHeight: "100px", objectFit: "contain" }} alt="" />
                            <div style={{ color: "#f69422" }} className="col-md-2">Language <br /><p style={{ color: "#000000", marginTop: "15px" }}>
                              {el.languages == 1 ? "English & Turkish" : el.languages == 2 ? "English" : el.languages == 3 ? "Turkish" : "Arabic"}
                            </p></div>
                            <div style={{ color: "#f69422" }} className="col-md-2">cost <br /><p style={{ color: "#000000", marginTop: "15px" }}><del>${el.pre_sale_fees}</del></p></div>
                            <div style={{ color: "#f69422" }} className="col-md-2">sale <br /><p style={{ color: "#000000", marginTop: "15px" }}>${el.post_sale_fees}</p></div>
                            <div style={{ color: "#f69422" }} className="col-md-2">Years <br /><p style={{ color: "#000000", marginTop: "15px" }}>{el.study_years}</p></div>
                            <div style={{ color: "#f69422", marginTop: "30px" }} className="col-md-2"><Link to={`/register/${el.degree.name}/${el.university.id}/${el.id}`} style={{ color: "#000000", textDecoration: "none", backgroundColor: "#f79b31", padding: "10px", color: "#ffffff" }}>Apply</Link></div>
                          </div>
                        </div>
                      )))}
                    </>
                  </div>
                </div>
              </div>
            ) : filterSearch.length === 0 && input.length > 0 ?
              <div style={{ marginTop: "35px" }} className="container uni-container">
                <div className="container">
                  <div className="row uni-row">
                    <h5>We could not find any results that matches the search </h5>
                  </div>
                </div>
              </div>
              : ''}
          </div>
          <Footer />
        </>
      ) : (
        <p>sorry something must be wrong try refreshing your browser</p>
      )}
    </>
  );
};

export default FilterFun;
