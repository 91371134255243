import React from 'react'
import email from '../assets/images/HowWeWork/email-01.png'
import receipt2 from '../assets/images/HowWeWork/receipt2.png'
import receipt from '../assets/images/HowWeWork/receipt-01.png'
import clipboard from '../assets/images/HowWeWork/clipboard-01.png'

const HowWeWork = () => {
    return (

      <>
        <div className="col-11 m-auto">
        <div className="container how-we-work" style={{marginTop: "50px"}}>
          <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
            <h2 style={{textAlign: "center", marginTop: '2rem'}}>How We Work
            </h2>
            <div className="rectangle-115"></div>
          </div>
          <div className="row" style={{marginTop: "50px", textAlign: "center"}}><h4 style={{color: "#f27c66"}}>Step 1: Profile</h4><p>A profile is created on the official website of the institution and filled out with personal information and study wishes, to be sent to the official institution email.</p></div>
          <div className="row" style={{borderBottom: "2px solid #f9cb8f", paddingBlock: "4%"}}>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-8">
                <p>
                    <p className="steps-title">Deposit Payment</p> <br />
                    In case the student wishes to accept the offer sent to him and continue the registration procedures, he must pay a deposit payment to the university's
                    bank account, indicating his full name and reference number as stated in the offer letter (we do not interfere with student's payments to the university). The student then sends a picture of the bank's receipt to the official institution's e-mail with the full name, after that we continue the registration procedures.
                </p>

                <h4 style={{textAlign: "center", marginTop: "52px", color: "#f27c66"}}>Step 2</h4>
                </div>
                <div className="col-md-4" style={{display: "flex"}}>
                  <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content"}}>
                    <img src={receipt} alt="" />
                    <div className="rectangle"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="row">
                <div className="col-md-8">
                  <p>
                    <p className="steps-title">Final Acceptance Letter</p> <br />
                    After submitting a copy of the bank receipt and within a maximum period of 24 hours (in the case of payment inside of Turkey) or four days (in the case of payment from outside Turkey), we obtain the final acceptance - which illustrates that the student has officially saved his/her seat in the university and can visit the university as a final step).
                </p>
                <h4 style={{marginLeft: "55px", color: "#f27c66", letterSpacing: "1px", marginTop: "117px"}}>Step 3</h4>
                </div>
                <div className="col-md-4" style={{display: "flex"}}>
                  <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content"}}>
                    <img src={email} alt="" />
                    <div className="rectangle"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" style={{paddingBlock: "4%"}}>
            <div className="col-md-6">
              <div className="row">
              <div className="col-md-4" style={{display: "flex", alignItems: "end"}}>
                  <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content"}}>
                    <div className="rectangle2"></div>
                    <img src={receipt2} alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                <p>
                    <p className="steps-title">Final Acceptance Letter</p> <br />
                    After submitting a copy of the bank receipt and within a maximum period of 24 hours (in the case of payment inside of Turkey) or four days (in the case of payment from outside Turkey), we obtain the final acceptance - which illustrates that the student has officially saved his/her seat in the university and can visit the university as a final step).
                </p>
                  {/* <p>
                    <p className="steps-title">Deposit Payment</p> <br />
                    In case the student wishes to accept the offer sent to him and continue the registration procedures, he must pay a deposit payment to the university's
                    bank account, indicating his full name and reference number as stated in the offer letter (we do not interfere with student's payments to the university). The student then sends a picture of the bank's receipt to the official institution's e-mail with the full name, after that we continue the registration procedures.
                </p> */}
                <h4 style={{marginTop: "30px", marginLeft: "55px", color: "#f27c66", letterSpacing: "1px"}}>Step 4</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6">
            <div className="row">
            <div className="col-md-4" style={{display: "flex", alignItems: "end"}}>
                  <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content"}}>
                    <div className="rectangle3"></div>
                    <img src={clipboard} alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <p>
                    <p className="steps-title">With the Final Acceptance Letter</p> <br />
                    The student can apply for a study visa through the Turkish embassy of his country. Upon arrival in Turkey, we will pick him/her up from the airport and supervise the handover of his/her certificates within the university and provide the student number to begin the study officially within the university.
                </p>
                <h4 style={{marginTop: "54px", marginLeft: "55px", color: "#f27c66", letterSpacing: "1px"}}>Step 5</h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="how-we-work2">
          <div style={{display: "flex", alignItems: "center", flexDirection: "column"}}>
              <h2 style={{textAlign: "center", marginTop: '2rem '}}>How We Work
              </h2>
              <div className="rectangle-115"></div>
          </div>
          <div className="row" style={{marginTop: "50px" , marginRight: "0"}}><p style={{textAlign: "center"}}><h4 style={{color: "#f27c66"}}>Step 1: Profile</h4>A profile is created on the official website of the institution and filled out with personal information and study wishes, to be sent to the official institution email.</p></div>
          <div className="how-we-work2-container">
            
            <div className="rectangle2-mob"></div>
            <div className="rectangle3-mob"></div>
            <h4 style={{marginTop: "100px", marginLeft: "55px", color: "#f27c66"}}>step 2</h4>
            <div style={{backgroundColor: "#f27c66", borderRadius: "50%", padding: "5px", height: "fit-content", marginTop: "304px", marginLeft: "-82px"}}>
              <img src={receipt} alt="" />
            </div>
            
          </div>
          <div className="step1-mob" style={{marginTop: "-200px", marginLeft: "130px"}}>Initial acceptance
                  (offer letter)
                  Within 24 hours we begin
                  working on student's file and
                  get the official offer letter -
                  which
          </div>
          
        </div>

        <div className="how-we-work3">
          <div className="how-we-work2-container">
            
            <div style={{backgroundColor: "#f9cb8f"}} className="rectangle2-mob2"></div>
            <div style={{backgroundColor: "#f9cb8f"}} className="rectangle3-mob2"></div>
            <h4 style={{marginTop: "117px", marginLeft: "55px", color: "#f9cb8f"}}>step 3</h4>
            <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content", marginTop: "304px", marginRight:"100px"}}>
              <img src={receipt2} alt="" />
            </div>
            
          </div>
          <div className="w-50 step2-mob" style={{marginTop: "-190px", marginRight: "253px", display: 'flex'}}>Deposit payment:
            In case the student wishes to
            accept the offer sent to him
            and continue the registration
            procedures
          </div>
          
        </div>

        <div className="how-we-work3">
          <div className="how-we-work2-container">
            
            <div style={{backgroundColor: "#f9cb8f"}} className="rectangle2-mob2"></div>
            <div style={{backgroundColor: "#f9cb8f"}} className="rectangle3-mob2"></div>
            <h4 style={{marginTop: "100px", marginLeft: "55px", color: "#f9cb8f"}}>step 4</h4>
            <div style={{backgroundColor: "#f9cb8f", borderRadius: "50%", padding: "5px", height: "fit-content", marginTop: "304px", marginRight:"100px"}}>
              <img src={receipt} alt="" />
            </div>
            
          </div>
          <div className="w-50 step2-mob" style={{marginTop: "-200px", marginRight: "253px", display: 'flex'}}>Deposit payment:
            In case the student wishes to
            accept the offer sent to him
            and continue the registration
            procedures
          </div>
          
        </div>

        <div style={{marginBottom: "200px"}} className="how-we-work2">
          <div className="how-we-work2-container">
            
            <div className="rectangle2-mob"></div>
            <div className="rectangle3-mob"></div>
            <h4 style={{marginTop: "100px", marginLeft: "55px", color: "#f27c66"}}>step 5</h4>
            <div style={{backgroundColor: "#f27c66", borderRadius: "50%", padding: "5px", height: "fit-content", marginTop: "304px", marginLeft: "-82px"}}>
              <img src={receipt} alt="" />
            </div>
            
          </div>
          <div className="step3-mob" style={{marginTop: "-200px", marginLeft: "130px"}}>Initial acceptance
                  (offer letter)
                  Within 24 hours we begin
                  working on student's file and
                  get the official offer letter -
                  which
          </div>
          
        </div>
        </div>
      </>

    )
}

export default HowWeWork
