import axios from 'axios'

const CREATE_FORM_REQUEST = 'CREATE_FORM_REQUEST'
const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS'
const CREATE_FORM_FAILURE = 'CREATE_FORM_FAILURE'

export const postFormDataAction = (form) => {
    return (dispatch) => {
        dispatch({ type: CREATE_FORM_REQUEST });

        const config = {
            contentType: 'application/json',
        }
        axios.post('/api/dashboard/register/form/', form, config)

            .then(res => {
                dispatch({ type: CREATE_FORM_SUCCESS, payload: res.data });
            })
            .catch(err => {
                dispatch({ type: CREATE_FORM_FAILURE, payload: err });
            })
    }
}

export const postFormDataReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_FORM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case CREATE_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            }
        case CREATE_FORM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
}