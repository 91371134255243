import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Filter from '../components/Filter'
import FilterFun from '../components/FilterFun'
import Footer from '../components/Footer'
import Header from '../components/Header'

const UniversitiesPage = ({ match }) => {

    let [universities, setUniversities] = useState([])

    useEffect(() => {
        getUniversities()
        window.scrollTo(0, 0)
    }, [])

    let getUniversities = async () => {
        let response = await fetch('/api/dashboard/universities/')
        let data = await response.json()
        setUniversities(data)
    }

    return (
        <>
        <FilterFun match={match}/>
        </>
    )
}

export default UniversitiesPage