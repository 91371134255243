import React, { useState, useEffect } from 'react'
import offer from '../../assets/images/WhatWeOffer/offer.png'

const WhatWeOffer = () => {

    let [body, setBody] = useState([])

    useEffect(() => {
        getBody()
    }, [])

    let getBody = async () => {
        let response = await fetch('api/dashboard/weoffer')
        let data = await response.json()
        setBody(data[0])
    }

    return (
        <div className="container">
            <div className="row">
                
                <div className="col-md-6">
                    <img className="img-fluid" src={offer} alt="" />
                </div>
                 
                <div className="col-md-6 offer-column">
                    <h2 className="text-ar2" style={{letterSpacing: "0", textAlign: 'right', alignSelf: "end"}}>ماذا نقدم</h2>
                    <p className="text-ar" style={{letterSpacing: "0", alignSelf: "end", textAlign: "right", direction: "rtl"}}>{body.body_ar}</p>
                </div>
            </div>
        </div>
    )
}

export default WhatWeOffer
