import React, { useEffect, useState } from 'react'

import 'swiper/swiper-bundle.css'
import Footer from '../components/Footer';
import Header from '../components/Header';

import header from '../assets/images/SuccessStories/header.gif'
import { useDispatch, useSelector } from 'react-redux';
import { getSuccessDataAction } from '../redux/successStories';
import Loader from '../components/Loader';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import BlogModal from '../components/BlogModal';
import { handleShowAction } from '../redux/ModalRedux';

const SuccessStoriesPage = ({ match, history }) => {

    const dispatch = useDispatch();

    const getSuccessData = useSelector(state => state.getSuccessData);
    const { loading, data, error } = getSuccessData;

    let number = 3

    var w = window.innerWidth;

    const handleChange = useSelector(state => state.handleChange);
    const { show } = handleChange;

    const handleShow = () => { dispatch(handleShowAction()) }


    if (w <= 1000) {
        number = 1
    }

    const [id, setId] = useState(null)

    const sendId = (value) => {
        setId(value)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getSuccessDataAction())
    }, [dispatch])
    return (
        <>
            {loading ? <Loader /> : data ? (
                <>
                    <Header match={match} hisotry={history} step3 />
                    <div className="container-fluid">
                        <div style={{ marginTop: "50px" }} className="container">
                            <h4 style={{ textAlign: "center" }}>Home <i style={{ color: "#f6992d" }} class="fas fa-greater-than"></i> Success Stories</h4>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="container">
                                <img src={header} alt="" className="img-fluid" /></div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div style={{ marginTop: "50px", display: "flex", justifyContent: "center", flexWrap: "wrap" }} className="container">
                            {data['texts']?.map(el => (
                                <Card key={el.id} style={{ width: '18rem', borderRadius: "10px", margin: "1rem" }}>
                                    <Card.Img variant="top" src={el.blog_image} />
                                    <Card.Body>
                                        <Card.Title>{el.blog_title_en.length > 25 ? el.blog_title_en.slice(0, 25) + "..." : el.blog_title_en}</Card.Title>
                                        <hr />
                                        <Card.Text>
                                            {el.blog_content_en.length > 70 ? el.blog_content_en.substring(0, 70) + "..." : el.blog_content_en}
                                        </Card.Text>
                                    </Card.Body>
                                    <ListGroup className="list-group-flush">
                                        <ListGroupItem style={{ letterSpacing: 0 }}><strong style={{ fontWeight: "900" }}>Published:</strong> {el.published_date.slice(0, 10)} / {el.published_date.slice(11, 19)}</ListGroupItem>
                                        <ListGroupItem><Button onClick={() => (handleShow(), sendId(el.id))} className="blog-btn" style={{ backgroundcolor: "#f7972a" }}>Read More</Button></ListGroupItem>
                                    </ListGroup>
                                </Card>
                            ))}
                        </div>
                    </div>

                    {show && <BlogModal id={id} />}
                    <Footer />
                </>
            ) : <></>}
        </>
    )
}
export default SuccessStoriesPage
