import axios from "axios";
const LANGUAGES_DATA_REQUEST = "LANGUAGES_DATA_REQUEST";
const LANGUAGES_DATA_SUCCESS = "LANGUAGES_DATA_SUCCESS";
const LANGUAGES_DATA_FAIL = "LANGUAGES_DATA_FAIL";

export const getLanguagesDataAction = () => async (dispatch) => {
  try {
    dispatch({
      type: LANGUAGES_DATA_REQUEST,
    });

    const { data } = await axios.get("/api/dashboard/languages/");
    console.log(data)
    dispatch({
      type: LANGUAGES_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LANGUAGES_DATA_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getLanguagesDataReducer = (state = {}, action) => {
  switch (action.type) {
    case LANGUAGES_DATA_REQUEST:
      return {
        loading: true,
      };
    case LANGUAGES_DATA_SUCCESS:
      return {
        loading: false,
        data: action.payload,
      };
    case LANGUAGES_DATA_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
