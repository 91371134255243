import React, {useEffect} from 'react'
import notepad from '../assets/images/Services/notepad.png'
import illustration from '../assets/images/Services/Illustration.png'
import session from '../assets/images/Services/session.png'
import airplane from '../assets/images/Services/airplane.png'
import dorm from '../assets/images/Services/dorm.png'
import book from '../assets/images/Services/book.png'
import message from '../assets/images/Services/Message.png'
import bank from '../assets/images/Services/bank.png'
import id from '../assets/images/Services/id.png'
import artboard from '../assets/images/website-artboard.png'
import Header from '../components/Header'
import Footer from '../components/Footer'

const ServicesPage = ({ match }) => {

    console.log("/ar" + match.path)
    useEffect(()=> {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
        <Header match={match} step2 />
        <div className="container-fluid artboard" style={{
            // backgroundImage: `url(${artboard}`,
        //  filter: "opacity(0.2)",
          backgroundRepeat: "repeat-y"}}>
            <div style={{marginTop: "50px"}} className="container services-page-container">
                <h4 style={{textAlign: "center"}}>Home <i style={{color: "#f6992d"}} class="fas fa-greater-than"></i> Services</h4>

                <div className="row services-row" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className="col-md-3 w-20"><img src={notepad} alt="" />
                        <h4>Admissions and Registrations</h4>
                        <p>Guaranteed acceptance letter with up to 75% scholarship</p>
                    </div>
                    <div className="col-md-3 w-20"><img src={illustration} style={{maxWidth: "63px"}} alt="" />
                        <h4>Certificate of  Equivalency Services</h4>
                        <p>We do the paperwork for student in the university</p>
                    </div>
                    <div className="col-md-3 w-20"><img src={session} alt="" />
                        <h4>Education Consultancy Sessions</h4>
                        <p>Call us and we will explain the details about the education in Turkey</p>
                        {/* <p>Explaining all information and details of preferred study field and its specifications</p> */}
                    </div>
                    <div className="col-md-3 w-20"><img style={{maxWidth: "60px"}} src={airplane} alt="" />
                        <h4>Airport Pickup</h4>
                        <p>We will be waiting for you at the airport</p>
                    </div>

                </div>

                <div className="row services-row" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div className="col-md-3 w-20"><img src={dorm} alt="" />
                        <h4>Dormitories</h4>
                        <p>We offer comfortable clean dormitories</p>
                    </div>
                    <div style={{minHeight: "175px"}} className="col-md-3 w-20"><img src={book} style={{maxWidth: "65px", marginBottom: "8%"}} alt="" />
                        <h4 style={{marginTop: "5%"}}>Special <br /> Scholarships</h4>
                    </div>
                    <div style={{minHeight: "175px"}} className="col-md-3 w-20"><img src={message} style={{maxWidth: "65px", marginBottom: "8%"}} alt="" />
                        <h4 style={{marginTop: "5%"}}>Follow Up <br /> Service</h4>
                    </div>
                    <div style={{minHeight: "175px"}} className="col-md-3 w-20"><img src={id} style={{marginBottom: "8%"}} alt="" />
                        <h4 style={{marginTop: "5%"}}>Residence Card <br />Permit</h4>
                    </div>

                </div>

                <div className="row services-row" style={{display: 'flex', justifyContent: 'space-evenly'}}>
                    <div style={{minHeight: "175px"}} className="col-md-3 w-20"><img src={bank} style={{maxWidth: "150px", marginBottom: "8%"}} alt="" />
                        <h4 style={{marginTop: "5%"}}>Bank Accounts</h4>
                    </div>

                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default ServicesPage
