import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/footerLogo.png'

const ArFooter = () => {

    var currentYear = new Date().getFullYear();

    return (
        <>
            <a href="https://api.whatsapp.com/send?phone=905394479920" className="wp_btn"><i className="fab fa-whatsapp"></i></a>
            <div className="footer" style={{ backgroundColor: "#2d3339" }}>
                <div className="container">
                    <div className="row">

                        <div className="col-md-4">
                            <div className="w-50" style={{ display: "flex", justifyContent: "space-evenly", backgroundColor: "#f6921e", alignItems: "center", padding: "10px 5px 5px" }}>
                                <i style={{ fontSize: "26px" }} class="fas fa-graduation-cap"></i>
                                <h4 className="ar-text2" style={{ color: "#ffffff", marginBottom: "0", letterSpacing: "0" }}>معلومات عنا </h4>
                            </div>

                            <img className="footer-logo" style={{ margin: "30px 0" }} src={logo} alt="" />

                            <p className="footer-data"><i style={{ marginRight: "5px" }} class="fas fa-phone-alt"></i><a style={{ textDecoration: "none" }} href="tel:+902128554253">+90 212 855 42 53</a></p>
                            <p className="footer-data"><i style={{ marginRight: "5px" }} class="fas fa-envelope"></i><a style={{ textDecoration: "none" }} href="mailto:info@provada.education">info@provada.education</a></p>
                            <p className="footer-data"><i style={{ marginRight: "5px" }} class="fas fa-home"></i><a style={{ textDecoration: "none" }} target="_blank" href="https://goo.gl/maps/fbCPABmanRuE5eMh7">Babacan Premium B blok / 387 </a></p>

                            <div className="footer-links">
                                <a style={{ marginLeft: "0" }} target="_blank" href="https://twitter.com/ProvadaE"><i class="fab fa-twitter" style={{ textAlign: 'center', lineHeight: '18px' }}></i></a>
                                <a target="_blank" href="https://www.facebook.com/provada.edu/"><i class="fab fa-facebook-f" style={{ textAlign: 'center', lineHeight: '18px' }}></i></a>
                                <a target="_blank" href="https://www.instagram.com/provada_education"><i class="fab fa-instagram" style={{ textAlign: 'center', lineHeight: '18px' }}></i></a>
                                <a target="_blank" href="https://www.youtube.com/channel/UCVQUvTaMEBNcIaGP4lh5e9A/featured"><i class="fab fa-youtube" style={{ textAlign: 'center', lineHeight: '18px' }}></i></a>
                            </div>
                        </div>



                        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }} className="col-md-4">
                            {/* <div style={{display: "flex", justifyContent: "space-between", backgroundColor: "#7a7a7a", alignItems: "center", padding: "10px 5px 5px"}}>
                                <i style={{fontSize: "26px"}} class="far fa-clock"></i>
                                <h4 style={{color: "#ffffff", marginBottom: "0", textAlign: "end"}}>خريطة الموقع </h4>
                            </div> */}

                            <div style={{ marginTop: "70px", marginLeft: "auto" }}>
                                <p style={{ textAlign: "right" }}><Link className="footer-nav-item ar-text" style={{ textDecoration: "none", letterSpacing: "0", textAlign: "right" }} to="/ar">الرئيسية</Link></p>
                                <p style={{ textAlign: "right" }}><Link className="footer-nav-item ar-text" style={{ textDecoration: "none", letterSpacing: "0", textAlign: "right" }} to="/ar/services">الخدمات</Link></p>
                                <p style={{ textAlign: "right" }}><Link className="footer-nav-item ar-text" style={{ textDecoration: "none", letterSpacing: "0", textAlign: "right" }} to="/ar/universities">الجامعات</Link></p>
                                <p style={{ textAlign: "right" }}><Link className="footer-nav-item ar-text" style={{ textDecoration: "none", letterSpacing: "0", textAlign: "right" }} to="/ar/success-stories">قصص النجاح</Link></p>
                                <p style={{ textAlign: "right" }}><Link className="footer-nav-item ar-text" style={{ textDecoration: "none", letterSpacing: "0", textAlign: "right" }} to="/ar/registration">التسجيل</Link></p>
                                <p style={{ textAlign: "right" }}><Link className="footer-nav-item ar-text" style={{ textDecoration: "none", letterSpacing: "0", textAlign: "right" }} to="/ar/contact">تواصل</Link></p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12043.187355435331!2d28.681547!3d41.0078197!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3c09aa1320cb2047!2sBabacan%20Premium!5e0!3m2!1sen!2str!4v1643892813230!5m2!1sen!2str" style={{ border: "0" }} allowFullScreen="" loading="lazy"></iframe>
                        </div>



                    </div>
                </div>
            </div>
            <div className="container-fluid second-footer-container" style={{ backgroundColor: "#23272d" }}>
                <div style={{ justifyContent: "end" }} className="container second-footer">
                    <p style={{ textAlign: 'right' }}> <a className="ar-text2" style={{ textDecoration: "none", color: "#eb8c1e" }} target="_blank" href="https://osgpro.com/">OSG Pro</a> &#169; {currentYear}. جميع الحقوق محفوظة.</p>
                    {/* <div className="footer-nav">          
                        <p><Link className="footer-nav-item" style={{textDecoration: "none", letterSpacing: "0"}} to="/ar/contact">تواصل</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none", letterSpacing: "0"}} to="/ar/registration">التسجيل</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none", letterSpacing: "0"}} to="/ar/success-stories">قصص النجاح</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none", letterSpacing: "0"}} to="/ar/universities">الجامعات</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none", letterSpacing: "0"}} to="/ar/services">الخدمات</Link></p>
                        <p><Link className="footer-nav-item" style={{textDecoration: "none", letterSpacing: "0"}} to="/ar">الرئيسية</Link></p>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default ArFooter
