import React, { useEffect, useState } from 'react'

import 'swiper/swiper-bundle.css'
import ArFooter from '../../components/ArabicComponents/ArFooter';
import ArHeader from '../../components/ArabicComponents/ArHeader';

import header from '../../assets/images/SuccessStories/header.gif'
import { useDispatch, useSelector } from 'react-redux';
import { getSuccessDataAction } from '../../redux/successStories';
import Loader from '../../components/Loader';
import { Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import { handleShowAction } from '../../redux/ModalRedux';
import ArBlogModal from '../../components/ArBlogModal';

const ArSuccessStoriesPage = ({ match }) => {
    const dispatch = useDispatch();

    const getSuccessData = useSelector(state => state.getSuccessData);
    const { loading, data, error } = getSuccessData;

    let number = 3

    var w = window.innerWidth;

    const handleChange = useSelector(state => state.handleChange);
    const { show } = handleChange;

    const handleShow = () => { dispatch(handleShowAction()) }

    if (w <= 1000) {
        number = 1
    }

    const [id, setId] = useState(null)

    const sendId = (value) => {
        setId(value)
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getSuccessDataAction())
    }, [dispatch])
    return (
        <>
            {loading ? (
                <Loader />
            ) : data ? (
                <>
                    <ArHeader match={match} step3 />
                    <div className="container-fluid">
                        <div style={{ marginTop: "50px" }} className="container">
                            <h4 className="text-ar2" style={{ textAlign: "center", letterSpacing: "0" }}>الرئيسية <i style={{ color: "#f6992d" }} class="fas fa-less-than"></i> قصص النجاح</h4>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="container">
                                <img src={header} alt="" className="img-fluid" /></div>
                        </div>

                        <div className="container-fluid">
                            <div style={{ marginTop: "50px", display: "flex", justifyContent: "center", flexWrap: "wrap" }} className="container">
                                {data['texts']?.map(el => (
                                    <Card key={el.id} style={{ width: '18rem', borderRadius: "10px", margin: "1rem" }}>
                                        <Card.Img variant="top" src={el.blog_image} />
                                        <Card.Body>
                                            <Card.Title className="text-ar2" style={{ textAlign: "right", letterSpacing: "0" }} dir="rtl">{el.blog_title_ar.length > 25 ? el.blog_title_ar.slice(0, 25) + "..." : el.blog_title_ar}</Card.Title>
                                            <hr />
                                            <Card.Text style={{ textAlign: "right", letterSpacing: "0" }} dir="rtl">
                                                {el.blog_content_ar.length > 70 ? el.blog_content_ar.substring(0, 70) + "..." : el.blog_content_ar}
                                            </Card.Text>
                                        </Card.Body>
                                        <ListGroup className="list-group-flush">
                                            <ListGroupItem style={{ letterSpacing: 0, direction: "rtl", textAlign: "right" }}><strong style={{ fontWeight: "900", letterSpacing: "0" }}> نشر في:</strong> {el.published_date.slice(0, 10)} / {el.published_date.slice(11, 19)}  </ListGroupItem>
                                            <ListGroupItem><Button onClick={() => (handleShow(), sendId(el.id))} className="blog-btn" style={{ backgroundcolor: "#f7972a", letterSpacing: "0" }}>إقرأ المزيد</Button></ListGroupItem>
                                        </ListGroup>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>

                    {show && <ArBlogModal id={id} />}
                    <ArFooter />
                </>
            ) : (
                <></>
            )}
        </>
    )
}
export default ArSuccessStoriesPage
