import React, { useState } from 'react'
import RingLoader from "react-spinners/BeatLoader";
import ClockLoader from "react-spinners/ClockLoader";

const Loader = () => {
    // let [loading, setLoading] = useState(true);
    // let [color, setColor] = useState("#fcb813");

    return (
        <div className="loader">
            {/* <ClipLoader color={color} loading={loading} size={150} /> */}
            <RingLoader size={150} />
        </div>
    )
}

export default Loader
