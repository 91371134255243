import React, { useState, useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import bg from '../../assets/images/bg.png'
import { createOrder } from '../../actions/orderActions'
import { ORDER_CREATE_RESET } from '../../constants/orderConstants'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Login from '../Login'

import useGeoLocation from "react-ipgeolocation";

function PlaceOrderScreen({ history, match, location }) {

    let bookId = match.params.id

    const location2 = useGeoLocation();

    const [discountCode, setDiscountcode] = useState(0)
    const [codes, setCodes] = useState([])

    let [book, setBook] = useState([])
 
    const orderCreate = useSelector(state => state.orderCreate)
    const { order, error, success } = orderCreate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const dispatch = useDispatch()

    let code = 0

    let getBook = async () => {
        let response = await fetch(`/api/dashboard/store/${bookId}`)
        let data = await response.json()
        setBook(data)
    }

    const placeOrder = () => {
        dispatch(createOrder({
            product: book,
            paymentMethod: "PayPal",
            totalPrice: book.price,
    }, book.id))}

    // const checkCode = (value) => {
    //     while (codes.length !== 0) {
    //         console.log(value)
    //     }
    // }

    let getCodes = async () => {
        let response = await fetch ('/api/dashboard/codes')
        let data = await response.json()
        setCodes(data)
        console.log(data)
    }

    useEffect(() => {
        // if (!userInfo) {
        //     history.push('/login')
        // }

        if (success) {
            history.push(`/order/${order._id}`)
            dispatch({ type: ORDER_CREATE_RESET })
        }
        getBook()
    }, [success, history, userInfo])

    return (
        <>
            
                {userInfo ? (
                    <>
                    <Header step7 match={match} />
                    <div className="container my-5">
                    <Row>
                        <Col className="d-flex justify-content-center align-items-center" md={8}>
                            <div className="row img col-4">
                                <img src={bg} alt="" className=""/>
                            </div>
                        </Col>

                        <Col md={4}>
                            <Card>
                                <ListGroup variant='flush'>
                                    <ListGroup.Item>
                                        <h2>Order Summary</h2>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Book Name:</Col>
                                            <Col>{book.name}</Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Price</Col>
                                            {location2.country == 'TR' ? (
                                                <Col>TL {book.price_in_tl}</Col>
                                            ) : (
                                                <Col>${book.price}</Col>
                                            )}
                                        </Row>
                                    </ListGroup.Item>

                                    {error && <ListGroup.Item>{error}</ListGroup.Item>}

                                    {/* <ListGroup.Item>
                                        <Row>
                                            <Col>Discount Code:</Col>
                                            <Col><input onChange={(e) => (setDiscountcode(e.target.value))} className="promo-code" type="number" placeholder="Enter Promo Code" /></Col>
                                        </Row>
                                    </ListGroup.Item> */}

                                    <ListGroup.Item>
                                        <Button
                                            type='button'
                                            className='btn-block'
                                            onClick={placeOrder}
                                            style={{backgroundColor: "#eb8c1e"}}
                                        >
                                            Place Order
                                        </Button>
                                    </ListGroup.Item>

                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Footer />
                </>
            ) : (
                <Login match={match} history={history} location={location} />
            )}
            
        </>
    )
}

export default PlaceOrderScreen