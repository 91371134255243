import {
  HashRouter as Router,
  Route,
} from 'react-router-dom'

import AboutUs from './pages/AboutUs'
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import RegisterationPage from './pages/RegisterationPage';
import RegistrationsStepsPage from './pages/RegistrationsStepsPage';
import ServicesPage from './pages/ServicesPage';
import SuccessStoriesPage from './pages/SuccessStoriesPage';
import UniversitiesPage from './pages/UniversitiesPage';
import UniversityPage from './pages/UniversityPage';
import ArHomePage from './pages/ArabicPages/ArHomePage';
import ArServicesPage from './pages/ArabicPages/ArServicesPage';
import ArSuccessStoriesPage from './pages/ArabicPages/ArSuccessStoriesPage';
import ArRegistrationsStepsPage from './pages/ArabicPages/ArRegistrationsStepsPage';
import ArContactPage from './pages/ArabicPages/ArContactPage';
import ArUniversitiesPage from './pages/ArabicPages/ArUniversitiesPage';
import ArUniversityPage from './pages/ArabicPages/ArUniversityPage';
import ArRegisterationPage from './pages/ArabicPages/ArRegisterationPage';
import RegisterationPageDegree from './pages/RegisterationPageDegree';
import ArRegisterationPageDegree from './pages/ArabicPages/ArRegisterationPageDegree';
import TestForm from './pages/TestForm';
import Books from './pages/StorePages/Books';
import Book from './pages/StorePages/Book';
import Profile from './pages/ProfilePages/Profile';
import Login from './pages/Login';
import Register from './pages/Register';
import ProfilePage from './pages/ProfilePages/ProfilePage';
import AccountPage from './pages/ProfilePages/AccountPage';
import Checkout from './pages/StorePages/Checkout';
import OrderScreen from './pages/StorePages/OrderScreen';
import PlaceOrderScreen from './pages/StorePages/PlaceOrderScreen';
import ErrorPage from './pages/ErrorPage';
import ArBooks from './pages/ArabicPages/ArabicStorePages/ArBooks';
import ArBook from './pages/ArabicPages/ArabicStorePages/ArBook';
import ArOrderScreen from './pages/ArabicPages/ArabicStorePages/ArOrderScreen';
import ArPlaceOrderScreen from './pages/ArabicPages/ArabicStorePages/ArPlaceOrderScreen';
import ArProfilePage from './pages/ArabicPages/ArabicProfilePages/ArProfilePage';
import ArAccountPage from './pages/ArabicPages/ArabicProfilePages/ArAccountPage';
import ArLogin from './pages/ArabicPages/ArLogin';
import ArRegister from './pages/ArabicPages/ArRegister';

function App() {
  return (
    <div className="App">
      <Router>

        {/* Authentication Routes */}
        <Route path='/login' component={Login} />
        <Route path='/signup' component={Register} />

        <Route path='/ar/login' component={ArLogin} />
        <Route path='/ar/signup' component={ArRegister} />

        {/* Website Routes */}
        <Route exact path='/' component={HomePage} />
        <Route path='/aboutUs' component={AboutUs} />
        <Route path='/services' component={ServicesPage} />
        <Route path='/blog' component={SuccessStoriesPage} />
        <Route exact path='/universities' component={UniversitiesPage} />
        <Route path='/universities/:id' component={UniversityPage} />
        <Route path='/registration' component={RegistrationsStepsPage} />
        <Route path='/contact' component={ContactPage} />
        <Route path='/register' exact component={RegisterationPage} />
        <Route path='/register/:id/:uni/:major' component={RegisterationPageDegree} />

        {/* Store Routes */}
        {/* <Route path="/orders" component={Profile}/> */}
        <Route path='/order/:id' component={OrderScreen} />
        <Route path="/placeOrder/:id" component={PlaceOrderScreen} />
        <Route path="/book/:id" component={Book} />
        <Route exact path="/store" component={Books} />
        {/* <Route path="/checkout/:id" component={Checkout} /> */}

        {/* Account Routes */}
        <Route path="/account" component={AccountPage} />
        <Route path="/profile" component={ProfilePage} />

        {/* Arabic Routes */}
        <Route exact path='/ar' component={ArHomePage} />
        <Route path='/ar/services' component={ArServicesPage} />
        <Route path='/ar/blog' component={ArSuccessStoriesPage} />
        <Route exact path='/ar/universities' component={ArUniversitiesPage} />
        <Route path='/ar/universities/:id' component={ArUniversityPage} />
        <Route path='/ar/registration' component={ArRegistrationsStepsPage} />
        <Route path='/ar/contact' component={ArContactPage} />
        <Route path='/ar/register' exact component={ArRegisterationPage} />
        <Route path='/ar/register/:id/:uni/:major' component={ArRegisterationPageDegree} />
        <Route path='/test' component={TestForm} />

        {/* Arabic Store Routes */}

        <Route path='/ar/order/:id' component={ArOrderScreen} />
        <Route path="/ar/placeOrder/:id" component={ArPlaceOrderScreen} />
        <Route path="/ar/book/:id" component={ArBook} />
        <Route exact path="/ar/store" component={ArBooks} />

        {/* Arabic Account Routes */}
        <Route path="/ar/account" component={ArAccountPage} />
        <Route path="/ar/profile" component={ArProfilePage} />

        {/* <Route component={ErrorPage} /> */}
      </Router>
    </div>
  );
}

export default App;
