import axios from 'axios';
const UNVERSITIES_DATA_REQUEST = 'UNVERSITIES_DATA_REQUEST';
const UNVERSITIES_DATA_SUCCESS = 'UNVERSITIES_DATA_SUCCESS';
const UNVERSITIES_DATA_FAIL = 'UNVERSITIES_DATA_FAIL';


export const getUniversitiesDataAction = () => async (dispatch) => {
    try {
        dispatch({
            type: UNVERSITIES_DATA_REQUEST
        })

        const { data } = await axios.get('/api/dashboard/universities/')
        dispatch({
            type: UNVERSITIES_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UNVERSITIES_DATA_FAIL,
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail : error.message
        })
    }
}

export const getUniversitiesDataReducer = (state={}, action) => {
    switch(action.type) {
        case UNVERSITIES_DATA_REQUEST:
            return {
                loading: true
            }
        case UNVERSITIES_DATA_SUCCESS:
            return {
                loading: false,
                data: action.payload
            }
        case UNVERSITIES_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}