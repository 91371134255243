import React, { useState, useEffect } from 'react'
import book from '../../assets/images/Counter/book.png'
import humans from '../../assets/images/Counter/humans.png'
import pencil from '../../assets/images/Counter/pencil.png'
import calendar from '../../assets/images/Counter/calendar.png'

import CountUp from 'react-countup';

const Counter = () => {

    let [counterData, setCounterdata] = useState([])
    let [universities, setUniversities] = useState([])

    useEffect(() => {
        getCounterData()
        getUniversities()
    }, [])

    let getCounterData = async () => {
        let response = await fetch('/api/dashboard/counter')
        let data = await response.json()
        setCounterdata(data)
    }

    let getUniversities = async () => {
        let response = await fetch('/api/dashboard/universities/')
        let data = await response.json()
        setUniversities(data)
    }

    return (
        <div className="counter-section">
            <div className="row container">
                <div className="col-md-3">
                    <img src={calendar} alt="" /> <br />
                    <p className="ar-text">
                        <CountUp style={{ fontSize: "30px" }} end={counterData[0]?.courses} /> <br />
                        حدث
                    </p>
                </div>
                <div className="col-md-3">
                    <img src={pencil} alt="" /> <br />
                    <p className="ar-text">
                        <CountUp style={{ fontSize: "30px" }} end={counterData[0]?.students} /> <br />
                        جامعة
                    </p>
                </div>
                <div className="col-md-3">
                    <img src={humans} alt="" /> <br />
                    <p className="ar-text">
                        <CountUp style={{ fontSize: "30px" }} end={universities?.length} /> <br />
                        طالب
                    </p>
                </div>
                <div className="col-md-3">
                    <img src={book} alt="" /> <br />
                    <p className="ar-text">
                        <CountUp style={{ fontSize: "30px" }} end={counterData[0]?.events} /> <br />
                        كورس
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Counter
